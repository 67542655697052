import React, { Component } from 'react'
import Chart from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels';

let myChart;

class HorasAulaStackedChart extends Component {

  componentDidMount = () => {
    this.buildChart();
  };

  componentDidUpdate = () => {
    this.buildChart();
  };

  buildChart = () => {
    let element = document.getElementById("stackedchart-horasaula"),
      myChartRef = element.getContext("2d");

    element.height = this.props.heigth;

    if (typeof myChart !== "undefined") myChart.destroy();

    const compare = (a, b) => a.horasAula.total > b.horasAula.total ? -1 : (a.horasAula.total < b.horasAula.total ? 1 : 0);

    const totalizer = {
      id: 'totalizer',
      beforeUpdate: chart => {
        let totals = {};
        let utmost = 0;

        chart.data.datasets.forEach((dataset, datasetIndex) => {
          if (chart.isDatasetVisible(datasetIndex)) {
            utmost = datasetIndex;
            dataset.data.forEach((value, index) => {
              totals[index] = (totals[index] || 0) + value;
            });
          }
        });

        chart.$totalizer = {
          totals: totals,
          utmost: utmost
        };
      }
    };

    let unidades = this.props.unidades.slice();
    unidades.sort(compare);

    myChart = new Chart(myChartRef, {
      type: 'horizontalBar',
      data: {
        labels: unidades.map(u => u.unidade),
        datasets: [
          {
            label: "Iniciante",
            data: unidades.map(u => u.horasAula.nivel.iniciante),
            categoryPercentage: 0.8,
            barPercentage: 1.0,
            backgroundColor: 'rgba(92, 184, 92, 0.5)',
            borderWidth: 1
          },
          {
            label: "Intermediário",
            data: unidades.map(u => u.horasAula.nivel.intermediario),
            categoryPercentage: 0.8,
            barPercentage: 1.0,
            backgroundColor: 'rgba(240, 173, 78, 0.5)',
            borderWidth: 1
          },
          {
            label: "Avançado",
            data: unidades.map(u => u.horasAula.nivel.avancado),
            categoryPercentage: 0.8,
            barPercentage: 1.0,
            backgroundColor: 'rgba(2, 117, 216, 0.5)',
            borderWidth: 1
          },
          {
            label: "Misto",
            data: unidades.map(u => u.horasAula.nivel.misto),
            categoryPercentage: 0.8,
            barPercentage: 1.0,
            backgroundColor: 'rgba(217, 83, 79, 0.5)',
            borderWidth: 1
          }
        ]
      },
      options: {
        title: {
          display: true,
          text: 'Horas Aula por Nível',
          fontSize: 14
        },
        legend: {
          position: 'bottom'
        },
        layout: {
          padding: {
            left: 0,
            right: 20,
            top: -10,
            bottom: -10
          }
        },
        plugins: {
          datalabels: {
            color: 'rgba(0, 0, 0, 1)',
            anchor: 'end',
            align: 'end',
            formatter: (value, ctx) => {
              const total = ctx.chart.$totalizer.totals[ctx.dataIndex];
              return total.toFixed(2);
            },
            display: function (ctx) { return ctx.datasetIndex === ctx.chart.$totalizer.utmost }
          }
        },
        scales: {
          xAxes: [{
            gridLines: { zeroLineColor: 'rgba(0, 0, 0, 0.5)' },
            stacked: true,
            ticks: { fontColor: '#212528' },
          }],
          yAxes: [{
            gridLines: { tickMarkLength: 0 },
            stacked: true,
            ticks: { fontColor: '#212528', padding: 10 },
          }]
        },
        maintainAspectRatio: false
      },
      plugins: [ChartDataLabels, totalizer]
    });
  };

  render() {
    return (
      <canvas id="stackedchart-horasaula" />
    )
  }
}

export default HorasAulaStackedChart;