import React, { Component } from 'react'
import Chart from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels';

let myChart;

class AlunosIdadeBarChart extends Component {

  componentDidMount = () => {
    this.buildChart();
  };

  componentDidUpdate = () => {
    this.buildChart();
  };

  buildChart = () => {
    let element = document.getElementById("barchart-alunos-idades"),
      myChartRef = element.getContext("2d");

    element.height = this.props.heigth;

    if (typeof myChart !== "undefined") myChart.destroy();

    const unidades = this.props.unidades.slice();
    let idades = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
    unidades.forEach(unidade => {
      idades[0] += unidade.alunos.idade._7;
      idades[1] += unidade.alunos.idade._8;
      idades[2] += unidade.alunos.idade._9;
      idades[3] += unidade.alunos.idade._10;
      idades[4] += unidade.alunos.idade._11;
      idades[5] += unidade.alunos.idade._12;
      idades[6] += unidade.alunos.idade._13;
      idades[7] += unidade.alunos.idade._14;
      idades[8] += unidade.alunos.idade._15;
      idades[9] += unidade.alunos.idade._16;
    });

    myChart = new Chart(myChartRef, {
      type: 'bar',
      data: {
        labels: ['7', '8', '9', '10', '11', '12', '13', '14', '15', '16+'],
        datasets: [
          {
            data: idades,
            categoryPercentage: 0.8,
            barPercentage: 1.0,
            backgroundColor: 'rgba(217, 83, 79, 1)',
            borderWidth: 1,
            datalabels: {
              color: 'rgba(0, 0, 0, 0.8)',
              anchor: 'end',
              align: 'end',
              offset: -5
            }
          }
        ]
      },
      options: {
        title: {
          display: true,
          text: 'Alunos / Idade',
          fontSize: 14
        },
        layout: {
          padding: {
            left: 0,
            right: 0,
            top: 0,
            bottom: 0
          }
        },
        legend: { display: false },
        scales: {
          xAxes: [{
            gridLines: { display: false },
            ticks: {
              fontColor: '#212528',
              fontSize: 11
            },
          }],
          yAxes: [{
            gridLines: { tickMarkLength: 0 },
            ticks: { display: false },
          }]
        },
        maintainAspectRatio: false
      },
      plugins: [ChartDataLabels]
    });
  };

  render() {
    return (
      <canvas id="barchart-alunos-idades" />
    )
  }
}

export default AlunosIdadeBarChart;