import React, { Component, Fragment } from "react";
import { Modal, Form, Col, Button } from "react-bootstrap";
import { CSSTransition } from 'react-transition-group';
import { toast } from 'react-toastify';
import SweetAlert from "react-bootstrap-sweetalert";
import { Loading, SelectAtivo } from "../../../components";
import { validEmailRegex } from "../../../utils/validation";
import api from "../../../services/api";

const resetErrors = { nome: '', email: '' };

class FormAdmin extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showModal: false,
      isLoading: false,
      alert: null,
      record: {},
      errors: resetErrors
    };
    this.loadFromAPI = props.loadFromAPI;
    this.closeModalForm = props.closeModal;
  }

  static getDerivedStateFromProps(props, state) {
    if (props.showModal !== state.showModal) {
      return {
        showModal: props.showModal,
        record: props.record
      };
    }
    return null;
  }

  closeModal = () => {
    this.setState({
      showModal: false,
      isLoading: false,
      record: this.props.emptyRecord,
      errors: resetErrors
    }, () => this.closeModalForm());
  };

  validateInput = (name, value, errors) => {
    switch (name) {
      case 'nome':
        errors.nome = !value ? 'Preencha o campo NOME!' : '';
        break;
      case 'email':
        if (!value) {
          errors.email = 'Preencha o campo EMAIL';
        } else if (!validEmailRegex.test(value)) {
          errors.email = 'EMAIL inválido';
        } else {
          errors.email = '';
        }
        break;
      default:
        break;
    }

    return errors;
  };

  validateForm = () => {
    let record = Object.assign({}, this.state.record),
      errors = Object.assign({}, this.state.errors);

    for (const key in record) {
      errors = this.validateInput(key, record[key], errors);
    }

    return errors;
  };

  handleInputChange = (event) => {
    event.preventDefault();
    const { name, value } = event.target;
    this.handleComponentChange(name, value);
  };

  handleComponentChange = (name, value) => {
    let record = Object.assign({}, this.state.record),
      errors = Object.assign({}, this.state.errors);

    record[name] = value;
    this.setState({
      record: record,
      errors: this.validateInput(name, value, errors)
    });
  };

  hideAlert = () => {
    this.setState({ alert: null });
  };

  handleSubmit = (event) => {
    event.preventDefault();
    const errors = this.validateForm();
    if (JSON.stringify(errors) !== JSON.stringify(resetErrors)) {
      this.setState({
        alert: (<SweetAlert warning title="Oops!" onConfirm={() => this.hideAlert()} showConfirm={false} timeout={2000}>Preenchimento incorreto!</SweetAlert>),
        errors: errors
      });
    } else {
      this.setState({ isLoading: true }, () => {
        api.post('/administradores', this.state.record)
          .then((response) => {
            if (response.data.message === 'ok') {
              const idAdministrador = response.data.idAdministrador ? response.data.idAdministrador : null;
              toast.success('Administrador salvo com sucesso!');
              this.loadFromAPI();
              this.closeModal();
              if (idAdministrador) this.setState({ record: this.props.emptyRecord });
            } else {
              this.setState({
                isLoading: false,
                alert: (<SweetAlert warning title="Oops!" onConfirm={() => this.hideAlert()} showConfirm={false} timeout={2000}>{response.data.message}</SweetAlert>),
              });
            }
          })
          .catch((error) => {
            this.setState({ isLoading: false });
            toast.error('Falha ao enviar os dados!');
          });
      });
    }
  }

  render() {
    const { showModal, isLoading, alert, record, errors } = this.state;

    return (
      <Fragment>
        {alert}
        <Modal show={showModal} onHide={this.closeModal}>
          <Modal.Header closeButton>
            <Modal.Title>{record.id ? 'Editar' : 'Adicionar'} administrador</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="loading">
              <Form id="form-admin" className="form-horizontal form-sm form-custom" onSubmit={this.handleSubmit} noValidate>
                <Form.Row>
                  <Form.Group as={Col} sm={12}>
                    <Form.Label>Nome</Form.Label>
                    <Form.Control
                      type="text"
                      name="nome"
                      value={record.nome}
                      onChange={this.handleInputChange}
                      onBlur={this.handleInputChange}
                      className={errors.nome.length && 'is-invalid'}
                      required
                      noValidate
                    />
                    <Form.Control.Feedback type="invalid">{errors.nome}</Form.Control.Feedback>
                  </Form.Group>
                  <Form.Group as={Col} sm={9}>
                    <Form.Label>Email</Form.Label>
                    <Form.Control
                      type="email"
                      name="email"
                      value={record.email}
                      onChange={this.handleInputChange}
                      onBlur={this.handleInputChange}
                      className={errors.email.length && 'is-invalid'}
                      required
                      noValidate
                    />
                    <Form.Control.Feedback type="invalid">{errors.email}</Form.Control.Feedback>
                  </Form.Group>
                  <Form.Group as={Col} sm={3}>
                    <Form.Label>Status</Form.Label>
                    <SelectAtivo
                      name="ativo"
                      ativo={record.ativo}
                      onChange={this.handleComponentChange}
                      required={true}
                    />
                    <Form.Control.Feedback type="invalid">{errors.ativo}</Form.Control.Feedback>
                  </Form.Group>
                </Form.Row>
              </Form>
              <CSSTransition
                in={isLoading}
                timeout={500}
                classNames="fade"
                unmountOnExit
              >
                <div><Loading /></div>
              </CSSTransition>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="outline-secondary" onClick={this.closeModal}>Cancelar</Button>
            <Button variant="success" form="form-admin" type="submit">Salvar</Button>
          </Modal.Footer>
        </Modal>
      </Fragment>
    );
  }
}

export default FormAdmin;