import React, { Component } from "react";
import { Alert, Modal, Table, Row, Col, Card } from "react-bootstrap";
import { CSSTransition } from 'react-transition-group';
import SweetAlert from "react-bootstrap-sweetalert";

import { Loading } from "../../../components"
import { dateToLocal, calculaIdade } from '../../../utils/dates';
import { serieLabel, turnoLabel, nivelLabel } from '../../../utils/misc';

class ProfileAluno extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showModal: false,
      isLoading: false,
      alert: null,
      record: {}
    }
    this.loadFromAPI = props.loadFromAPI;
    this.closeProfileAluno = props.closeProfileAluno;
  }

  static getDerivedStateFromProps(props, state) {
    if (props.showModal !== state.showModal) {
      let record = Object.assign({}, props.record);

      for (const key in record) {
        if (key === 'nascimento' || key === 'inscricao' || key === 'matricula' || key === 'formatura' || key === 'desistencia') {
          if (record[key] && record[key].length) record[key] = new Date(record[key].replace('Z', ''));
          else record[key] = '';
        } else if (record[key] === null) {
          record[key] = '';
        }
      }

      return {
        showModal: props.showModal,
        record: record
      };
    }
    return null;
  }

  closeModal = () => {
    this.setState({
      showModal: false,
      isLoading: false,
      record: this.props.emptyRecord,
      formValid: false
    }, () => this.closeProfileAluno());
  };

  showAlert = (type, message) => {
    this.setState({
      alert: (<SweetAlert type={type} title="Oops!" onConfirm={() => this.hideAlert()} showConfirm={false} timeout={2000}>{message}</SweetAlert>)
    });
  };

  hideAlert = () => {
    this.setState({ alert: null });
  };

  render() {
    const { showModal, isLoading, alert, record } = this.state;

    return (
      <React.Fragment>
        {alert}
        <Modal
          size="lg"
          show={showModal}
          onHide={this.closeModal}
          dialogClassName="modal-90w"
        >
          <Modal.Header closeButton>
            <Modal.Title>Ficha do aluno</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="loading">
              <CSSTransition
                in={isLoading}
                timeout={500}
                classNames="fade"
                unmountOnExit
              >
                <div><Loading /></div>
              </CSSTransition>

              <Row>
                <Col sm={12}>
                  <h1 className="mb-3"><b>{record.nome}</b> <small className="text-muted">({calculaIdade(record.nascimento)} anos)</small></h1>
                </Col>
                <Col sm={12}>
                  <Card bg="light" className="mb-3">
                    <Card.Body className="p-2">
                      <Card.Title className="text-muted">Matrícula</Card.Title>
                      <Row>
                        <Col lg={5}>
                          <small>Unidade</small>
                          <h4><b>{record.unidade}</b></h4>
                        </Col>
                        <Col xs={8} lg={4}>
                          <small>Curso</small>
                          <h4><b>{record.curso}</b></h4>
                        </Col>
                        <Col xs={4} lg={3}>
                          <small>Nível</small>
                          <h4><b>{nivelLabel(record.nivel)}</b></h4>
                        </Col>
                      </Row>
                      <Row>
                        <Col sm={6} lg={3} className="pb-2 pb-lg-0 pr-sm-1">
                          <Alert variant="secondary" className="h-100 mb-0">
                            Inscrição
                            <h3><b>{dateToLocal(record.inscricao)}</b></h3>
                          </Alert>
                        </Col>
                        <Col sm={6} lg={3} className="pb-2 pb-lg-0  pl-sm-1 pr-lg-1">
                          <Alert variant="success" className="h-100 mb-0">
                            Matrícula
                            <h3><b>{record.matricula ? dateToLocal(record.matricula) : '--/--/----'}</b></h3>
                          </Alert>
                        </Col>
                        <Col sm={6} lg={3} className="pb-2 pb-sm-0 pr-sm-1 pl-lg-1">
                          <Alert variant="primary" className="h-100 mb-0">
                            Formatura
                            <h3><b>{record.formatura ? dateToLocal(record.formatura) : '--/--/----'}</b></h3>
                          </Alert>
                        </Col>
                        <Col sm={6} lg={3} className="pl-sm-1">
                          <Alert variant="danger" className="h-100 mb-0">
                            Desistência
                            <h3><b>{record.desistencia ? dateToLocal(record.desistencia) : '--/--/----'}</b></h3>
                          </Alert>
                        </Col>
                      </Row>
                    </Card.Body>
                  </Card>
                </Col>
                <Col md={6} className="pr-md-2">
                  <Card className="mb-3">
                    <Card.Body className="p-2">
                      <Card.Title>Dados do aluno</Card.Title>
                      <Table className="mb-0">
                        <tbody>
                          <tr>
                            <th className="text-right">Nome:</th>
                            <td>{record.nome}</td>
                          </tr>
                          <tr>
                            <th className="text-right">Nascimento:</th>
                            <td>{dateToLocal(record.nascimento)}</td>
                          </tr>
                          <tr>
                            <th className="text-right">Idade:</th>
                            <td>{calculaIdade(record.nascimento) + ' anos'}</td>
                          </tr>
                          <tr>
                            <th className="text-right">CPF:</th>
                            <td>{record.cpf}</td>
                          </tr>
                          <tr>
                            <th className="text-right">RG:</th>
                            <td>{record.rg}</td>
                          </tr>
                          <tr>
                            <th className="text-right">Email:</th>
                            <td>{record.email}</td>
                          </tr>
                          <tr>
                            <th className="text-right">Fone:</th>
                            <td>{record.fone}</td>
                          </tr>
                          <tr>
                            <th className="text-right">Fone emergência:</th>
                            <td>{record.foneEmergencia}</td>
                          </tr>
                          <tr>
                            <th className="text-right">Colégio/Escola:</th>
                            <td>{record.colegio}</td>
                          </tr>
                          <tr>
                            <th className="text-right">Série:</th>
                            <td>{serieLabel(record.serie)}&nbsp;&nbsp;&nbsp;&nbsp;<b>Turno:</b>&nbsp;&nbsp;{turnoLabel(record.turno)}</td>
                          </tr>
                          <tr>
                            <th className="text-right">Endereço:</th>
                            <td>{record.endereco}</td>
                          </tr>
                          <tr>
                            <th className="text-right">Bairro:</th>
                            <td>{record.bairro}</td>
                          </tr>
                          <tr>
                            <th className="text-right">Municipio:</th>
                            <td>{record.municipio}&nbsp;&nbsp;&nbsp;&nbsp;<b>UF:</b> &nbsp;&nbsp;{record.uf}</td>
                          </tr>
                        </tbody>
                      </Table>
                    </Card.Body>
                  </Card>
                </Col>
                <Col md={6} className="pl-md-2">
                  <Card className="mb-3 h-100">
                    <Card.Body className="p-2">
                      <Card.Title>Repertório</Card.Title>
                    </Card.Body>
                  </Card>
                </Col>
              </Row>

            </div>
          </Modal.Body>
        </Modal>
      </React.Fragment>
    );
  }
}

export default ProfileAluno;