import React, { Component } from "react";
import { Row, Col, InputGroup } from "react-bootstrap";

import { SelectUnidades, SelectCursos } from '../../../../components';

class HeaderProfessor extends Component {
  constructor(props) {
    super(props);
    this.state = {
      idUnidade: '',
      idCurso: ''
    };
    this.upState = props.onChange;
  }

  handleInputChange = (event) => {
    event.preventDefault();
    const { name, value } = event.target;
    this.handleComponentChange(name, value);
  };

  handleComponentChange = (name, value) => {
    if (this.state[name] !== value) {
      this.setState({ [name]: value }, this.upState(name, value));
    }
  };

  render() {
    const { idUnidade, idCurso } = this.state;

    return (
      <Row noGutters className="mb-3">
        <Col sm={5} md={6} lg={7}><h1 className="page-title">Chamada</h1></Col>
        <Col sm={7} md={6} lg={5} className="pt-3 pl-sm-2">
          <Row noGutters>
            <Col sm={7} className="pr-sm-2">
              <InputGroup size="sm" className="mb-2 mb-sm-0">
                <InputGroup.Prepend>
                  <InputGroup.Text>Unidade</InputGroup.Text>
                </InputGroup.Prepend>
                <SelectUnidades
                  name="idUnidade"
                  unidades={this.props.usuario.unidades}
                  idUnidade={idUnidade}
                  onChange={this.handleComponentChange}
                />
              </InputGroup>
            </Col>
            <Col sm={5}>
              <InputGroup size="sm" className="mb-2 mb-sm-0">
                <InputGroup.Prepend>
                  <InputGroup.Text>Curso</InputGroup.Text>
                </InputGroup.Prepend>
                <SelectCursos
                  name="idCurso"
                  idCurso={idCurso}
                  cursos={this.props.usuario.cursos}
                  onChange={this.handleComponentChange}
                />
              </InputGroup>
            </Col>
          </Row>
        </Col>
      </Row >
    );
  }
}

export default HeaderProfessor;