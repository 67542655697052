import React, { Component } from "react";
import { InputGroup } from "react-bootstrap";

class SelectHorario extends Component {
  constructor(props) {
    super(props);
    this.state = {
      horario: '',
      hora: '',
      minuto: '',
      error: ''
    };
    this.onChange = props.onChange;
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.value !== prevState.horario
      || nextProps.error !== prevState.error) {
      return {
        horario: nextProps.value,
        hora: parseInt(nextProps.value / 60),
        minuto: nextProps.value % 60,
        error: nextProps.error
      };
    }
    return null;
  }

  handleChange = (event) => {
    event.preventDefault();
    let { name, value } = event.target,
      { hora, minuto } = this.state;

    value = parseInt(value);

    if (name === 'hora' && value !== hora) {
      let horario = (value * 60) + minuto;
      this.setState({
        horario: horario,
        hora: value
      }, this.onChange(this.props.name, horario));
    }

    if (name === 'minuto' && value !== minuto) {
      let horario = (hora * 60) + value;
      this.setState({
        horario: horario,
        minuto: value
      }, this.onChange(this.props.name, horario));
    }
  };

  render() {
    const { hora, minuto, error } = this.state;

    return (
      <InputGroup className={'horario ' + (error ? 'is-invalid ' : '') + (this.props.required ? 'required' : '')}>
        <select
          name="hora"
          value={hora}
          onChange={this.handleChange}
          onBlur={this.handleChange}
          className="custom-select"
        >
          <option value="7">07</option>
          <option value="8">08</option>
          <option value="9">09</option>
          <option value="10">10</option>
          <option value="11">11</option>
          <option value="12">12</option>
          <option value="13">13</option>
          <option value="14">14</option>
          <option value="15">15</option>
          <option value="16">16</option>
          <option value="17">17</option>
          <option value="18">18</option>
          <option value="19">19</option>
          <option value="20">20</option>
          <option value="21">21</option>
        </select>
        <select
          name="minuto"
          value={minuto}
          onChange={this.handleChange}
          onBlur={this.handleChange}
          className="custom-select"
        >
          <option value="0">00</option>
          <option value="5">05</option>
          <option value="10">10</option>
          <option value="15">15</option>
          <option value="20">20</option>
          <option value="25">25</option>
          <option value="30">30</option>
          <option value="35">35</option>
          <option value="40">40</option>
          <option value="45">45</option>
          <option value="50">50</option>
          <option value="55">55</option>
        </select>
      </InputGroup>
    )
  }
}

export default SelectHorario;
