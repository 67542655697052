import React, { Component } from 'react'
import Chart from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels';

let myChart;

class FrequenciaStackedChart extends Component {

  componentDidMount = () => {
    this.buildChart();
  };

  componentDidUpdate = () => {
    this.buildChart();
  };

  buildChart = () => {
    let element = document.getElementById("stackedchart-frequencia"),
      myChartRef = element.getContext("2d");

    element.height = this.props.heigth;

    if (typeof myChart !== "undefined") myChart.destroy();

    const compare = (a, b) => a.aulas.idxPresenca > b.aulas.idxPresenca ? -1 : (a.aulas.idxPresenca < b.aulas.idxPresenca ? 1 : 0);

    const totalizer = {
      id: 'totalizer',
      beforeUpdate: chart => {
        let totals = {};
        let utmost = 0;

        chart.data.datasets.forEach((dataset, datasetIndex) => {
          if (chart.isDatasetVisible(datasetIndex)) {
            utmost = datasetIndex;
            dataset.data.forEach((value, index) => {
              totals[index] = (totals[index] || 0) + value;
            });
          }
        });

        chart.$totalizer = {
          totals: totals,
          utmost: utmost
        };
      }
    };

    let unidades = this.props.unidades.slice();

    for (let i = 0; i < unidades.length; i++) {
      unidades[i].aulas.idxAusencia = parseInt((unidades[i].aulas.ausente * 100) / unidades[i].aulas.total);
      unidades[i].aulas.idxPresenca = 100 - unidades[i].aulas.idxAusencia;
    }
    unidades.sort(compare);

    myChart = new Chart(myChartRef, {
      type: 'horizontalBar',
      data: {
        labels: unidades.map(u => u.unidade),
        datasets: [
          {
            label: "Presente",
            data: unidades.map(u => u.aulas.idxPresenca),
            categoryPercentage: 0.9,
            barPercentage: 1.0,
            backgroundColor: 'rgba(92, 184, 92, 1)',
            borderWidth: 1,

          },
          {
            label: "Ausente",
            data: unidades.map(u => u.aulas.idxAusencia),
            categoryPercentage: 0.9,
            barPercentage: 1.0,
            backgroundColor: 'rgba(217, 83, 79, 1)',
            borderWidth: 1
          }
        ]
      },
      options: {
        title: {
          display: false,
          text: 'Índice de Frequência',
          fontSize: 14,
          padding: 8
        },
        legend: {
          display: false,
          position: 'bottom',
          labels: { padding: 5, boxWidth: 12 }
        },
        layout: {
          padding: {
            left: 0,
            right: 0,
            top: -10,
            bottom: -5
          }
        },
        plugins: {
          datalabels: {
            color: 'rgba(256, 256, 256,1)',
            formatter: function (value) { return value > 0 ? value + '%' : '' }
          }
        },
        scales: {
          xAxes: [{
            gridLines: { zeroLineColor: 'rgba(0, 0, 0, 0.5)' },
            stacked: true,
            ticks: { fontColor: '#212528' },
          }],
          yAxes: [{
            gridLines: { tickMarkLength: 0 },
            stacked: true,
            ticks: { fontColor: '#212528', padding: 10 },
          }]
        },
        maintainAspectRatio: false
      },
      plugins: [ChartDataLabels, totalizer]
    });
  };

  render() {
    return (
      <canvas id="stackedchart-frequencia" />
    )
  }
}

export default FrequenciaStackedChart;