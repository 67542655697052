import React, { Component } from 'react'
import Chart from 'chart.js';

let myChart;

class AlunosNivelPieChart extends Component {

  componentDidMount = () => {
    this.buildChart();
  };

  componentDidUpdate = () => {
    this.buildChart();
  };

  buildChart = () => {
    let element = document.getElementById("piechart-nivel"),
      myChartRef = element.getContext("2d");

    element.height = this.props.heigth;

    if (typeof myChart !== "undefined") myChart.destroy();

    const unidades = this.props.unidades.slice();
    let niveis = [0, 0, 0, 0, 0];

    unidades.forEach(unidade => {
      niveis[0] += unidade.alunos.nivel.iniciante;
      niveis[1] += unidade.alunos.nivel.intermediario;
      niveis[2] += unidade.alunos.nivel.avancado;
      niveis[3] += unidade.alunos.nivel.especial;
      niveis[4] += unidade.alunos.nivel.monitor;

    });

    myChart = new Chart(myChartRef, {
      type: 'pie',
      data: {
        labels: ['Iniciante', 'Intermediário', 'Avançado', 'Especial', 'Monitor'],
        datasets: [
          {
            data: niveis,
            backgroundColor: ['rgba(92, 184, 92, 0.5)', 'rgba(240, 173, 78, 0.5)', 'rgba(2, 117, 216, 0.5)', 'rgba(128, 0, 128, 0.5)', 'rgba(255, 99, 132, 0.5)'],
            datalabels: {
              color: 'rgba(0, 0, 0, 1)',
              anchor: 'end',
              align: 'start',
              formatter: function (value, context) {
                const total = context.dataset.data.reduce((a, b) => a + b, 0);
                return Math.round((value / total) * 100) + '%';
              }
            }
          }
        ]
      },
      options: {
        title: {
          display: true,
          text: 'Alunos / Nível',
          fontSize: 14
        },
        legend: {
          position: 'right',
          labels: { padding: 5, boxWidth: 12 }
        },
        layout: {
          padding: {
            left: 0,
            right: 0,
            top: -10,
            bottom: 0
          }
        },
        maintainAspectRatio: false
      }
    });
  };

  render() {
    return (
      <canvas id="piechart-nivel" />
    )
  }
}

export default AlunosNivelPieChart;