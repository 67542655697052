import React, { Component } from "react";
import { Container, Row, Col, Card, Table, Button } from "react-bootstrap";
import { CSSTransition } from 'react-transition-group';
import { toast } from 'react-toastify';
import Pagination from "react-js-pagination";
import {
  Loading,
  ButtonAdd,
  ButtonEdit,
  ButtonDelete,
  PaginationPerPage
} from "../../../components";
import Filter from './Filter';
import FormColegio from "./FormColegio";
import { filterQuery } from '../../../utils/misc';
import api from "../../../services/api";

const emptyRecord = { id: '', idEstado: 1, municipio: '', colegio: '', tipo: '', email: '', fone: '' };

class Colegios extends Component {
  usuario = JSON.parse(localStorage.getItem('usuario'));

  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      showModal: false,
      records: [],
      totalRecords: 0,
      record: emptyRecord,
      query: {
        colegio: '',
        tipo: '',
        municipio: '',
        order: 'colegios.colegio',
        direction: 'ASC',
        limit: 50,
        offset: 0
      },
      activePage: 1
    };
  }

  loadFromAPI = () => {
    this.setState({ isLoading: true }, () => {
      api.get('/colegios', { params: filterQuery(this.state.query) })
        .then(response => {
          this.setState({
            records: response.data.colegios ? response.data.colegios : [],
            totalRecords: response.data.total ? response.data.total : 0,
            isLoading: false
          });
        })
        .catch(error => {
          this.setState({ isLoading: false });
          toast.error('Falha ao obter dados!');
        });
    });
  };

  doDelete = (id) => {
    api.delete('/colegios', { data: { id: id } })
      .then((response) => {
        if (response.data.message === 'ok') {
          toast.success('Escola/Colégio excluido com sucesso!');
          this.loadFromAPI();
        } else {
          toast.error(response.data.message);
        }
      })
      .catch((error) => {
        toast.error('Ocorreu uma falha ao excluir!');
      });
  };

  handlePageChange = (pageNumber) => {
    let query = Object.assign({}, this.state.query);
    query.offset = (pageNumber * query.limit) - query.limit;
    this.setState({
      query: query,
      activePage: pageNumber
    }, () => this.loadFromAPI());
  };

  setQuery = (filter) => {
    let query = Object.assign({}, this.state.query);
    query.colegio = filter.colegio;
    query.tipo = filter.tipo;
    query.municipio = filter.municipio;
    this.setState({ query: query }, () => this.loadFromAPI());
  };

  setLimit = (limit) => {
    let query = Object.assign({}, this.state.query);
    query.limit = limit;
    query.offset = 0;
    this.setState({
      query: query,
      activePage: 1
    }, () => this.loadFromAPI());
  };

  setOrder = (order) => {
    let query = Object.assign({}, this.state.query);
    query.order = order;
    query.direction = (query.order === order && query.direction === 'ASC') ? 'DESC' : 'ASC';

    if (this.state.totalRecords <= query.limit) {
      // reordena em memoria
      let records = this.state.records;
      if (order.indexOf('.') > 0) {
        order = order.split('.');
        order = order[1];
      }

      if (records.length && query.direction === 'ASC') {
        records.sort((a, b) => (a[order] > b[order]) ? 1 : -1);
      } else {
        records.sort((a, b) => (a[order] < b[order]) ? 1 : -1);
      }
      this.setState({
        records: records,
        query: query
      })
    } else {
      // busca na api
      this.setState({ query: query });
      this.loadFromAPI();
    }
  };

  openModalForm = (record) => {
    this.setState({
      showModal: true,
      record: record
    });
  };

  closeModalForm = () => {
    this.setState({
      showModal: false,
      record: emptyRecord
    });
  };

  componentDidMount = () => {
    this.loadFromAPI();
  };

  render() {
    const { isLoading, showModal, records, record, query, activePage, totalRecords } = this.state;

    const labelTipo = (tipo) => {
      return tipo === 'M' ? 'Municipal' : (tipo === 'E' ? 'Estadual' : (tipo === 'P' ? 'Particular' : ''));
    };

    return (
      <Container fluid>
        <Row className="mb-3">
          <Col><h1 className="page-title">Colégios</h1></Col>
          <Col sm="auto" className="pt-3 pt-sm-2">
            <ButtonAdd label="Adicionar colégio" onClick={() => this.openModalForm(emptyRecord)} />
          </Col>
        </Row>
        <Filter
          idProfessor={this.usuario.idProfessor ? this.usuario.idProfessor : null}
          setQuery={this.setQuery}
        />
        <br />
        <Card className="card-list">
          <Card.Body className="p-0">
            <div className="loading">
              <CSSTransition
                in={isLoading}
                timeout={500}
                classNames="fade"
                unmountOnExit
              >
                <div><Loading /></div>
              </CSSTransition>
              <Row className="pt-2 pb-1 pl-3 pr-2">
                <Col className="text-center text-sm-left">
                  <span className="nowrap">Total: <b>{totalRecords}</b> registro(s).</span>&nbsp;
                  {totalRecords > query.limit && (<PaginationPerPage limit={query.limit} setLimit={this.setLimit} />)}
                </Col>
                <Col>
                  {totalRecords > query.limit && (
                    <Pagination
                      activePage={activePage}
                      itemsCountPerPage={query.limit}
                      totalItemsCount={totalRecords}
                      pageRangeDisplayed={5}
                      onChange={this.handlePageChange}
                      innerClass={'pagination pagination-sm float-sm-right my-0'}
                      itemClass={'page-item'}
                      linkClass={'page-link'}
                      activeLinkClass={'page-link'}
                    />
                  )}
                </Col>
              </Row>
              <Table striped responsive className="table-data">
                <thead className="thead-light">
                  <tr>
                    <th>#</th>
                    <th><Button variant="link" size="sm" onClick={() => this.setOrder('colegios.colegio')}>Nome</Button></th>
                    <th className="text-center"><Button variant="link" size="sm" onClick={() => this.setOrder('colegios.tipo')}>Tipo</Button></th>
                    <th><Button variant="link" size="sm" onClick={() => this.setOrder('municipios.municipio')}>Município</Button></th>
                    <th className="text-center"><Button variant="link" size="sm" onClick={() => this.setOrder('alunos')}>Alunos</Button></th>
                    <th className="text-center">Email</th>
                    <th className="text-center">Fone</th>
                    <th style={{ width: "76px" }}></th>
                  </tr>
                </thead>
                <tbody>
                  {
                    !records.length ? (
                      <tr><td colSpan={8} className="empty">Nenhum registro </td></tr>
                    ) : (
                        records.map((item, key) => {
                          return (
                            <tr key={key}>
                              <td><span className="text-muted">{key + 1}</span></td>
                              <td><b>{item.colegio}</b></td>
                              <td className="text-center">{labelTipo(item.tipo)}</td>
                              <td>{item.municipio}</td>
                              <td className="text-center">{item.alunos}</td>
                              <td className="text-center">{item.email}</td>
                              <td className="text-center">{item.fone}</td>
                              <td className="text-right">
                                <ButtonEdit onClick={() => this.openModalForm(item)} />
                                <ButtonDelete id={item.id} label={item.colegio} doDelete={this.doDelete} />
                              </td>
                            </tr>
                          )
                        })
                      )
                  }
                </tbody>
              </Table>

            </div>
          </Card.Body>
        </Card>
        <br />
        <FormColegio
          emptyRecord={emptyRecord}
          record={record}
          showModal={showModal}
          loadFromAPI={this.loadFromAPI}
          closeModalForm={this.closeModalForm}
        />
      </Container>
    );
  }
}

export default Colegios;