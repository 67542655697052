import React, { Component } from "react";

class SelectGenero extends Component {
  constructor(props) {
    super(props);
    this.state = {
      value: props.genero ? props.genero : ''
    };
    this.onChange = props.onChange;
  }

  handleChange = (event) => {
    event.preventDefault();
    const value = event.target.value;
    if (this.state.value !== value) {
      this.setState({ value: value }, this.onChange(this.props.name, value));
    }
  };

  render() {
    const { value } = this.state;

    return (
      <select
        value={value}
        onChange={this.handleChange}
        required={this.props.required}
        className="custom-select"
      >
        <option value="">{this.props.label}</option>
        <option value="F">Feminino</option>
        <option value="M">Masculino</option>
        <option value="O">Outro</option>
        <option value="N">Prefiro não dizer</option>
      </select>
    )
  }
}

export default SelectGenero;
