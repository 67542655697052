import React, { Component } from "react";
import { Container, Row, Col, Card, Table } from "react-bootstrap";
import { CSSTransition } from 'react-transition-group';
import { toast } from 'react-toastify';
import {
  Loading,
  ButtonAdd,
  ButtonEdit,
  ButtonDelete,
  StatusBadge
} from "../../../components";
import FormCurso from "./FormCurso";
import api from "../../../services/api";

const emptyRecord = { id: '', curso: '', ativo: 'S', professores: [] };

class Cursos extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      showModal: false,
      records: [],
      totalRecords: 0,
      record: emptyRecord
    };
  }

  loadFromAPI = () => {
    this.setState({ isLoading: true }, () => {
      api.get('/cursos')
        .then(response => {
          this.setState({
            records: response.data.cursos ? response.data.cursos : [],
            totalRecords: response.data.cursos ? response.data.cursos.length : 0,
            isLoading: false
          });
        })
        .catch(error => {
          this.setState({ isLoading: false });
          toast.error('Falha ao obter dados!');
        });
    });
  };

  doDelete = (id) => {
    api.delete('/cursos', { data: { id: id } })
      .then((response) => {
        if (response.data.message === 'ok') {
          toast.success('Unidade excluida com sucesso!');
          this.loadFromAPI();
        } else {
          toast.error(response.data.message);
        }
      })
      .catch((error) => {
        toast.error('Ocorreu uma falha ao excluir!');
      });
  };

  openModalForm = (record) => {
    this.setState({
      showModal: true,
      record: record
    });
  };

  closeModalForm = () => {
    this.setState({
      showModal: false,
      record: emptyRecord
    });
  };

  componentDidMount = () => {
    this.loadFromAPI();
  };

  render() {
    const { isLoading, showModal, totalRecords, records, record } = this.state;

    return (
      <Container fluid>
        <Row className="mb-4">
          <Col><h1 className="page-title">Cursos</h1></Col>
          <Col sm="auto" className="pt-3 pt-sm-2">
            <ButtonAdd label="Adicionar curso" onClick={() => this.openModalForm(emptyRecord)} />
          </Col>
        </Row>

        <Card className="card-list">
          <Card.Body className="p-0">
            <div className="loading">
              <div className="nowrap mx-3 py-2">Total: <b>{totalRecords}</b> registro(s).</div>
              <Table striped responsive className="table-data">
                <thead className="thead-light">
                  <tr>
                    <th style={{ width: "25px" }}>#</th>
                    <th>Curso</th>
                    <th className="text-center">Inscritos</th>
                    <th className="text-center">Matriculados</th>
                    <th className="text-center">Formados</th>
                    <th className="text-center">Desistencias</th>
                    <th className="text-center">Professores</th>
                    <th className="text-center">Atividades</th>
                    <th className="text-center">Status</th>
                    <th style={{ width: "76px" }}></th>
                  </tr>
                </thead>
                <tbody>
                  {!records.length ? (
                    <tr><td colSpan={10} className="empty">Nenhum registro </td></tr>
                  ) : (
                      records.map((curso, key) => {
                        return (
                          <tr key={key}>
                            <td><span className="text-muted">{key + 1}</span></td>
                            <td><b>{curso.curso}</b></td>
                            <td className="text-center">{curso.inscritos}</td>
                            <td className="text-center">{curso.matriculados}</td>
                            <td className="text-center">{curso.formados}</td>
                            <td className="text-center">{curso.desistencias}</td>
                            <td className="text-center">{curso.professores.length}</td>
                            <td className="text-center">{curso.atividades}</td>
                            <td className="text-center"><StatusBadge ativo={curso.ativo} /></td>
                            <td className="text-right">
                              <ButtonEdit onClick={() => this.openModalForm(curso)} />
                              <ButtonDelete id={curso.id} label={curso.curso} doDelete={this.doDelete} />
                            </td>
                          </tr>
                        )
                      })
                    )}
                </tbody>
              </Table>
              <CSSTransition in={isLoading} timeout={500} classNames="fade" unmountOnExit>
                <div><Loading /></div>
              </CSSTransition>
            </div>
          </Card.Body>
        </Card>
        <br />
        <FormCurso
          emptyRecord={emptyRecord}
          record={record}
          showModal={showModal}
          loadFromAPI={this.loadFromAPI}
          closeModalForm={this.closeModalForm}
        />
      </Container>
    );
  }
}

export default Cursos;