import React, { Component, memo } from "react";
import { toast } from 'react-toastify';

import api from "../../services/api";

class SelectColegios extends Component {
  constructor(props) {
    super(props);
    this.state = {
      options: [],
      value: '',
      error: ''
    };
    this.UpState = props.onChange;
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.idColegio !== prevState.value
      || nextProps.error !== prevState.error) {
      return {
        value: nextProps.idColegio,
        error: nextProps.error
      };
    }
    return null;
  }

  handleChange = (event) => {
    event.preventDefault();
    const value = event.target.value;
    if (this.state.value !== value) {
      this.setState({ value: value }, this.UpState(this.props.name, value));
    }

  };

  loadFromAPI = () => {
    api.get('/colegios')
      .then(response => this.setState({ options: response.data.colegios }))
      .catch(error => toast.error(error.message));
  };

  componentDidMount = () => {
    this.loadFromAPI();
  };

  render() {
    const { options, value, error } = this.state;

    return (
      <select
        name={this.props.name}
        value={value}
        onChange={this.handleChange}
        onBlur={this.handleChange}
        className={'custom-select' + (error ? ' is-invalid' : '')}
        required={this.props.required}
      >
        <option value=""></option>
        {options.map((u, i) => <option key={i} value={u.id}>{u.colegio + ' (' + u.municipio + ')'}</option>)}
      </select>
    )
  }
}

export default memo(SelectColegios);
