import React, { Component, Fragment } from "react";
import { Container, Image } from "react-bootstrap";
import SweetAlert from "react-bootstrap-sweetalert";

import logoFestival from '../assets/img/festival-virtual-da-gaita-ponto.jpg';

class Festival extends Component {
  constructor(props, context) {
    super(props, context);
    this.state = { alert: null };
  }

  hideAlert = () => {
    this.setState({ alert: null });
  };

  componentDidMount = () => {
    this.setState({
      alert: <SweetAlert title="Inscrições encerradas!" onConfirm={() => this.hideAlert()} />
    });
  };


  render() {
    const { alert } = this.state;

    return (
      <Fragment>
        {alert}
        <Container fluid className="full-width">
          <Image src={logoFestival} fluid style={{ 'width': '100%' }} />
        </Container >
      </Fragment >
    );
  }
}

export default Festival;
