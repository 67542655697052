import React, { memo } from 'react';
import { Button } from "react-bootstrap";

function ButtonAdd({ label, onClick }) {
  return <Button
    variant="success"
    title={label}
    onClick={onClick}
  >
    <i className="fa fa-plus"></i> {label}
</Button>
};

export default memo(ButtonAdd);