import React, { Component } from "react";

class SelectDiaSemana extends Component {
  constructor(props) {
    super(props);
    this.state = {
      value: '',
      error: ''
    };
    this.onChange = props.onChange;
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.dia !== prevState.value
      || nextProps.error !== prevState.error) {
      return {
        value: nextProps.dia,
        error: nextProps.error
      };
    }
    return null;
  }

  handleChange = (event) => {
    event.preventDefault();
    const value = parseInt(event.target.value);
    if (this.state.value !== value) {
      this.setState({ value: value }, this.onChange(this.props.name, value));
    }
  };

  render() {
    const { value, error } = this.state;

    return (
      <select
        value={value}
        onChange={this.handleChange}
        onBlur={this.handleChange}
        className={'custom-select' + (error ? ' is-invalid' : '')}
        required={this.props.required}
      >
        <option value="0">Domingo</option>
        <option value="1">Segunda</option>
        <option value="2">Terça</option>
        <option value="3">Quarta</option>
        <option value="4">Quinta</option>
        <option value="5">Sexta</option>
        <option value="6">Sábado</option>
      </select>
    )
  }
}

export default SelectDiaSemana;
