import React, { Component } from "react";

class SelectNiveis extends Component {
  constructor(props) {
    super(props);
    this.state = {
      value: '',
      matricula: '',
      error: '',
      required: ''
    };
    this.onChange = props.onChange;
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.nivel !== prevState.value
      || nextProps.matricula !== prevState.matricula
      || nextProps.error !== prevState.error
      || nextProps.required !== prevState.required) {
      return {
        value: nextProps.nivel,
        matricula: nextProps.matricula,
        error: nextProps.error,
        required: nextProps.required
      };
    }
    return null;
  }

  handleChange = (event) => {
    event.preventDefault();
    const value = event.target.value;
    if (this.state.value !== value) {
      this.setState({ value: value }, this.onChange(this.props.name, value));
    }
  };

  render() {
    const { value, matricula, error, required } = this.state;

    return (
      <select
        value={value}
        onChange={this.handleChange}
        onBlur={this.handleChange}
        className={'custom-select' + (error ? ' is-invalid' : '')}
        required={required}
        disabled={((typeof matricula !== 'undefined') && !matricula) ? true : false}
      >
        <option value=""></option>
        <option value="1">Iniciante</option>
        <option value="2">Intermediario</option>
        <option value="3">Avançado</option>
        <option value="4">Especial</option>
        <option value="5">Monitor</option>
      </select>
    )
  }
}

export default SelectNiveis;
