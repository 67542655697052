import React, { memo } from 'react';
import { Button } from "react-bootstrap";

function ButtonEdit({ title, onClick }) {
  return <Button
    variant="warning"
    title={title ? title : "Editar"}
    className="btn-icon-sm"
    onClick={onClick}
  >
    <i className="fa fa-pencil"></i>
  </Button>
};

export default memo(ButtonEdit);