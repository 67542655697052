import React, { Component } from "react";
import { Modal, Form, Col, Button, ButtonGroup, Badge, Collapse } from "react-bootstrap";
import { CSSTransition } from 'react-transition-group';
import { toast } from 'react-toastify';
import SweetAlert from "react-bootstrap-sweetalert";
import DatePicker from "react-datepicker";
import { isValidCPF, formatCPF } from '@brazilian-utils/brazilian-utils';
import {
  Loading,
  TypeaheadMunicipios,
  TypeaheadColegios,
  SelectEstados,
  SelectSeries,
  SelectTurnos,
  SelectGeneros,
  SelectUnidades,
  SelectCursos,
  SelectNiveis
} from "../../../components";
import { validEmailRegex } from "../../../utils/validation";
import { dateToServer } from "../../../utils/dates";
import { formatFone } from "../../../utils/fone";
import api from "../../../services/api";

const errorsReset = {
  idEstado: '',
  idUnidade: '',
  idCurso: '',
  municipio: '',
  nome: '',
  nascimento: '',
  cpf: '',
  rg: '',
  endereco: '',
  bairro: '',
  email: '',
  fone: '',
  foneEmergencia: '',
  colegio: '',
  serie: '',
  turno: '',
  responsavel: '',
  respTipo: '',
  respRG: '',
  respFone: '',
  inscricao: '',
  matricula: '',
  nivel: '',
  diaSemana: '',
  inicio: '',
  fim: ''
};
const stepsReset = {
  aluno: true,
  endereco: false,
  responsavel: false,
  matricula: false
};

class FormAluno extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showModal: false,
      isLoading: false,
      alert: null,
      record: {},
      steps: stepsReset,
      errors: errorsReset
    };
    this.loadFromAPI = props.loadFromAPI;
    this.closeFormAluno = props.closeFormAluno;
  }

  static getDerivedStateFromProps(props, state) {
    if (props.showModal !== state.showModal) {
      let record = Object.assign({}, props.record);

      for (const key in record) {
        if (key === 'nascimento' || key === 'inscricao' || key === 'matricula' || key === 'formatura' || key === 'desistencia') {
          if (record[key] && record[key].length) record[key] = new Date(record[key].replace('Z', ''));
          else record[key] = '';
        } else if (record[key] === null) {
          record[key] = '';
        }
      }

      if (props.usuario.unidades.length === 1) record.idUnidade = props.usuario.unidades[0].id;
      if (props.usuario.cursos.length === 1) record.idCurso = props.usuario.cursos[0].id;

      return {
        showModal: props.showModal,
        record: record
      };
    }
    return null;
  }

  closeModal = () => {
    this.setState({
      showModal: false,
      isLoading: false,
      steps: stepsReset,
      record: this.props.emptyRecord,
      errors: errorsReset
    }, () => this.closeFormAluno());
  };

  toggleStep = (name) => {
    let steps = Object.assign({}, this.state.steps);
    steps.aluno = false;
    steps.endereco = false;
    steps.responsavel = false;
    steps.matricula = false;
    steps[name] = true;
    this.setState({ steps: steps });
  };

  validateInput = (name, value, errors) => {
    switch (name) {
      case 'idUnidade':
        if (!value || value.length === 0) {
          errors.idUnidade = 'Selecione uma UNIDADE';
          errors.idCurso = '';
        } else {
          errors.idUnidade = '';
        }
        break;
      case 'idCurso':
        errors.idCurso = ((!value || value.length === 0) && this.state.record.idUnidade) ? 'Selecione um CURSO' : '';
        break;
      case 'idEstado':
        errors.idEstado = (!value || value.length === 0) ? 'Selecione um ESTADO' : '';
        break;
      case 'municipio':
        errors.municipio = (!value || value.length === 0) ? 'Preencha o campo MUNICIPIO' : '';
        break;
      case 'nome':
        errors.nome = (!value || value.length === 0) ? 'Preencha o campo NOME' : '';
        break;
      case 'nascimento':
        errors.nascimento = (!value || value.length === 0) ? 'Preencha o campo NASCIMENTO' : '';
        break;
      case 'cpf':
        // if (!value || value.length === 0) {
        //   errors.cpf = 'Preencha o campo CPF';
        // }
        if (value && !isValidCPF(value)) {
          errors.cpf = 'CPF inválido';
        } else {
          errors.cpf = '';
        }
        break;
      // case 'rg':
      //   errors.rg = (!value || value.length === 0) ? 'Preencha o campo RG' : '';
      //   break;
      // case 'bairro':
      //   errors.bairro = (!value || value.length === 0) ? 'Preencha o campo BAIRRO' : '';
      //   break;
      case 'email':
        // if (!value || value.length === 0) {
        //   errors.email = 'Preencha o campo EMAIL';
        // }
        if (value && !validEmailRegex.test(value)) {
          errors.email = 'EMAIL inválido';
        } else {
          errors.email = '';
        }
        break;
      case 'colegio':
        errors.colegio = (!value || value.length === 0) ? 'Preencha o campo ESCOLA/COLÉGIO' : '';
        break;
      case 'serie':
        errors.serie = (!value || value.length === 0) ? 'Selecione uma SÉRIE' : '';
        break;
      case 'turno':
        errors.turno = (!value || value.length === 0) ? 'Selecione um TURNO' : '';
        break;
      case 'responsavel':
        errors.responsavel = (!value || value.length === 0) ? 'Preencha o campo RESPONSAVEL' : '';
        break;
      case 'respRG':
        errors.respRG = (!value || value.length === 0) ? 'Preencha o campo RG DO RESPONSAVEL' : '';
        break;
      case 'respFone':
        errors.respFone = (!value || value.length === 0) ? 'Preencha o campo FONE DO RESPONSAVEL' : '';
        break;
      // case 'inscricao':
      //   errors.inscricao = (!value || value.length === 0) ? 'Preencha o campo INSCRIÇÃO' : '';
      //   break;
      case 'matricula':
        errors.matricula = (!value || value.length === 0) ? 'Preencha o campo MATRÍCULA' : '';
        break;
      case 'nivel':
        errors.nivel = (this.state.record.matricula && (!value || value.length === 0)) ? 'Selecione um NÍVEL' : '';
        break;
      default:
    }

    return errors;
  };

  validateForm = () => {
    let record = Object.assign({}, this.state.record),
      errors = Object.assign({}, this.state.errors);

    for (const key in record) {
      errors = this.validateInput(key, record[key], errors);
    }

    return errors;
  };

  handleInputChange = (event) => {
    event.preventDefault();
    const { name, value } = event.target;
    this.handleComponentChange(name, value);
  };

  handleComponentChange = (name, value) => {
    let record = Object.assign({}, this.state.record);
    let errors = Object.assign({}, this.state.errors);

    if (name === 'cpf') value = formatCPF(value);
    if (name === 'fone' || name === 'foneEmergencia' || name === 'respFone') value = formatFone(value);

    record[name] = value;
    this.setState({
      record: record,
      errors: this.validateInput(name, value, errors)
    });
  };

  hideAlert = () => {
    this.setState({ alert: null });
  };

  handleSubmit = async (event) => {
    event.preventDefault();
    // const errors = this.validateForm();
    // if (JSON.stringify(errors) !== JSON.stringify(errorsReset)) {
    //   this.setState({
    //     alert: (<SweetAlert warning title="Oops!" onConfirm={() => this.hideAlert()} showConfirm={false} timeout={2000}>Preenchimento incorreto!</SweetAlert>),
    //     errors: errors
    //   });
    // } else {
      try {
        this.setState({ isLoading: true });
        const record = Object.assign({}, this.state.record);
        record.nascimento = dateToServer(record.nascimento);
        record.inscricao = dateToServer(record.inscricao);
        record.matricula = record.matricula ? dateToServer(record.matricula) : '';
        record.formatura = record.formatura ? dateToServer(record.formatura) : '';
        record.desistencia = record.desistencia ? dateToServer(record.desistencia) : '';
        const response = await api.post('/alunos', record);
        if (response.data.message === 'ok') {
          toast.success('Aluno salvo com sucesso!');
          this.loadFromAPI();
          this.closeModal();
        } else {
          this.setState({
            isLoading: false,
            alert: (<SweetAlert warning title="Oops!" onConfirm={() => this.hideAlert()} showConfirm={false} timeout={2000}>{response.data.message}</SweetAlert>),
          });
        }
      } catch (error) {
        this.setState({ isLoading: false });
        toast.error('Falha ao enviar os dados!');
      }
    // }
  };

  render() {
    const { showModal, isLoading, alert, record, steps, errors } = this.state;

    return (
      <React.Fragment>
        {alert}
        <Modal size="lg" show={showModal} onHide={this.closeModal}>
          <Modal.Header closeButton>
            <Modal.Title>{record.id ? 'Editar' : 'Matricular'} aluno</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="loading">
              <CSSTransition
                in={isLoading}
                timeout={500}
                classNames="fade"
                unmountOnExit
              >
                <div><Loading /></div>
              </CSSTransition>
              <div className="d-flex flex-column">
                <ButtonGroup className="mb-3" size="sm" aria-label="First group">
                  <Button
                    active={steps.aluno}
                    variant="primary"
                    onClick={() => this.toggleStep('aluno')}
                  >
                    <Badge pill variant="primary" className="border border-light">1</Badge> Aluno
                  </Button>
                  <Button
                    active={steps.endereco}
                    variant="primary"
                    onClick={() => this.toggleStep('endereco')}
                  >
                    <Badge pill variant="primary" className="border border-light">2</Badge> Endereço
                  </Button>
                  <Button
                    active={steps.responsavel}
                    variant="primary"
                    onClick={() => this.toggleStep('responsavel')}
                  >
                    <Badge pill variant="primary" className="border border-light">3</Badge> Responsável
                  </Button>
                  <Button
                    active={steps.matricula}
                    variant="primary"
                    onClick={() => this.toggleStep('matricula')}
                  >
                    <Badge pill variant="primary" className="border border-light">4</Badge> Matrícula
                  </Button>
                </ButtonGroup>
              </div>
              <Form id="form-usuario" className="form-horizontal form-sm form-custom" onSubmit={this.handleSubmit} noValidate>
                <Collapse in={steps.aluno}>
                  <Form.Row>
                    <Form.Group as={Col} sm={8}>
                      <Form.Label>Nome do aluno</Form.Label>
                      <Form.Control type="text"
                        name="nome"
                        value={record.nome}
                        onChange={this.handleInputChange}
                        onBlur={this.handleInputChange}
                        className={errors.nome.length ? 'is-invalid' : ''}
                        required
                      />
                      <Form.Control.Feedback type="invalid">{errors.nome}</Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group as={Col} sm={4}>
                      <Form.Label>Data de nascimento</Form.Label>
                      <DatePicker selected={record.nascimento}
                        dateFormat="dd/MM/yyyy"
                        onChange={date => this.handleComponentChange('nascimento', date)}
                        // minDate={this.subDays(new Date(), 5475)}
                        popperPlacement="bottom-end"
                        popperClassName="right-triangle"
                        className={'form-control' + (errors.nascimento.length ? ' is-invalid' : '')}
                        showMonthDropdown
                        showYearDropdown
                        dropdownMode="select"
                        required
                      />
                      <Form.Control.Feedback type="invalid">{errors.nascimento}</Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group as={Col} xs={6}>
                      <Form.Label>CPF do aluno</Form.Label>
                      <Form.Control type="text"
                        name="cpf"
                        value={record.cpf}
                        onChange={this.handleInputChange}
                        onBlur={this.handleInputChange}
                        className={errors.cpf.length ? 'is-invalid' : ''}
                      />
                      <Form.Control.Feedback type="invalid">{errors.cpf}</Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group as={Col} xs={6}>
                      <Form.Label>RG do aluno</Form.Label>
                      <Form.Control type="text"
                        name="rg"
                        value={record.rg}
                        onChange={this.handleInputChange}
                        onBlur={this.handleInputChange}
                        className={errors.rg.length ? 'is-invalid' : ''}
                      />
                      <Form.Control.Feedback type="invalid">{errors.rg}</Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group as={Col} lg={6}>
                      <Form.Label>Email</Form.Label>
                      <Form.Control type="email"
                        name="email"
                        value={record.email}
                        onChange={this.handleInputChange}
                        onBlur={this.handleInputChange}
                        className={errors.email.length ? 'is-invalid' : ''}
                        noValidate
                      />
                      <Form.Control.Feedback type="invalid">{errors.email}</Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group as={Col} xs={6} lg={3}>
                      <Form.Label>Fone do aluno</Form.Label>
                      <Form.Control type="text"
                        name="fone"
                        value={record.fone}
                        onChange={this.handleInputChange}
                        onBlur={this.handleInputChange}
                        noValidate
                      />
                      <Form.Control.Feedback type="invalid">{errors.fone}</Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group as={Col} xs={6} lg={3}>
                      <Form.Label>Fone de emergência</Form.Label>
                      <Form.Control type="text"
                        name="foneEmergencia"
                        value={record.foneEmergencia}
                        onChange={this.handleInputChange}
                        onBlur={this.handleInputChange}
                      />
                      <Form.Control.Feedback type="invalid">{errors.foneEmergencia}</Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group as={Col} sm={6}>
                      <Form.Label>Escola/Colégio</Form.Label>
                      <TypeaheadColegios
                        name="colegio"
                        colegio={record.colegio}
                        onChange={this.handleComponentChange}
                        required
                        error={errors.colegio}
                      />
                      <Form.Control.Feedback type="invalid">{errors.colegio}</Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group as={Col} xs={6} sm={3}>
                      <Form.Label>Série</Form.Label>
                      <SelectSeries
                        name="serie"
                        serie={record.serie}
                        onChange={this.handleComponentChange}
                        required
                        error={errors.serie}
                      />
                      <Form.Control.Feedback type="invalid">{errors.serie}</Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group as={Col} xs={6} sm={3}>
                      <Form.Label>Turno</Form.Label>
                      <SelectTurnos
                        name="turno"
                        turno={record.turno}
                        onChange={this.handleComponentChange}
                        required
                        error={errors.turno}
                      />
                      <Form.Control.Feedback type="invalid">{errors.turno}</Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group as={Col} xs={6} sm={2}>
                      <Form.Label>Gênero</Form.Label>
                      <SelectGeneros
                        name="genero"
                        genero={record.genero}
                        onChange={this.handleComponentChange}
                        required
                        error={errors.genero}
                      />
                      <Form.Control.Feedback type="invalid">{errors.genero}</Form.Control.Feedback>
                    </Form.Group>
                  </Form.Row>
                </Collapse>
                <Collapse in={steps.endereco}>
                  <Form.Row>
                    <Form.Group as={Col} xs={3}>
                      <Form.Label>Estado</Form.Label>
                      <SelectEstados
                        name="idEstado"
                        idEstado={record.idEstado}
                        onChange={this.handleComponentChange}
                        error={errors.idEstado}
                        showUF
                        required
                      />
                      <Form.Control.Feedback type="invalid">{errors.idEstado}</Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group as={Col} xs={9}>
                      <Form.Label>Municipio</Form.Label>
                      <TypeaheadMunicipios
                        name="municipio"
                        idEstado={record.idEstado}
                        municipio={record.municipio}
                        onChange={this.handleComponentChange}
                        required
                        error={errors.municipio}
                      />
                      <Form.Control.Feedback type="invalid">{errors.municipio}</Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group as={Col} xs={12}>
                      <Form.Label>Bairro</Form.Label>
                      <Form.Control type="text"
                        name="bairro"
                        value={record.bairro}
                        onChange={this.handleInputChange}
                        onBlur={this.handleInputChange}
                      />
                    </Form.Group>
                    <Form.Group as={Col} xs={12}>
                      <Form.Label>Endereço</Form.Label>
                      <Form.Control as="textarea"
                        rows="3"
                        name="endereco"
                        value={record.endereco}
                        onChange={this.handleInputChange}
                        onBlur={this.handleInputChange}
                      />
                      <Form.Control.Feedback type="invalid">{errors.endereco}</Form.Control.Feedback>
                    </Form.Group>
                  </Form.Row>
                </Collapse>
                <Collapse in={steps.responsavel}>
                  <Form.Row>
                    <Form.Group as={Col} xs={12}>
                      <Form.Label>Responsável</Form.Label>
                      <Form.Control
                        type="text"
                        name="responsavel"
                        value={record.responsavel}
                        onChange={this.handleInputChange}
                        onBlur={this.handleInputChange}
                        className={errors.responsavel.length ? 'is-invalid' : ''}
                        required
                      />
                      <Form.Control.Feedback type="invalid">{errors.responsavel}</Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group as={Col} xs={6}>
                      <Form.Label>RG do responsável</Form.Label>
                      <Form.Control
                        type="text"
                        name="respRG"
                        value={record.respRG}
                        onChange={this.handleInputChange}
                        onBlur={this.handleInputChange}
                        className={errors.respRG.length ? 'is-invalid' : ''}
                        required
                      />
                      <Form.Control.Feedback type="invalid">{errors.respRG}</Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group as={Col} xs={6}>
                      <Form.Label>Fone do responsável</Form.Label>
                      <Form.Control
                        type="text"
                        name="respFone"
                        value={record.respFone}
                        onChange={this.handleInputChange}
                        onBlur={this.handleInputChange}
                        className={errors.respFone.length ? 'is-invalid' : ''}
                        required
                      />
                      <Form.Control.Feedback type="invalid">{errors.respFone}</Form.Control.Feedback>
                    </Form.Group>
                  </Form.Row>
                </Collapse>
                <Collapse in={steps.matricula}>
                  <Form.Row>
                    <Form.Group as={Col} sm={5}>
                      <Form.Label>Unidade</Form.Label>
                      <SelectUnidades
                        name="idUnidade"
                        unidades={this.props.usuario.unidades}
                        idUnidade={record.idUnidade}
                        onChange={this.handleComponentChange}
                        required
                        error={errors.idUnidade}
                      />
                      <Form.Control.Feedback type="invalid">{errors.idUnidade}</Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group as={Col} xs={6} sm={4}>
                      <Form.Label>Curso</Form.Label>
                      <SelectCursos
                        name="idCurso"
                        cursos={this.props.usuario.cursos}
                        idCurso={record.idCurso}
                        onChange={this.handleComponentChange}
                        required
                        error={errors.idCurso}
                      />
                      <Form.Control.Feedback type="invalid">{errors.idCurso}</Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group as={Col} xs={6} sm={3}>
                      <Form.Label>Data inscrição</Form.Label>
                      <DatePicker
                        selected={record.inscricao}
                        dateFormat="dd/MM/yyyy"
                        onChange={date => this.handleComponentChange('inscricao', date)}
                        popperPlacement="bottom-end"
                        popperClassName="right-triangle"
                        className={'form-control' + (errors.inscricao.length ? ' is-invalid' : '')}
                        showMonthDropdown
                        showYearDropdown
                        dropdownMode="select"
                      />
                      <Form.Control.Feedback type="invalid">{errors.inscricao}</Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group as={Col} xs={6} sm={3}>
                      <Form.Label>Data matricula</Form.Label>
                      <DatePicker
                        selected={record.matricula}
                        dateFormat="dd/MM/yyyy"
                        onChange={date => this.handleComponentChange('matricula', date)}
                        popperPlacement="bottom"
                        popperClassName="center-triangle"
                        className={'form-control' + (errors.matricula.length ? ' is-invalid' : '')}
                        showMonthDropdown
                        showYearDropdown
                        dropdownMode="select"
                        required
                      />
                      <Form.Control.Feedback type="invalid">{errors.matricula}</Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group as={Col} xs={6} sm={3}>
                      <Form.Label>Nível</Form.Label>
                      <SelectNiveis
                        name="nivel"
                        nivel={record.nivel}
                        matricula={record.matricula}
                        onChange={this.handleComponentChange}
                        error={errors.nivel}
                        required
                      />
                      <Form.Control.Feedback type="invalid">{errors.nivel}</Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group as={Col} xs={6} sm={3}>
                      <Form.Label>Data formatura</Form.Label>
                      <DatePicker
                        selected={record.formatura}
                        dateFormat="dd/MM/yyyy"
                        onChange={date => this.handleComponentChange('formatura', date)}
                        popperPlacement="bottom"
                        popperClassName="center-triangle"
                        className={'form-control'}
                        showMonthDropdown
                        showYearDropdown
                        dropdownMode="select"
                      />
                    </Form.Group>
                    <Form.Group as={Col} xs={6} sm={3}>
                      <Form.Label>Data desistencia</Form.Label>
                      <DatePicker
                        selected={record.desistencia}
                        dateFormat="dd/MM/yyyy"
                        onChange={date => this.handleComponentChange('desistencia', date)}
                        popperPlacement="bottom-end"
                        popperClassName="right-triangle"
                        className={'form-control'}
                        showMonthDropdown
                        showYearDropdown
                        dropdownMode="select"
                      />
                    </Form.Group>
                  </Form.Row>
                </Collapse>
              </Form>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="outline-secondary" onClick={this.closeModal}>Cancelar</Button>
            {steps.aluno && <Button variant="success" onClick={() => this.toggleStep('endereco')}>Próximo</Button>}
            {steps.endereco && <Button variant="outline-secondary" onClick={() => this.toggleStep('aluno')}>Anterior</Button>}
            {steps.endereco && <Button variant="success" onClick={() => this.toggleStep('responsavel')}>Próximo</Button>}
            {steps.responsavel && <Button variant="outline-secondary" onClick={() => this.toggleStep('endereco')}>Anterior</Button>}
            {steps.responsavel && <Button variant="success" onClick={() => this.toggleStep('matricula')}>Próximo</Button>}
            {steps.matricula && <Button variant="outline-secondary" onClick={() => this.toggleStep('responsavel')}>Anterior</Button>}
            {steps.matricula && <Button variant="success" form="form-usuario" type="submit">Salvar</Button>}
          </Modal.Footer>
        </Modal>
      </React.Fragment>
    );
  }
}

export default FormAluno;