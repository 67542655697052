import React, { Component } from "react";
import { Container, Row, Col, Card, Table } from "react-bootstrap"
import { CSSTransition } from 'react-transition-group';
import { toast } from 'react-toastify';
import {
  Loading,
  ButtonAdd,
  ButtonEdit,
  ButtonDelete,
  StatusBadge
} from "../../../components";
import FormUnidade from "./FormUnidade";
import api from "../../../services/api";

const emptyRecord = { id: '', unidade: '', municipio: '', idEstado: 1, ativo: 'S', professores: [] };

class Unidades extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      showModal: false,
      records: [],
      totalRecords: 0,
      record: emptyRecord
    };
  }

  loadFromAPI = () => {
    this.setState({ isLoading: true }, () => {
      api.get('/unidades')
        .then(response => {
          this.setState({
            records: response.data.unidades ? response.data.unidades : [],
            totalRecords: response.data.unidades ? response.data.unidades.length : 0,
            isLoading: false
          });
        })
        .catch(error => {
          this.setState({ isLoading: false });
          toast.error('Falha ao obter dados!');
        });
    });
  };

  doDelete = (id) => {
    api.delete('/unidades', { data: { id: id } })
      .then((response) => {
        if (response.data.message === 'ok') {
          toast.success('Unidade excluida com sucesso!');
          this.loadFromAPI();
        } else {
          toast.error(response.data.message);
        }
      })
      .catch((error) => {
        toast.error('Ocorreu uma falha ao excluir!');
      });
  };

  openModalForm = (record) => {
    this.setState({
      showModal: true,
      record: record
    });
  };

  closeModalForm = () => {
    this.setState({
      showModal: false,
      record: emptyRecord
    });
  };

  componentDidMount = () => {
    this.loadFromAPI();
  };

  render() {
    const { isLoading, showModal, totalRecords, records, record } = this.state;

    const somaTotal = (records) => {
      let inscricoes = 0,
        matriculas = 0,
        formados = 0,
        desistencias = 0,
        professores = 0,
        atividades = 0;

      records.forEach(item => {
        inscricoes += item.inscritos;
        matriculas += item.matriculados;
        formados += item.formados;
        desistencias += item.desistencias;
        professores += item.professores.length;
        atividades += item.atividades;
      });

      return (
        <tr>
          <th></th>
          <th></th>
          <th></th>
          <th className="text-center">{inscricoes}</th>
          <th className="text-center">{matriculas}</th>
          <th className="text-center">{formados}</th>
          <th className="text-center">{desistencias}</th>
          <th className="text-center">{professores}</th>
          <th className="text-center">{atividades}</th>
          <th></th>
          <th></th>
        </tr>
      );
    };
    return (
      <Container fluid>
        <Row className="mb-4">
          <Col><h1 className="page-title">Unidades</h1></Col>
          <Col sm="auto" className="pt-3 pt-sm-2">
            <ButtonAdd label="Adicionar unidade" onClick={() => this.openModalForm(emptyRecord)} />
          </Col>
        </Row>

        <Card className="card-list">
          <Card.Body className="p-0">
            <div className="loading">
              <div className="nowrap mx-3 py-2">Total: <b>{totalRecords}</b> registro(s).</div>
              <Table striped responsive className="table-data">
                <thead className="thead-light">
                  <tr>
                    <th>#</th>
                    <th>Unidade</th>
                    <th>Municipio</th>
                    <th className="text-center">Inscrições</th>
                    <th className="text-center">Matriculas</th>
                    <th className="text-center">Formados</th>
                    <th className="text-center">Desistencias</th>
                    <th className="text-center">Professores</th>
                    <th className="text-center">Atividades</th>
                    <th className="text-center">Status</th>
                    <th style={{ width: "76px" }}></th>
                  </tr>
                </thead>
                <tbody>
                  {!records.length ? (
                    <tr><td colSpan={11} className="empty">Nenhum registro </td></tr>
                  ) : (
                      records.map((unidade, key) => {
                        return (
                          <tr key={key}>
                            <td><span className="text-muted">{key + 1}</span></td>
                            <td><b>{unidade.unidade}</b></td>
                            <td>{unidade.municipio} / {unidade.uf}</td>
                            <td className="text-center">{unidade.inscritos}</td>
                            <td className="text-center">{unidade.matriculados}</td>
                            <td className="text-center">{unidade.formados}</td>
                            <td className="text-center">{unidade.desistencias}</td>
                            <td className="text-center">{unidade.professores.length}</td>
                            <td className="text-center">{unidade.atividades}</td>
                            <td className="text-center"><StatusBadge ativo={unidade.ativo} /></td>
                            <td className="text-right">
                              <ButtonEdit onClick={() => this.openModalForm(unidade)} />
                              <ButtonDelete id={unidade.id} label={unidade.unidade} doDelete={this.doDelete} />
                            </td>
                          </tr>
                        )
                      })
                    )}
                </tbody>
                <tfoot>
                  {somaTotal(records)}
                </tfoot>
              </Table>
              <CSSTransition
                in={isLoading}
                timeout={500}
                classNames="fade"
                unmountOnExit
              >
                <div><Loading /></div>
              </CSSTransition>
            </div>
          </Card.Body>
        </Card>
        <br />
        <FormUnidade
          emptyRecord={emptyRecord}
          record={record}
          showModal={showModal}
          loadFromAPI={this.loadFromAPI}
          closeModalForm={this.closeModalForm}
        />
      </Container>
    );
  }
}

export default Unidades;