import React, { Component, Fragment } from "react";
import { Card, Image, Alert, Form, Col, Button } from "react-bootstrap";
import { CSSTransition } from 'react-transition-group';
import SweetAlert from "react-bootstrap-sweetalert";
import DatePicker from "react-datepicker";
import { isValidCPF, formatCPF } from '@brazilian-utils/brazilian-utils';
import {
  Loading,
  SelectUnidades,
  SelectGeneros,
  SelectSeries,
  SelectTurnos
} from "../components";

import api from "../services/api";
import { validEmailRegex } from "../utils/validation";
import { dateToServer, subDays } from "../utils/dates";
import { formatFone } from "../utils/fone";
import logo from '../assets/img/logo-gaiteiros.png';

const emptyRecord = {
  idUnidade: '',
  nome: '',
  genero: '',
  nascimento: '',
  cpf: '',
  rg: '',
  email: '',
  fone: '',
  colegio: '',
  serie: '',
  turno: ''
};
const resetErrors = {
  idUnidade: '',
  nome: '',
  genero: '',
  nascimento: '',
  cpf: '',
  rg: '',
  email: '',
  fone: '',
  colegio: '',
  serie: '',
  turno: ''
};

class Inscricao extends Component {
  constructor(props, context) {
    super(props, context);
    this.state = {
      isLoading: false,
      alert: null,
      record: emptyRecord,
      errors: resetErrors,
      response: null,
      formValid: false
    };
  }

  validateInput = (name, value, errors) => {
    switch (name) {
      case 'idUnidade':
        errors.idUnidade = (!value || value.length === 0) ? 'Selecione uma UNIDADE' : '';
        break;
      case 'nome':
        errors.nome = (!value || value.length === 0) ? 'Preencha o campo NOME' : '';
        break;
      case 'genero':
        errors.genero = (!value) ? 'Selecione um GÊNERO' : '';
        break;
      case 'nascimento':
        errors.nascimento = (!value || value.length === 0) ? 'Preencha o campo NASCIMENTO' : '';
        break;
      case 'cpf':
        if (!value || value.length === 0) {
          errors.cpf = 'Preencha o campo CPF';
        } else if (!isValidCPF(value)) {
          errors.cpf = 'CPF inválido';
        } else {
          errors.cpf = '';
        }
        break;
      case 'rg':
        errors.rg = (!value || value.length === 0) ? 'Preencha o campo RG' : '';
        break;
      case 'email':
        if (!value || value.length === 0) {
          errors.email = 'Preencha o campo EMAIL';
        } else if (!validEmailRegex.test(value)) {
          errors.email = 'EMAIL inválido';
        } else {
          errors.email = '';
        }
        break;
      case 'fone':
        errors.fone = (!value || value.length === 0) ? 'Preencha o campo FONE' : '';
        break;
      case 'colegio':
        errors.colegio = (!value || value.length === 0) ? 'Preencha o campo ESCOLA/COLÉGIO' : '';
        break;
      case 'serie':
        errors.serie = (!value || value.length === 0) ? 'Selecione uma SÉRIE' : '';
        break;
      case 'turno':
        errors.turno = (!value || value.length === 0) ? 'Selecione um TURNO' : '';
        break;
      default:
        break;
    }

    return errors;
  };

  validateForm = (record, errors) => {
    for (const key in record) {
      errors = this.validateInput(key, record[key], errors);
      if (errors[key]) return false;
    }
    return true;
  };

  handleInputChange = (event) => {
    event.preventDefault();
    const { name, value } = event.target;
    this.handleComponentChange(name, value);
  };

  handleComponentChange = (name, value) => {
    let record = Object.assign({}, this.state.record),
      errors = Object.assign({}, this.state.errors);

    if (name === 'cpf') value = formatCPF(value);
    if (name === 'fone') value = formatFone(value);

    record[name] = value;
    errors = this.validateInput(name, value, errors);
    this.setState({
      record: record,
      errors,
      formValid: this.validateForm(record, errors)
    });
  };

  setAlert = (type, message, action) => {
    return (<SweetAlert type={type} title="Oops!" onConfirm={() => this.hideAlert()}>{message}{(action && <br />)}{action}</SweetAlert>)
  };

  showAlert = (type, message) => {
    this.setState({ alert: this.setAlert(type, message) });
  };

  hideAlert = () => {
    this.setState({ alert: null });
  };

  handleSubmit = async (event) => {
    event.preventDefault();
    if (!this.state.formValid) {
      this.showAlert('warning', 'Preenchimento incorreto!');
    } else {
      this.setState({ isLoading: true }, () => {
        const record = Object.assign({}, this.state.record);
        record.nascimento = dateToServer(record.nascimento);
        api.post('/inscricao', record)
          .then((response) => {
            if (response.data.success) {
              this.setState({
                isLoading: false,
                response: response.data,
              });
            } else {
              this.setState({
                isLoading: false,
                alert: this.setAlert('warning', response.data.message)
              });
            }
          })
          .catch((error) => {
            this.setState({
              isLoading: false,
              alert: this.setAlert('danger', 'Falha ao enviar os dados!')
            });
          });
      });
    }
  };

  render() {
    const { isLoading, alert, record, errors, response, formValid } = this.state;

    return (
      <div className="page-wrapper">
        {alert}
        <Card className="shadow">
          <Card.Body>
            <div className="text-center">
              <Image src={logo} fluid />
            </div>
            <br />
            <h4 className="mb-3 text-center" style={{ color: "#fd7922" }}><b>FICHA DE INSCRIÇÃO</b></h4>
            <div className="loading">
              {!response ? (
                <Fragment>
                  <p className="mb-4 text-center"><small>Ficamos felizes com teu desejo em participar do Projeto Fábrica de Gaiteiros.<br />
                    Se tens entre 7 e 15 anos de idade escolha abaixo a Unidade de sua cidade<br />
                    e preencha TODOS os dados na FICHA DE INSCRIÇÃO.<br />
                    Quando for disponibilizada a vaga enviaremos um email e terás 2 (dois) dias úteis para comparecer<br /> na Unidade escolhida e efetivar sua matrícula diretamente com o professor(a).<br />
                    Findo o prazo a inscrição será cancelada, podendo ser refeita e aguardar nova chamada.<br />
                    As INCRIÇÕES valem até 30 de novembro do corrente ano.</small></p>
                  <Form id="form-inscricao" className="form-horizontal" onSubmit={this.handleSubmit} noValidate>
                    <Form.Row>
                      <Form.Group as={Col} xs={12}>
                        <Form.Label>Unidade</Form.Label>
                        <SelectUnidades
                          name="idUnidade"
                          onChange={this.handleComponentChange}
                          error={errors.idUnidade}
                          required
                        />
                        <Form.Control.Feedback type="invalid">{errors.idUnidade}</Form.Control.Feedback>
                      </Form.Group>
                      <Form.Group as={Col} sm={7}>
                        <Form.Label>Nome</Form.Label>
                        <Form.Control type="text"
                          name="nome"
                          value={record.nome}
                          onChange={this.handleInputChange}
                          onBlur={this.handleInputChange}
                          className={errors.nome.length ? 'is-invalid' : ''}
                          required
                        />
                        <Form.Control.Feedback type="invalid">{errors.nome}</Form.Control.Feedback>
                      </Form.Group>
                      <Form.Group as={Col} xs={6} sm={2}>
                        <Form.Label>Gênero</Form.Label>
                        <SelectGeneros
                          name="genero"
                          genero={record.genero}
                          onChange={this.handleComponentChange}
                          required
                          error={errors.genero}
                        />
                        <Form.Control.Feedback type="invalid">{errors.genero}</Form.Control.Feedback>
                      </Form.Group>
                      <Form.Group as={Col} xs={6} sm={3}>
                        <Form.Label>Data de nascimento</Form.Label>
                        <DatePicker selected={record.nascimento}
                          dateFormat="dd/MM/yyyy"
                          onChange={date => this.handleComponentChange('nascimento', date)}
                          minDate={subDays(new Date(), 5475)}
                          popperPlacement="bottom-end"
                          popperClassName="right-triangle"
                          className={'form-control' + (errors.nascimento.length ? ' is-invalid' : '')}
                          showMonthDropdown
                          showYearDropdown
                          dropdownMode="select"
                          required
                        />
                        <Form.Control.Feedback type="invalid">{errors.nascimento}</Form.Control.Feedback>
                      </Form.Group>
                      <Form.Group as={Col} sm={6}>
                        <Form.Label>CPF</Form.Label>
                        <Form.Control type="text"
                          name="cpf"
                          value={record.cpf}
                          onChange={this.handleInputChange}
                          onBlur={this.handleInputChange}
                          className={errors.cpf.length ? 'is-invalid' : ''}
                          required
                        />
                        <Form.Control.Feedback type="invalid">{errors.cpf}</Form.Control.Feedback>
                      </Form.Group>
                      <Form.Group as={Col} sm={6}>
                        <Form.Label>RG</Form.Label>
                        <Form.Control type="text"
                          name="rg"
                          value={record.rg}
                          onChange={this.handleInputChange}
                          onBlur={this.handleInputChange}
                          className={errors.rg.length ? 'is-invalid' : ''}
                          required
                        />
                        <Form.Control.Feedback type="invalid">{errors.rg}</Form.Control.Feedback>
                      </Form.Group>
                      <Form.Group as={Col} sm={7}>
                        <Form.Label>Email</Form.Label>
                        <Form.Control type="email"
                          name="email"
                          value={record.email}
                          onChange={this.handleInputChange}
                          onBlur={this.handleInputChange}
                          className={errors.email.length ? 'is-invalid' : ''}
                          required
                        />
                        <Form.Control.Feedback type="invalid">{errors.email}</Form.Control.Feedback>
                      </Form.Group>
                      <Form.Group as={Col} sm={5}>
                        <Form.Label>Fone</Form.Label>
                        <Form.Control type="text"
                          name="fone"
                          value={record.fone}
                          onChange={this.handleInputChange}
                          onBlur={this.handleInputChange}
                          className={errors.fone.length ? 'is-invalid' : ''}
                          required
                        />
                        <Form.Control.Feedback type="invalid">{errors.fone}</Form.Control.Feedback>
                      </Form.Group>
                      <Form.Group as={Col} sm={6}>
                        <Form.Label>Escola/Colégio</Form.Label>
                        <Form.Control type="text"
                          name="colegio"
                          value={record.colegio}
                          onChange={this.handleInputChange}
                          onBlur={this.handleInputChange}
                          className={errors.colegio.length ? 'is-invalid' : ''}
                          required
                        />
                        <Form.Control.Feedback type="invalid">{errors.colegio}</Form.Control.Feedback>
                      </Form.Group>
                      <Form.Group as={Col} xs={6} sm={3}>
                        <Form.Label>Série</Form.Label>
                        <SelectSeries
                          name="serie"
                          serie={record.serie}
                          onChange={this.handleComponentChange}
                          required
                          error={errors.serie}
                        />
                        <Form.Control.Feedback type="invalid">{errors.serie}</Form.Control.Feedback>
                      </Form.Group>
                      <Form.Group as={Col} xs={6} sm={3}>
                        <Form.Label>Turno</Form.Label>
                        <SelectTurnos
                          name="turno"
                          turno={record.turno}
                          onChange={this.handleComponentChange}
                          required
                          error={errors.turno}
                        />
                        <Form.Control.Feedback type="invalid">{errors.turno}</Form.Control.Feedback>
                      </Form.Group>
                      <Form.Group as={Col} sm={5}>
                        {formValid ? (
                          <Button
                            variant="warning"
                            size="lg"
                            block
                            className="mt-3 btn-fabrica"
                            type="submit"
                          >
                            ENVIAR
                          </Button>
                        ) : (
                            <Button
                              variant="outline-warning"
                              size="lg"
                              block
                              className="mt-3 btn-fabrica-disabled"
                              disabled
                            >
                              ENVIAR
                            </Button>
                          )}
                      </Form.Group>
                    </Form.Row>
                  </Form>
                </Fragment>
              ) : (
                  <Alert variant={response.variant} className="mt-3 text-center">
                    <Alert.Heading >ATENÇÃO!</Alert.Heading>
                    <p className="mt-3">{response.message}</p>
                    {response.action}
                  </Alert>
                )}
              <CSSTransition
                in={isLoading}
                timeout={500}
                classNames="fade"
                unmountOnExit
              >
                <div><Loading /></div>
              </CSSTransition>
            </div>
          </Card.Body>
        </Card>
      </div>
    );
  }
}

export default Inscricao;
