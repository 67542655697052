import React, { Component } from "react";

class SelectAtivo extends Component {
  constructor(props) {
    super(props);
    this.state = {
      value: props.ativo ? props.ativo : ''
    };
    this.onChange = props.onChange;
  }

  handleChange = (event) => {
    event.preventDefault();
    const value = event.target.value;
    if (this.state.value !== value) {
      this.setState({ value: value }, this.onChange(this.props.name, value));
    }
  };

  render() {
    const { value } = this.state;

    return (
      <select
        value={value}
        onChange={this.handleChange}
        required={this.props.required}
        className="custom-select"
      >
        <option value="S">Ativo</option>
        <option value="N">Inativo</option>
      </select>
    )
  }
}

export default SelectAtivo;
