import React from "react";
import { Badge } from "react-bootstrap";

function StatusBadge(props) {
  const label = props.ativo === 'S' ? 'Ativo' : 'Inativo';
  return <Badge
    pill
    variant={props.ativo === 'S' ? 'success' : 'danger'}
    className="badge-md"
    title={label}
  >
    {label}
  </Badge>;
}

export default StatusBadge;