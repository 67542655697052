import React, { Component } from "react";
import { Card, Col, Form, InputGroup, Button } from "react-bootstrap";

import { TypeaheadMunicipios, SelectTipoColegio } from '../../../components';

class Filter extends Component {
  constructor(props) {
    super(props);
    this.state = {
      colegio: '',
      tipo: '',
      municipio: ''
    };
    this.UpState = props.setQuery;
  }

  handleInputChange = (event) => {
    event.preventDefault();
    const { name, value } = event.target;
    this.handleComponentChange(name, value);
  };

  handleComponentChange = (name, value) => {
    this.setState({ [name]: value });
  };

  handleSubmit = (event) => {
    event.preventDefault();
    this.UpState(this.state);
  };

  render() {
    const { colegio, tipo, municipio } = this.state;

    return (
      <Card className="card-filter">
        <Card.Body>
          <Card.Title>Filtros</Card.Title>
          <Form id="form-filter" onSubmit={this.handleSubmit} noValidate>
            <Form.Row>
              <Col sm={8} md={4}>
                <InputGroup size="sm">
                  <InputGroup.Prepend>
                    <InputGroup.Text>Colégio</InputGroup.Text>
                  </InputGroup.Prepend>
                  <Form.Control type="text"
                    name="colegio"
                    value={colegio}
                    onChange={this.handleInputChange}
                  />
                </InputGroup>
              </Col>
              <Col sm={4} md={2}>
                <InputGroup size="sm">
                  <InputGroup.Prepend>
                    <InputGroup.Text>Tipo</InputGroup.Text>
                  </InputGroup.Prepend>
                  <SelectTipoColegio
                    name="tipo"
                    tipo={tipo}
                    onChange={this.handleComponentChange}
                  />
                </InputGroup>
              </Col>
              <Col sm={8} md={4}>
                <InputGroup size="sm">
                  <InputGroup.Prepend>
                    <InputGroup.Text>Município</InputGroup.Text>
                  </InputGroup.Prepend>
                  <TypeaheadMunicipios
                    name="municipio"
                    municipio={municipio}
                    onChange={this.handleComponentChange}
                    size="sm"
                  />
                </InputGroup>
              </Col>
              <Col sm={4} md={2}>
                <Button variant="primary" size="sm" form="form-filter" type="submit" block>Filtrar</Button>
              </Col>
            </Form.Row>
          </Form>
        </Card.Body>
      </Card>
    );
  }
}

export default Filter;