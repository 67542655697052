import React, { Component } from "react";
import { Container, Form, Row, Col, Table, Button } from "react-bootstrap";
import { CSSTransition } from 'react-transition-group';
import { toast } from 'react-toastify';
import Pagination from "react-js-pagination";
import {
  Loading,
  ButtonAdd,
  ButtonEdit,
  ButtonDelete,
  PaginationPerPage
} from '../../../components';
import Filter from './Filter';
import FormAtividade from './FormAtividade';
import FormFoto from './FormFoto';
import { dateToServer, dateToLocal } from '../../../utils/dates';
import { filterQuery } from '../../../utils/misc';
import api from '../../../services/api';

const emptyRecord = {
  id: '',
  data: '',
  atividade: '',
  instituicao: '',
  local: '',
  descricao: '',
  unidades: [],
  professores: [],
  alunos: [],
  outros: []
};

const s3Location = 'https://fabricadegaiteiros.s3.sa-east-1.amazonaws.com';

class Atividades extends Component {
  usuario = JSON.parse(localStorage.getItem('usuario'));

  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      showModalAtividade: false,
      showModalFoto: false,
      alert: null,
      records: [],
      totalRecords: 0,
      record: emptyRecord,
      query: {
        idUnidade: (this.usuario.unidades && this.usuario.unidades.length === 1) ? this.usuario.unidades[0].id : '',
        idProfessor: '',
        aluno: '',
        dataIni: '',
        dataFim: '',
        order: 'inicio',
        direction: 'ASC',
        limit: 50,
        offset: 0
      },
      activePage: 1
    };
  }

  loadFromAPI = () => {
    this.setState({ isLoading: true }, () => {
      let query = Object.assign({}, this.state.query);
      query.dataIni = query.dataIni ? dateToServer(query.dataIni) : '';
      query.dataFim = query.dataFim ? dateToServer(query.dataFim) : '';
      api.get('/atividades', { params: filterQuery(query) })
        .then(response => {
          this.setState({
            records: response.data.atividades ? response.data.atividades : [],
            totalRecords: response.data.total ? response.data.total : 0,
            isLoading: false
          });
        })
        .catch(error => {
          this.setState({ isLoading: false });
          toast.error('Falha ao obter dados!');
        });
    });
  };

  doDelete = (id) => {
    api.delete('/atividades', { data: { id: id } })
      .then((response) => {
        if (response.data.message === 'ok') {
          toast.success('Atividade excluida com sucesso!');
          this.loadFromAPI();
        } else {
          toast.error(response.data.message);
        }
      })
      .catch((error) => {
        toast.error('Ocorreu uma falha ao excluir!');
      });
  };

  handlePageChange = (pageNumber) => {
    let query = Object.assign({}, this.state.query);
    query.offset = (pageNumber * query.limit) - query.limit;
    this.setState({
      query: query,
      activePage: pageNumber
    }, () => this.loadFromAPI());
  };

  setQuery = (filter) => {
    let query = Object.assign({}, this.state.query);
    query.idUnidade = filter.idUnidade;
    query.idCurso = filter.idCurso;
    query.nome = filter.nome;
    query.status = filter.status;
    query.idade = filter.idade;
    query.idColegio = filter.idColegio;
    query.serie = filter.serie;
    query.turno = filter.turno;
    query.tipoRange = filter.tipoRange;
    query.rangeStart = filter.rangeStart;
    query.rangeEnd = filter.rangeEnd;
    this.setState({ query: query }, () => this.loadFromAPI());
  };

  setLimit = (limit) => {
    let query = Object.assign({}, this.state.query);
    query.limit = limit;
    query.offset = 0;
    this.setState({
      query: query,
      activePage: 1
    }, () => this.loadFromAPI());
  };

  setOrder = (order) => {
    let query = Object.assign({}, this.state.query);
    query.order = order;
    query.direction = (query.order === order && query.direction === 'ASC') ? 'DESC' : 'ASC';

    if (this.state.totalRecords <= query.limit) {
      // reordena em memoria
      let records = this.state.records;
      if (order.indexOf('.') > 0) {
        order = order.split('.');
        order = order[1];
      }

      if (records.length && query.direction === 'ASC') {
        records.sort((a, b) => (a[order] > b[order]) ? 1 : -1);
      } else {
        records.sort((a, b) => (a[order] < b[order]) ? 1 : -1);
      }
      this.setState({
        records: records,
        query: query
      });
    } else {
      // busca na api
      this.setState({ query: query });
      this.loadFromAPI();
    }
  };

  openFormAtividade = (record) => {
    this.setState({
      showModalAtividade: true,
      record: record
    });
  };

  closeFormAtividade = () => {
    this.setState({
      showModalAtividade: false,
      record: emptyRecord
    });
  };

  openFormFoto = (record) => {
    this.setState({
      showModalFoto: true,
      record: record
    });
  };

  updateRecords = (id, imagem, legenda) => {
    let records = this.state.records.map(x => x);
    for (let i = 0; i < records.length; i++) {
      if (records[i].id === id) {
        records[i].imagem = imagem;
        records[i].legenda = legenda;
      }
    }
    return records;
  };

  closeFormFoto = (id, imagem, legenda) => {
    if (id) {
      this.setState({
        showModalFoto: false,
        record: emptyRecord,
        records: this.updateRecords(id, imagem, legenda)
      });
    }
    this.setState({
      showModalFoto: false,
      record: emptyRecord
    });
  };

  hideAlert = () => {
    this.setState({ alert: null });
  };

  componentDidMount = () => {
    this.loadFromAPI();
  };

  render() {
    const { isLoading, showModalAtividade, showModalFoto, alert, records, record, query, activePage, totalRecords } = this.state;

    return (
      <Container fluid>
        <Row className="mb-3">
          <Col><h1 className="page-title">Atividades</h1></Col>
          <Col sm="auto" className="pt-3 pt-sm-2">
            <ButtonAdd label="Nova atividade" onClick={() => this.openFormAtividade(emptyRecord)} />
          </Col>
        </Row>
        <Filter query={query} usuario={this.usuario} setQuery={this.setQuery} />
        <div className="loading mt-3">
          <CSSTransition
            in={isLoading}
            timeout={500}
            classNames="fade"
            unmountOnExit
          >
            <div><Loading /></div>
          </CSSTransition>
          <Row className="mb-3 pl-3 pr-2">
            <Col className="text-center text-sm-left">
              <span className="nowrap">Total: <b>{totalRecords}</b> registro(s).</span>&nbsp;
                  {totalRecords > query.limit && (<PaginationPerPage limit={query.limit} setLimit={this.setLimit} />)}
            </Col>
            <Col>
              {totalRecords > query.limit && (
                <Pagination
                  activePage={activePage}
                  itemsCountPerPage={query.limit}
                  totalItemsCount={totalRecords}
                  pageRangeDisplayed={5}
                  onChange={this.handlePageChange}
                  innerClass={'pagination pagination-sm float-sm-right my-0'}
                  itemClass={'page-item'}
                  linkClass={'page-link'}
                  activeLinkClass={'page-link'}
                />
              )}
            </Col>
          </Row>
          <Table striped>
            <tbody>
              {!records.length ? (
                <tr><td className="empty">Nenhum registro</td></tr>
              ) : (
                  records.map((item, key) => {
                    return (
                      <tr key={key}>
                        <td>
                          <Form.Row key={key} className="atividades-item-lista">
                            <Form.Group as={Col} sm={4} md={3} lg={2} className="mb-sm-0">
                              {item.imagem ? (
                                <div className="foto-lista foto-box">
                                  <div style={{ background: `url(${s3Location}/${item.imagem})` }}></div>
                                  <Button
                                    variant="outline-light"
                                    title="Editar imagem"
                                    onClick={() => this.openFormFoto(item)}
                                  >
                                    <i className="fa fa-pencil"></i>
                                  </Button>
                                </div>
                              ) : (
                                  <div className="frame-container foto-box foto-small">
                                    <div
                                      className="foto-content"
                                      style={{ cursor: 'pointer', backgroundColor: '#ddd' }}
                                      onClick={() => this.openFormFoto(item)}
                                    >
                                      Adicionar imagem
                                    </div>
                                  </div>
                                )}
                            </Form.Group>
                            <Form.Group as={Col} sm={8} md={9} lg={10} className="mb-0">
                              <h4 className="mb-1">
                                <span className="float-right">
                                  <ButtonEdit
                                    title="Editar atividade"
                                    onClick={() => this.openFormAtividade(item)}
                                  />
                                  <ButtonDelete
                                    id={item.id}
                                    title="Excluir atividade"
                                    label={item.atividade}
                                    doDelete={this.doDelete}
                                  />
                                </span>
                                <small>{dateToLocal(item.data)}</small>
                                <br />
                                {item.atividade}
                              </h4>
                              <Form.Row>
                                <Form.Group as={Col} lg={6}>
                                  <div><strong>Local:</strong> {item.local}</div>
                                  <div><strong>Instituição:</strong> {item.instituicao}</div>
                                </Form.Group>
                                <Form.Group as={Col} lg={6}>
                                  <strong>Descrição:</strong> {item.descricao}
                                </Form.Group>
                              </Form.Row>

                            </Form.Group>
                          </Form.Row>
                        </td>
                      </tr>
                    )
                  })
                )}
            </tbody>
          </Table>
        </div>
        <br />
        <FormAtividade
          emptyRecord={emptyRecord}
          record={record}
          showModal={showModalAtividade}
          loadFromAPI={this.loadFromAPI}
          closeForm={this.closeFormAtividade}
          usuario={this.usuario}
        />
        <FormFoto
          emptyRecord={emptyRecord}
          record={record}
          showModal={showModalFoto}
          updateRecords={this.updateRecords}
          closeModal={this.closeFormFoto}
          s3Location={s3Location}
        />
        {alert}
      </Container >
    );
  }
}

export default Atividades;