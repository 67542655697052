import React, { Component, Fragment } from "react";
import { Container, Row, Col, Table, InputGroup, Alert } from "react-bootstrap";
import { CSSTransition } from 'react-transition-group';
import { toast } from 'react-toastify';

import { Loading, SelectProfessores } from "../../../../components";
import FormQuadro from "./FormQuadro";
import Semana from "./Semana";

import { weekNames } from "../../../../utils/dates";

import api from "../../../../services/api";

const emptyRecord = {
  id: '',
  idUnidade: '',
  idCurso: '',
  idProfessor: '',
  dia: '',
  inicio: '',
  fim: ''
};

class Quadro extends Component {
  usuario = JSON.parse(localStorage.getItem('usuario'));

  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      showModal: false,
      idProfessor: (this.usuario && this.usuario.idProfessor) ? this.usuario.idProfessor : '',
      records: [],
      totalRecords: 0,
      record: emptyRecord
    };
  }

  loadFromAPI = () => {
    if (this.state.idProfessor) {
      this.setState({ isLoading: true }, () => {
        api.get('/aulas/quadro', { params: { idProfessor: this.state.idProfessor } })
          .then(response => {
            this.setState({
              records: response.data.quadro ? response.data.quadro : [],
              isLoading: false
            });
          })
          .catch(error => {
            this.setState({ isLoading: false });
            toast.error('Falha ao obter dados!');
          });
      });
    }
  };

  doDelete = (id) => {
    api.delete('/aulas/quadro', { data: { id: id } })
      .then((response) => {
        if (response.data.message === 'ok') {
          toast.success('Aula excluida com sucesso!');
          const records = this.state.records.filter(r => { return parseInt(r.id) !== parseInt(id) });
          this.setState({
            showModal: false,
            records: records,
            record: emptyRecord,
          });
        } else {
          toast.error(response.data.message);
        }
      })
      .catch((error) => {
        toast.error('Ocorreu uma falha ao excluir!');
      })
  };

  openModalForm = (record) => {
    this.setState({
      record: record,
      showModal: true
    })
  };

  closeModalForm = () => {
    this.setState({
      showModal: false,
      record: emptyRecord,
    });
  };

  handleProfessorChange = (name, value) => {
    if (this.state.idProfessor !== value) {
      this.setState({ idProfessor: value }, () => this.loadFromAPI());
    }
  };

  componentDidMount = () => {
    this.loadFromAPI();
  };

  render() {
    const { isLoading, showModal, idProfessor, records, record } = this.state,
      hours = [8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22];

    return (
      <Container fluid>
        <Row noGutters className="mb-3">
          {(this.usuario && this.usuario.idProfessor) ? (
            <Col sm={12}><h1 className="page-title">Quadro Semanal</h1></Col>
          ) : (
              <Fragment>
                <Col sm={6} md={7} lg={8}><h1 className="page-title">Quadro Semanal</h1></Col>
                <Col sm={6} md={5} lg={4} className="pt-3 pl-sm-3">
                  <InputGroup size="sm">
                    <InputGroup.Prepend>
                      <InputGroup.Text>Professor</InputGroup.Text>
                    </InputGroup.Prepend>
                    <SelectProfessores
                      name="idProfessor"
                      idProfessor={idProfessor}
                      onChange={this.handleProfessorChange}
                      appendUnidade
                    />
                  </InputGroup>
                </Col>
              </Fragment>
            )}
        </Row >
        {!idProfessor ? (
          <Alert variant="danger"><h5 className="text-center my-3">Selecione um professor!</h5></Alert>
        ) : (
            <div className="loading">
              <Alert variant="info">
                Utilize este quadro para definir o modelo semanal de aulas.<br />
                Este modelo ficará disponível e poderá ser utilizado durante preenchimento da <b>Agenda</b> de aulas.
            </Alert>
              <Table responsive className="table-week">
                <thead>
                  <tr>
                    <th></th>
                    <th>{weekNames[0]}</th>
                    <th>{weekNames[1]}</th>
                    <th>{weekNames[2]}</th>
                    <th>{weekNames[3]}</th>
                    <th>{weekNames[4]}</th>
                    <th>{weekNames[5]}</th>
                    <th>{weekNames[6]}</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td colSpan="8">
                      <div>
                        <table>
                          <tbody>
                            <tr>
                              <td>{hours.map((value, key) => { return (<p key={key}>{value}</p>) })}</td>
                              <Semana
                                records={records}
                                emptyRecord={emptyRecord}
                                openModalForm={(value) => this.openModalForm(value)}
                              />
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </Table>
              <CSSTransition
                in={isLoading}
                timeout={500}
                classNames="fade"
                unmountOnExit
              >
                <div><Loading /></div>
              </CSSTransition>
            </div>
          )}
        <br />
        <FormQuadro
          record={record}
          emptyRecord={emptyRecord}
          idProfessor={idProfessor}
          showModal={showModal}
          closeModalForm={this.closeModalForm}
          loadFromAPI={this.loadFromAPI}
          doDelete={this.doDelete}
          usuario={this.usuario}
        />
      </Container >
    );
  }
}

export default Quadro;