import React from "react";
import ReactDOM from "react-dom";
import { HashRouter, Route, Switch, Redirect } from "react-router-dom";
import { toast } from "react-toastify";

import "bootstrap/dist/css/bootstrap.min.css";
import "react-toastify/dist/ReactToastify.css";
import "./assets/css/fontawesome.css";
import "./assets/css/transitions.css";

import PagesLayout from "./layouts/Pages";
import AppLayout from "./layouts/App";

import Festival from "./views/Festival";

import { isAuthenticated } from "./services/auth";

toast.configure({
  autoClose: 2000,
  position: toast.POSITION.BOTTOM_CENTER
});

const PrivateRoute = ({ component: Component = null, render: Render = null, ...rest }) => {
  return (
    <Route
      {...rest}
      render={props =>
        isAuthenticated() ? (
          Render ? (Render(props)) : Component ? (<Component {...props} />) : null
        ) : (
          <Redirect to={{ pathname: '/login', state: { from: props.location } }} />
        )
      }
    />
  );
};

ReactDOM.render(
  <HashRouter>
    <Switch>
      <Redirect exact from="/" to="/inscricao" />
      <Redirect exact from="/app" to="/app/dashboard" />
      <PrivateRoute path="/app" render={props => <AppLayout {...props} />} />
      <Route path="/(login|recovery|reset|ativacao|inscricao|ativa-inscricao)" render={props => <PagesLayout {...props} />} />
      <Route path="/festival" render={props => <Festival {...props} />} />
      <Route path="*" component={() => <h1>Página não encontrada!</h1>} />
    </Switch>
  </HashRouter>,
  document.getElementById("root")
);