import React, { Component, memo } from "react";
import { toast } from 'react-toastify';

import api from "../../services/api";

class SelectUnidades extends Component {
  constructor(props) {
    super(props);
    this.state = {
      options: props.unidades ? props.unidades : [],
      value: '',
      error: ''
    };
    this.upState = props.onChange;
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.idUnidade !== prevState.value
      || nextProps.error !== prevState.error) {
      return {
        value: nextProps.idUnidade,
        error: nextProps.error
      };
    }
    return null;
  }

  loadUnidades = (params = {}) => {
    api.get('/unidades_web', { params: params })
      .then(response => {
        const unidades = response.data.unidades;
        let value = this.state.value;

        if (!value && unidades && unidades.length === 1) {
          value = unidades[0].id;
        } else if (value && unidades && unidades.length > 1) {
          var existe = false;
          for (let i = 0; i < unidades.length; i++) {
            if (unidades[i].id === value) {
              existe = true;
              break;
            }
          }
          if (!existe) value = '';
        }
        this.setState({
          options: unidades,
          value: value
        }, this.upState(this.props.name, value));
      })
      .catch(error => {
        toast.error(error.message);
      });
  };

  handleChange = (event) => {
    event.preventDefault();
    const value = event.target.value;
    if (this.state.value !== value) {
      this.setState({ value: value }, this.upState(this.props.name, value));
    }
  };

  componentDidMount = () => {
    if (this.props.checkProfessor && !this.props.idProfessor) {
      toast.error('Falha ao carregar Unidades!');
    } else if (this.props.idProfessor) {
      this.loadUnidades({ idProfessor: this.props.idProfessor, ativo: 'S' });
    } else if (!this.props.unidades) {
      this.loadUnidades();
    }
  };

  render() {
    const { options, value, error } = this.state;

    return (
      <select
        name={this.props.name}
        value={value}
        onChange={this.handleChange}
        className={'custom-select' + (error ? ' is-invalid' : '')}
        required={this.props.required}
      >
        {options.length > 1 && (<option value="">{this.props.label}</option>)}
        {options.map((v, i) => <option key={i} value={v.id}>{v.unidade}</option>)}
      </select>
    )
  }
}

export default memo(SelectUnidades);
