import React, { Component } from "react";
import {
  Container,
  Form,
  Row,
  Col,
  Alert,
  Card,
  InputGroup,
} from "react-bootstrap";
import { CSSTransition } from "react-transition-group";
import { toast } from "react-toastify";
import { Loading, SelectUnidades } from "../../../components";

import AlunosStackedChart from "./Charts/AlunosStackedChart";
import AlunosPieChart from "./Charts/AlunosPieChart";
import AlunosNivelPieChart from "./Charts/AlunosNivelPieChart";
import AlunosGeneroPieChart from "./Charts/AlunosGeneroPieChart";
import AlunosIdadeBarChart from "./Charts/AlunosIdadeBarChart";

import InscricoesBarChart from "./Charts/InscricoesBarChart";
import InscricoesPieChart from "./Charts/InscricoesPieChart";
import InscricoesTurnoPieChart from "./Charts/InscricoesTurnoPieChart";
import InscricoesGeneroPieChart from "./Charts/InscricoesGeneroPieChart";
import InscricoesIdadeBarChart from "./Charts/InscricoesIdadeBarChart";

import HorasAulaStackedChart from "./Charts/HorasAulaStackedChart";
import HorasAulaPieChart from "./Charts/HorasAulaPieChart";
import FrequenciaStackedChart from "./Charts/FrequenciaStackedChart";
import FrequenciaPieChart from "./Charts/FrequenciaPieChart";

import AtividadesBarChart from "./Charts/AtividadesBarChart";
import AtividadesPieChart from "./Charts/AtividadesPieChart";

import api from "../../../services/api";

const colors = [
  "#4e79a7",
  "#a0cbe8",
  "#f28e2b",
  "#ffbe7d",
  "#59a14f",
  "#8cd17d",
  "#b6992d",
  "#f1ce63",
  "#499894",
  "#86bcb6",
  "#e15759",
  "#ff9d9a",
  "#79706e",
  "#bab0ac",
  "#d37295",
  "#fabfd2",
  "#b07aa1",
  "#d4a6c8",
  "#9d7660",
  "#d7b5a6",
  "#c2c2c2",
  "#ff6666",
  "#c2c2f0",
  "#ffb3e6",
  "#ffcc99",
  "#c4e17f",
  "#ff99c1",
  "#99ff99",
  "#66b3ff",
  "#ffcc00",
  "#c4e17f",
  "#d9d9d9",
  "#ff6699",
  "#c2f0c2",
  "#ff6666",
  "#ffb3e6",
  "#b3b3b3",
  "#c2c2f0",
  "#ff99ff",
  "#c4e17f",
  "#ffcc99",
  "#c4e17f",
  "#ffb3b3",
  "#b3b3b3",
  "#66ff99",
];

class Dashboard extends Component {
  usuario = JSON.parse(localStorage.getItem("usuario"));

  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      idUnidade:
        this.usuario &&
        this.usuario.unidades &&
        this.usuario.unidades.length === 1
          ? this.usuario.unidades[0].id
          : "",
      unidades: [],
      total: {
        alunos: 0,
        inscricoes: 0,
        horasAula: 0,
        atividades: 0,
      },
    };
  }

  loadFromAPI = () => {
    this.setState({ isLoading: true }, () => {
      const params = this.state.idUnidade
        ? { idUnidade: this.state.idUnidade }
        : {};
      api
        .get("/relatorios/dashboard", { params })
        .then((response) => {
          let unidades = [],
            total = {
              alunos: 0,
              inscricoes: 0,
              horasAula: 0,
              aulas: 0,
              atividades: 0,
            };
          if (response.data.unidades && response.data.unidades.length) {
            unidades = response.data.unidades;
            for (let i = 0; i < unidades.length; i++) {
              unidades[i].color = colors[i];
              total.alunos += unidades[i].alunos.total;
              total.inscricoes += unidades[i].inscricoes.total;
              total.horasAula += unidades[i].horasAula.total;
              total.aulas += unidades[i].aulas.total;
            }
          }
          this.setState({
            unidades: unidades,
            total: total,
            isLoading: false,
          });
        })
        .catch((error) => {
          this.setState({ isLoading: false });
          toast.error("Falha ao obter dados!");
        });
    });
  };

  handleInputChange = (event) => {
    event.preventDefault();
    const { name, value } = event.target;
    this.handleComponentChange(name, value);
  };

  handleComponentChange = (name, value) => {
    if (this.state[name] !== value) {
      this.setState({ [name]: value }, () => {
        this.loadFromAPI();
      });
    }
  };

  componentDidMount = () => {
    this.loadFromAPI();
  };

  render() {
    const { isLoading, idUnidade, unidades, total } = this.state;

    return (
      <Container fluid>
        {this.usuario && this.usuario.unidades.length === 1 ? (
          <h1 className="page-title mb-3">Dashboard</h1>
        ) : (
          <Row noGutters className="mb-3">
            <Col sm={7} lg={9}>
              <h1 className="page-title">Dashboard</h1>
            </Col>
            <Col sm={5} lg={3} className="pt-3 pl-sm-3">
              <InputGroup size="sm">
                <InputGroup.Prepend>
                  <InputGroup.Text>Unidade</InputGroup.Text>
                </InputGroup.Prepend>
                <SelectUnidades
                  label="TODAS"
                  name="idUnidade"
                  unidades={this.usuario.unidades}
                  idUnidade={idUnidade}
                  onChange={this.handleComponentChange}
                />
              </InputGroup>
            </Col>
          </Row>
        )}

        <div className="loading">
          <Form.Row>
            <Col sm={6} md={3}>
              <Alert variant={"success"} className="alert-total">
                <h6>ALUNOS</h6>
                <div>{total.alunos}</div>
              </Alert>
            </Col>
            <Col sm={6} md={3}>
              <Alert variant={"danger"} className="alert-total">
                <h6>INSCRIÇÕES</h6>
                <div>{total.inscricoes}</div>
              </Alert>
            </Col>
            <Col sm={6} md={3}>
              <Alert variant={"warning"} className="alert-total">
                <h6>HS.AULA/SEMANA</h6>
                <div>{total.horasAula.toFixed(2)}</div>
              </Alert>
            </Col>
            <Col sm={6} md={3}>
              <Alert variant={"primary"} className="alert-total">
                <h6>ATIVIDADES</h6>
                <div>{unidades.reduce((a, b) => a + b.atividades, 0)}</div>
              </Alert>
            </Col>
          </Form.Row>

          {!idUnidade && this.usuario && this.usuario.unidades.length > 1 ? (
            <div>
              <Card className="mb-2">
                <Card.Header className="text-center bg-secondary text-white">
                  <b>ALUNOS</b> ({total.alunos})
                </Card.Header>
                <Card.Body>
                  <Form.Row>
                    <Col lg={5} className="mb-3 mb-lg-0">
                      <AlunosStackedChart unidades={unidades} heigth={400} />
                    </Col>
                    <Col sm={6} lg={3}>
                      <Form.Row className="mb-3 mb-lg-0">
                        <Col xs={12} className="mb-2">
                          <AlunosNivelPieChart
                            unidades={unidades}
                            heigth={160}
                          />
                        </Col>
                        <Col xs={12} className="mb-2">
                          <AlunosGeneroPieChart
                            unidades={unidades}
                            heigth={160}
                          />
                        </Col>
                        <Col xs={12}>
                          <AlunosIdadeBarChart
                            unidades={unidades}
                            heigth={160}
                          />
                        </Col>
                      </Form.Row>
                    </Col>
                    <Col sm={6} lg={4}>
                      <AlunosPieChart unidades={unidades} heigth={400} />
                    </Col>
                  </Form.Row>
                </Card.Body>
              </Card>
              <Card className="mb-2">
                <Card.Header className="text-center text-center bg-secondary text-white">
                  <b>AULAS</b>
                </Card.Header>
                <Card.Body>
                  <Form.Row>
                    <Col md={5}>
                      <HorasAulaStackedChart unidades={unidades} heigth={400} />
                    </Col>
                    <Col sm={7} md={4} className="mt-4 mt-md-0">
                      <HorasAulaPieChart unidades={unidades} heigth={400} />
                    </Col>
                    <Col sm={5} md={3} className="mt-4 mt-md-0">
                      <Form.Row className="mb-3 mb-lg-0">
                        <Col xs={12} className="mb-2">
                          <FrequenciaPieChart
                            unidades={unidades}
                            heigth={120}
                          />
                        </Col>
                        <Col xs={12} className="mb-2">
                          <FrequenciaStackedChart
                            unidades={unidades}
                            heigth={350}
                          />
                        </Col>
                      </Form.Row>
                    </Col>
                  </Form.Row>
                </Card.Body>
              </Card>
              <Card className="mb-2">
                <Card.Header className="text-center bg-secondary text-white">
                  <b>INSCRIÇÕES</b> ({total.inscricoes})
                </Card.Header>
                <Card.Body>
                  <Form.Row>
                    <Col lg={5} className="mb-3 mb-lg-0">
                      <InscricoesBarChart unidades={unidades} heigth={400} />
                    </Col>
                    <Col sm={6} lg={4}>
                      <InscricoesPieChart unidades={unidades} heigth={400} />
                    </Col>
                    <Col sm={6} lg={3}>
                      <Form.Row className="mb-3 mb-lg-0">
                        <Col xs={12} className="mb-2">
                          <InscricoesTurnoPieChart
                            unidades={unidades}
                            heigth={160}
                          />
                        </Col>
                        <Col xs={12} className="mb-2">
                          <InscricoesGeneroPieChart
                            unidades={unidades}
                            heigth={160}
                          />
                        </Col>
                        <Col xs={12}>
                          <InscricoesIdadeBarChart
                            unidades={unidades}
                            heigth={160}
                          />
                        </Col>
                      </Form.Row>
                    </Col>
                  </Form.Row>
                </Card.Body>
              </Card>
              <Card className="mb-2">
                <Card.Header className="text-center bg-secondary text-white">
                  <b>ATIVIDADES</b>
                </Card.Header>
                <Card.Body>
                  <Form.Row>
                    <Col sm={6} md={7} lg={8} xl={6}>
                      <AtividadesBarChart unidades={unidades} heigth={300} />
                    </Col>
                    <Col sm={6} md={5} lg={4} xl={6}>
                      <AtividadesPieChart unidades={unidades} heigth={300} />
                    </Col>
                  </Form.Row>
                </Card.Body>
              </Card>
            </div>
          ) : (
            <div>
              <Card className="mb-2">
                <Card.Header className="text-center bg-secondary text-white">
                  <b>ALUNOS</b> ({total.alunos})
                </Card.Header>
                <Card.Body>
                  <Form.Row>
                    <Col xs={12} md={5}>
                      <AlunosIdadeBarChart unidades={unidades} heigth={300} />
                    </Col>
                    <Col xs={6} md={4} className="mb-2">
                      <AlunosNivelPieChart unidades={unidades} heigth={300} />
                    </Col>
                    <Col xs={6} md={3} className="mb-2">
                      <AlunosGeneroPieChart unidades={unidades} heigth={300} />
                    </Col>
                  </Form.Row>
                </Card.Body>
              </Card>
              <Card className="mb-2">
                <Card.Header className="text-center bg-secondary text-white">
                  <b>INSCRIÇÕES</b> ({total.inscricoes})
                </Card.Header>
                <Card.Body>
                  <Form.Row>
                    <Col xs={12} md={5}>
                      <InscricoesIdadeBarChart
                        unidades={unidades}
                        heigth={300}
                      />
                    </Col>
                    <Col xs={6} md={4} className="mb-2">
                      <InscricoesTurnoPieChart
                        unidades={unidades}
                        heigth={300}
                      />
                    </Col>
                    <Col xs={6} md={3} className="mb-2">
                      <InscricoesGeneroPieChart
                        unidades={unidades}
                        heigth={300}
                      />
                    </Col>
                  </Form.Row>
                </Card.Body>
              </Card>
            </div>
          )}
          <CSSTransition
            in={isLoading}
            timeout={500}
            classNames="fade"
            unmountOnExit
          >
            <div>
              <Loading />
            </div>
          </CSSTransition>
        </div>
      </Container>
    );
  }
}

export default Dashboard;
