import React, { Component } from 'react'
import Chart from 'chart.js';

let myChart;

class FrequenciaPieChart extends Component {

  componentDidMount = () => {
    this.buildChart();
  };

  componentDidUpdate = () => {
    this.buildChart();
  };

  buildChart = () => {
    let element = document.getElementById("piechart-frequencia"),
      myChartRef = element.getContext("2d");

    element.height = this.props.heigth;

    if (typeof myChart !== "undefined") myChart.destroy();

    const unidades = this.props.unidades.slice();
    let frequencia = [0, 0];

    unidades.forEach(unidade => {
      frequencia[0] += unidade.aulas.presente;
      frequencia[1] += unidade.aulas.ausente;
    });

    myChart = new Chart(myChartRef, {
      type: 'pie',
      data: {
        labels: ['Presente', 'Ausente'],
        datasets: [
          {
            data: frequencia,
            backgroundColor: ['rgba(92, 184, 92, 1)', 'rgba(217, 83, 79, 1)'],
            datalabels: {
              color: 'rgba(0, 0, 0, 1)',
              anchor: 'end',
              align: 'start',
              formatter: function (value, context) {
                const total = context.dataset.data.reduce((a, b) => a + b, 0);
                return Math.round((value / total) * 100) + '%';
              }
            }
          }
        ]
      },
      options: {
        title: {
          display: true,
          text: 'Índice frequência',
          fontSize: 14,
          padding: 5
        },
        legend: {
          position: 'left',
          align: 'end',
          labels: { padding: 5, boxWidth: 12 }
        },
        layout: {
          padding: {
            left: 0,
            right: 0,
            top: -5,
            bottom: 0
          }
        },
        maintainAspectRatio: false
      }
    });
  };

  render() {
    return (
      <canvas id="piechart-frequencia" />
    )
  }
}

export default FrequenciaPieChart;