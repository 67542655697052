import React, { Component } from 'react'
import Chart from "chart.js";

let myChart;

class InscricoesBarChart extends Component {

  componentDidMount = () => {
    this.buildChart();
  };

  componentDidUpdate = () => {
    this.buildChart();
  };

  buildChart = () => {
    let element = document.getElementById("chart-inscricoes"),
      myChartRef = element.getContext("2d");

    element.height = this.props.heigth;

    if (typeof myChart !== "undefined") myChart.destroy();

    const compare = (a, b) => a.inscricoes.total > b.inscricoes.total ? -1 : (a.inscricoes.total < b.inscricoes.total ? 1 : 0);

    let unidades = this.props.unidades.slice();
    unidades.sort(compare);

    myChart = new Chart(myChartRef, {
      type: 'horizontalBar',
      data: {
        labels: unidades.map(u => u.unidade),
        datasets: [
          {
            data: unidades.map(u => u.inscricoes.total),
            categoryPercentage: 0.8,
            barPercentage: 1.0,
            backgroundColor: unidades.map(u => u.color),
            borderWidth: 1,
            datalabels: {
              color: 'rgba(0, 0, 0, 0.8)',
              anchor: 'end',
              align: 'end'
            }
          }
        ]
      },
      options: {
        title: {
          display: true,
          text: 'Inscrições / Unidade',
          fontSize: 14
        },
        layout: {
          padding: {
            left: 0,
            right: 20,
            top: -10,
            bottom: -5
          }
        },
        legend: { display: false },
        tooltips: { enabled: false },
        scales: {
          xAxes: [{
            gridLines: {
              zeroLineColor: 'rgba(0, 0, 0, 0.5)'
            },
            ticks: {
              fontColor: '#212528'
            },
          }],
          yAxes: [{
            gridLines: {
              tickMarkLength: 0
            },
            ticks: {
              fontColor: '#212528',
              padding: 10
            },
          }]
        },
        maintainAspectRatio: false
      }
    });
  };

  render() {
    return (
      <canvas id="chart-inscricoes" />
    )
  }
}

export default InscricoesBarChart;