import axios from "axios";
import { toast } from "react-toastify";

const api = axios.create({
  // timeout: 5000,
  baseURL: "https://app.fabricadegaiteiros.com.br/api",
});

api.interceptors.request.use((config) => {
  const token = localStorage.getItem("token");
  config.headers["authorization"] = `Bearer ${token}`;
  return config;
});

api.interceptors.response.use(
  (response) => {
    if (response.data.message && response.data.message !== "ok")
      toast.error(response.data.message);
    return response;
  },
  (error) => {
    if (error.response) {
      if (error.response.status === 403) {
        error.response.data.message = "Tempo de login expirado!";
        toast.error(error.response.data.message);
        window.location = "#/login";
      } else {
        toast.error(error.response.data.message);
      }
    } else if (error.request) {
      toast.error("Sem resposta do servidor!");
    } else {
      toast.error(error.message);
    }
    return Promise.reject(error);
  }
);

export default api;
