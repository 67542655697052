import React, { Component, memo } from "react";
import { Form } from "react-bootstrap";
import { toast } from 'react-toastify';

import api from "../../services/api";

class ButtonChamada extends Component {
  constructor(props) {
    super(props);
    this.state = {
      presenca: '',
      checked: false
    };
    this.upState = props.onClick;
  }

  static getDerivedStateFromProps(props) {
    return {
      presenca: props.presenca,
      checked: props.presenca === 'S' ? true : false
    };
  }

  componentDidUpdate = (prevProps) => {
    if (this.props.presenca !== prevProps.presenca) {
      this.setState({
        presenca: this.props.presenca,
        checked: this.props.presenca === 'S' ? true : false
      });
    }
  };

  handleInputChange = (event) => {
    const value = event.target.type === 'checkbox' ? event.target.checked : event.target.value;
    this.submitPresenca(value);
  };

  submitPresenca = (checked) => {
    const presenca = checked === true ? 'S' : 'N',
      params = {
        idAula: this.props.idAula,
        idAluno: this.props.idAluno,
        presenca: presenca
      };
    api.post('/aulas/chamadas', params)
      .then((response) => {
        if (response.data.message === 'ok') {
          toast.success('Chamada atualizada com sucesso!');
          this.setState({
            presenca: presenca,
            checked: checked
          }, this.upState(this.props.idAula, this.props.idAluno, presenca));
        } else {
          toast.error(response.data.message);
        }
      })
      .catch((error) => {
        toast.error('Ocorreu uma falha!');
      })
  };

  render() {
    const { checked } = this.state;

    return (
      <Form.Check
        aria-label="option 1"
        checked={checked}
        onChange={this.handleInputChange}
      />
    )
  }
}

export default memo(ButtonChamada);
