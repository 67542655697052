import React, { Component, memo } from "react";
import { toast } from 'react-toastify';
import Select from 'react-select';

import api from "../../services/api";

class MultiSelectCursos extends Component {
  _isMounted = false;

  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      options: [],
      selected: props.cursos,
      disabled: props.disabled
    };
    this.onChange = props.onChange;
  }

  loadCursos = (params = {}, upState = false) => {
    api.get('/cursos', { params: params })
      .then(response => {
        if (this._isMounted) {
          if (response.data.message) toast.error(response.data.message);
          const options = response.data.cursos.map(c => { return { value: c.id, label: c.curso } });
          if (upState) {
            this.setState({
              options: options,
              selected: null,
              disabled: false,
            }, this.onChange('cursos', []));
          } else {
            this.setState({ options: options, disabled: false, });
          }
        }
      })
      .catch(error => {
        toast.error(error.message);
      });
  };

  handleChange = (selected) => {
    this.setState({ selected }, this.onChange('cursos', selected));
  };

  componentDidMount = () => {
    const { checkProfessor, idProfessor } = this.props;
    this._isMounted = true;
    if (!checkProfessor) {
      this.loadCursos({ ativo: 'S' });
    } else if (checkProfessor && idProfessor) {
      this.loadCursos({ idProfessor, ativo: 'S' });
    } else {
      this.setState({ disabled: true });
    }
  };

  componentDidUpdate = (prevProps) => {
    const { checkProfessor, idProfessor } = this.props;
    if (checkProfessor) {
      if (idProfessor !== prevProps.idProfessor) {
        if (idProfessor) {
          this.loadCursos({ idProfessor, ativo: 'S' }, true);
        } else {
          this.setState({
            options: [],
            selected: null,
            disabled: true
          });
        }
      }
    }
  };

  componentWillUnmount = () => {
    this._isMounted = false;
  };

  render() {
    const { isLoading, options, selected, disabled } = this.state;

    return (
      <Select
        name={this.props.name}
        isLoading={isLoading}
        value={selected}
        onChange={this.handleChange}
        options={options}
        isMulti
        closeMenuOnSelect={false}
        isDisabled={disabled}
        placeholder="Selecione os Cursos"
        noOptionsMessage={() => null}
      />
    )
  }
}

export default memo(MultiSelectCursos);
