import React from "react";
import { Container } from "react-bootstrap";

function Footer() {
  return <footer sticky="bottom" className="container-fluid footer mt-auto py-3">
    <Container >
      <p className="text-center mb-0">
        &copy; {1900 + new Date().getYear()}{" "}
        <span>Fábrica de Gaiteiros</span>
      </p>
    </Container>
  </footer>;
}

export default Footer;