import React, { Component, memo } from "react";
import { toast } from 'react-toastify';
import Select from 'react-select';

import api from "../../services/api";

class MultiSelectProfessores extends Component {
  _isMounted = false;

  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      options: [],
      selected: props.professores,
      disabled: props.disabled
    };
    this.onChange = props.onChange;
  }
  
  loadProfessores = (params = {}, upState = false) => {
    api.get('/professores', { params: params })
      .then(response => {
        if (this._isMounted) {
          if (response.data.message) toast.error(response.data.message);
          const options = response.data.professores.map(p => { return { value: p.id, label: p.nome } });
          if (upState) {
            this.setState({
              options: options,
              selected: null,
              disabled: false,
            }, this.onChange('professores', []));
          } else {
            this.setState({ options: options, disabled: false, });
          }
        }
      })
      .catch(error => {
        toast.error(error.message);
      });
  };

  handleChange = (selected) => {
    this.setState({ selected }, this.onChange('professores', selected));
  };

  componentDidMount = () => {
    const { checkUnidade, idUnidade } = this.props;
    this._isMounted = true;

    if (!checkUnidade) {
      this.loadProfessores({ ativo: 'S' });
    } else if (checkUnidade && idUnidade) {
      this.loadProfessores({ idUnidade, ativo: 'S' });
    } else {
      this.setState({ disabled: true });
    }
  };

  componentDidUpdate = (prevProps) => {
    const { checkUnidade, idUnidade } = this.props;
    if (checkUnidade) {
      if (idUnidade !== prevProps.idUnidade) {
        if (idUnidade) {
          this.loadProfessores({ idUnidade, ativo: 'S' }, true);
        } else {
          this.setState({
            options: [],
            selected: null,
            disabled: true
          });
        }
      }
    }
  };

  componentWillUnmount = () => {
    this._isMounted = false;
  };

  render() {
    const { isLoading, options, selected, disabled } = this.state;

    return (
      <Select
        name={this.props.name}
        isLoading={isLoading}
        value={selected}
        onChange={this.handleChange}
        options={options}
        isMulti
        closeMenuOnSelect={false}
        isDisabled={disabled}
        placeholder="Selecione os professores"
        noOptionsMessage={() => null}
      />
    )
  }
}

export default memo(MultiSelectProfessores);
