import React, { Component } from "react";

const estados = [
  { id: 1, estado: 'Rio Grande do Sul', uf: 'RS' },
  { id: 2, estado: 'Santa Catarina', uf: 'SC' }
];

class SelectEstados extends Component {
  constructor(props) {
    super(props);
    this.state = {
      value: '',
      error: ''
    };
    this.onChange = props.onChange;
  }

  static getDerivedStateFromProps(props, state) {
    if (props.idEstado !== state.value) {
      return {
        value: props.idEstado,
        error: props.error
      };
    }
    return null;
  }

  handleChange = (event) => {
    event.preventDefault();
    const value = event.target.value;
    if (this.state.value !== value) {
      this.setState({ value: value }, this.onChange(this.props.name, value));
    }
  };

  render() {
    const { value, error } = this.state;

    return (
      <select
        value={value}
        onChange={this.handleChange}
        onBlur={this.handleChange}
        className={'custom-select' + (error ? ' is-invalid' : '')}
        required={this.props.required}
      >
        <option value=""></option>
        {estados.map((e, i) => <option key={i} value={e.id}>{this.props.showUF ? e.uf : e.estado}</option>)}
      </select>
    )
  }
}

export default SelectEstados;
