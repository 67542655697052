import React, { Component, Fragment } from "react";
import { Modal, Form, Col, Button } from "react-bootstrap";
import { CSSTransition } from 'react-transition-group';
import { toast } from 'react-toastify';
import SweetAlert from "react-bootstrap-sweetalert";
import DatePicker from "react-datepicker";
import { isValidCPF, formatCPF } from '@brazilian-utils/brazilian-utils';
import {
  Loading,
  SelectUnidades,
  SelectCursos,
  SelectSeries,
  SelectTurnos,
  SelectGeneros,
  SelectAtivo,
  TypeaheadColegios
} from "../../../components";
import { validEmailRegex } from "../../../utils/validation";
import { dateToServer } from "../../../utils/dates";
import { formatFone } from "../../../utils/fone";

import api from "../../../services/api";

const resetErrors = {
  idUnidade: '',
  idCurso: '',
  nome: '',
  nascimento: '',
  cpf: '',
  rg: '',
  email: '',
  fone: '',
  colegio: '',
  serie: '',
  turno: '',
  inscricao: ''
};

class FormInscricao extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showModal: false,
      isLoading: false,
      alert: null,
      record: {},
      errors: resetErrors
    };
    this.loadFromAPI = props.loadFromAPI;
    this.closeModalForm = props.closeModal;
  }

  static getDerivedStateFromProps(props, state) {
    if (props.showModal !== state.showModal) {
      let record = Object.assign({}, props.record);

      for (const key in record) {
        if (key === 'nascimento' || key === 'inscricao') {
          if (record[key] && record[key].length) record[key] = new Date(record[key].replace('Z', ''));
          else record[key] = '';
        } else if (record[key] === null) {
          record[key] = '';
        }
      }

      if (props.usuario.unidades.length === 1) record.idUnidade = props.usuario.unidades[0].id;
      if (props.usuario.cursos.length === 1) record.idCurso = props.usuario.cursos[0].id;

      return {
        showModal: props.showModal,
        record: record
      };
    }
    return null;
  }

  closeModal = () => {
    this.setState({
      showModal: false,
      isLoading: false,
      record: this.props.emptyRecord,
      errors: resetErrors
    }, () => this.closeModalForm());
  };

  validateInput = (name, value, errors) => {
    switch (name) {
      case 'idUnidade':
        errors.idUnidade = (!value) ? 'Selecione uma UNIDADE' : '';
        break;
      // case 'idCurso':
      //   errors.idCurso = (!value) ? 'Selecione um CURSO' : '';
      //   break;
      case 'nome':
        errors.nome = (!value) ? 'Preencha o campo NOME' : '';
        break;
      // case 'nascimento':
      //   errors.nascimento = (!value) ? 'Preencha o campo DATA DE NASCIMENTO' : '';
      //   break;
      case 'cpf':
        // errors.cpf = (!value) ? 'Preencha o campo CPF' : '';
        errors.cpf = (value && !isValidCPF(value)) ? 'CPF inválido' : '';
        break;
      // case 'rg':
      //   errors.rg = (!value) ? 'Preencha o campo RG' : '';
      //   break;
      case 'email':
        // errors.email = (!value) ? 'Preencha o campo EMAIL' : '';
        errors.email = (value && !validEmailRegex.test(value)) ? 'EMAIL inválido' : '';
        break;
      case 'fone':
        errors.fone = (!value) ? 'Preencha o campo FONE' : '';
        break;
      // case 'colegio':
      //   errors.colegio = (!value) ? 'Preencha o campo ESCOLA/COLÉGIO' : '';
      //   break;
      // case 'serie':
      //   errors.serie = (!value) ? 'Selecione uma SÉRIE' : '';
      //   break;
      // case 'turno':
      //   errors.turno = (!value) ? 'Selecione um TURNO' : '';
      //   break;
      case 'inscricao':
        errors.inscricao = (!value) ? 'Preencha o campo INSCRIÇÃO' : '';
        break;
      default:
        break;
    }

    return errors;
  };

  validateForm = () => {
    let record = Object.assign({}, this.state.record),
      errors = Object.assign({}, this.state.errors);

    for (const key in record) {
      errors = this.validateInput(key, record[key], errors);
    }

    return errors;
  };

  handleInputChange = (event) => {
    event.preventDefault();
    const { name, value } = event.target;
    this.handleComponentChange(name, value);
  };

  handleComponentChange = (name, value) => {
    let record = Object.assign({}, this.state.record),
      errors = Object.assign({}, this.state.errors);

    if (name === 'cpf') value = formatCPF(value);
    if (name === 'fone') value = formatFone(value);

    record[name] = value;
    this.setState({
      record: record,
      errors: this.validateInput(name, value, errors)
    });
  };

  hideAlert = () => {
    this.setState({ alert: null });
  };

  handleSubmit = (event) => {
    event.preventDefault();
    const errors = this.validateForm();
    if (JSON.stringify(errors) !== JSON.stringify(resetErrors)) {
      this.setState({
        alert: (<SweetAlert warning title="Oops!" onConfirm={() => this.hideAlert()} showConfirm={false} timeout={2000}>Preenchimento incorreto!</SweetAlert>),
        errors: errors
      });
    } else {
      this.setState({ isLoading: true }, () => {
        let record = Object.assign({}, this.state.record);
        record.nascimento = dateToServer(record.nascimento);
        record.inscricao = dateToServer(record.inscricao);
        api.post('/inscricoes', record)
          .then((response) => {
            if (response.data.message === 'ok') {
              toast.success('Inscrição salva com sucesso!');
              this.loadFromAPI();
              this.closeModal();
            } else {
              this.setState({
                isLoading: false,
                alert: (<SweetAlert warning title="Oops!" onConfirm={() => this.hideAlert()} showConfirm={false} timeout={2000}>{response.data.message}</SweetAlert>),
              });
            }
          })
          .catch((error) => {
            this.setState({ isLoading: false });
            toast.error('Falha ao enviar os dados!');
          });
      });
    }
  };

  render() {
    const { showModal, isLoading, alert, record, errors } = this.state;

    return (
      <Fragment>
        {alert}
        <Modal show={showModal} onHide={this.closeModal}>
          <Modal.Header closeButton>
            <Modal.Title>{record.id ? 'Editar' : 'Adicionar'} Inscrição</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="loading">
              <Form id="form-inscricao" className="form-horizontal form-sm form-custom" onSubmit={this.handleSubmit} noValidate>
                <CSSTransition
                  in={isLoading}
                  timeout={500}
                  classNames="fade"
                  unmountOnExit
                >
                  <div><Loading /></div>
                </CSSTransition>
                <Form.Row>
                  <Form.Group as={Col} sm={7}>
                    <Form.Label>Unidade</Form.Label>
                    <SelectUnidades
                      name="idUnidade"
                      unidades={this.props.usuario.unidades}
                      idUnidade={record.idUnidade}
                      onChange={this.handleComponentChange}
                      required
                      error={errors.idUnidade}
                    />
                    <Form.Control.Feedback type="invalid">{errors.idUnidade}</Form.Control.Feedback>
                  </Form.Group>
                  <Form.Group as={Col} sm={5}>
                    <Form.Label>Curso</Form.Label>
                    <SelectCursos
                      name="idCurso"
                      cursos={this.props.usuario.cursos}
                      idCurso={record.idCurso}
                      onChange={this.handleComponentChange}
                      required
                      error={errors.idCurso}
                    />
                    <Form.Control.Feedback type="invalid">{errors.idCurso}</Form.Control.Feedback>
                  </Form.Group>
                  <Form.Group as={Col} sm={8}>
                    <Form.Label>Nome</Form.Label>
                    <Form.Control type="text"
                      name="nome"
                      value={record.nome}
                      onChange={this.handleInputChange}
                      onBlur={this.handleInputChange}
                      className={errors.nome.length ? 'is-invalid' : ''}
                      required
                    />
                    <Form.Control.Feedback type="invalid">{errors.nome}</Form.Control.Feedback>
                  </Form.Group>
                  <Form.Group as={Col} sm={4}>
                    <Form.Label>Data de nascimento</Form.Label>
                    <div className={errors.nascimento ? ' is-invalid' : ''}>
                      <DatePicker selected={record.nascimento}
                        dateFormat="dd/MM/yyyy"
                        onChange={date => this.handleComponentChange('nascimento', date)}
                        // minDate={this.subDays(new Date(), 5475)}
                        popperPlacement="bottom-end"
                        popperClassName="right-triangle"
                        className={'form-control'}
                        showMonthDropdown
                        showYearDropdown
                        dropdownMode="select"
                      />
                    </div>
                    <Form.Control.Feedback type="invalid">{errors.nascimento}</Form.Control.Feedback>
                  </Form.Group>
                  <Form.Group as={Col} sm={6}>
                    <Form.Label>CPF</Form.Label>
                    <Form.Control type="text"
                      name="cpf"
                      value={record.cpf}
                      onChange={this.handleInputChange}
                      onBlur={this.handleInputChange}
                      className={errors.cpf.length ? 'is-invalid' : ''}
                    />
                    <Form.Control.Feedback type="invalid">{errors.cpf}</Form.Control.Feedback>
                  </Form.Group>
                  <Form.Group as={Col} sm={6}>
                    <Form.Label>RG</Form.Label>
                    <Form.Control type="text"
                      name="rg"
                      value={record.rg}
                      onChange={this.handleInputChange}
                      onBlur={this.handleInputChange}
                      className={errors.rg.length ? 'is-invalid' : ''}
                    />
                    <Form.Control.Feedback type="invalid">{errors.rg}</Form.Control.Feedback>
                  </Form.Group>
                  <Form.Group as={Col} sm={8}>
                    <Form.Label>Email</Form.Label>
                    <Form.Control type="email"
                      name="email"
                      value={record.email}
                      onChange={this.handleInputChange}
                      onBlur={this.handleInputChange}
                      className={errors.email.length ? 'is-invalid' : ''}
                    />
                    <Form.Control.Feedback type="invalid">{errors.email}</Form.Control.Feedback>
                  </Form.Group>
                  <Form.Group as={Col} sm={4}>
                    <Form.Label>Fone</Form.Label>
                    <Form.Control type="text"
                      name="fone"
                      value={record.fone}
                      onChange={this.handleInputChange}
                      onBlur={this.handleInputChange}
                      className={errors.fone.length ? 'is-invalid' : ''}
                      required
                    />
                    <Form.Control.Feedback type="invalid">{errors.fone}</Form.Control.Feedback>
                  </Form.Group>
                  <Form.Group as={Col} sm={6}>
                    <Form.Label>Escola/Colégio</Form.Label>
                    <TypeaheadColegios
                      name="colegio"
                      colegio={record.colegio}
                      onChange={this.handleComponentChange}
                      error={errors.colegio}
                    />
                    <Form.Control.Feedback type="invalid">{errors.colegio}</Form.Control.Feedback>
                  </Form.Group>
                  <Form.Group as={Col} xs={6} sm={3}>
                    <Form.Label>Série</Form.Label>
                    <SelectSeries
                      name="serie"
                      serie={record.serie}
                      onChange={this.handleComponentChange}
                      error={errors.serie}
                    />
                    <Form.Control.Feedback type="invalid">{errors.serie}</Form.Control.Feedback>
                  </Form.Group>
                  <Form.Group as={Col} xs={6} sm={3}>
                    <Form.Label>Turno</Form.Label>
                    <SelectTurnos
                      name="turno"
                      turno={record.turno}
                      onChange={this.handleComponentChange}
                      error={errors.turno}
                    />
                    <Form.Control.Feedback type="invalid">{errors.turno}</Form.Control.Feedback>
                  </Form.Group>
                  <Form.Group as={Col} xs={6} sm={2}>
                    <Form.Label>Gênero</Form.Label>
                    <SelectGeneros
                      name="genero"
                      genero={record.genero}
                      onChange={this.handleComponentChange}
                      required
                      error={errors.genero}
                    />
                    <Form.Control.Feedback type="invalid">{errors.genero}</Form.Control.Feedback>
                  </Form.Group>
                  <Form.Group as={Col} xs={6} sm={4}>
                    <Form.Label>Data da inscrição</Form.Label>
                    <div className={errors.inscricao ? ' is-invalid' : ''}>
                      <DatePicker selected={record.inscricao}
                        dateFormat="dd/MM/yyyy"
                        onChange={date => this.handleComponentChange('inscricao', date)}
                        className={'form-control'}
                        showMonthDropdown
                        showYearDropdown
                        dropdownMode="select"
                        required
                      />
                    </div>
                    <Form.Control.Feedback type="invalid">{errors.inscricao}</Form.Control.Feedback>
                  </Form.Group>
                  {record.id && (
                    <Form.Group as={Col} xs={6} sm={3}>
                      <Form.Label>Status</Form.Label>
                      <SelectAtivo
                        name="ativo"
                        ativo={record.ativo}
                        onChange={this.handleComponentChange}
                      />
                    </Form.Group>
                  )}
                </Form.Row>
              </Form>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="outline-secondary" onClick={this.closeModal}>Cancelar</Button>
            <Button variant="success" form="form-inscricao" type="submit">Salvar</Button>
          </Modal.Footer>
        </Modal>
      </Fragment >
    );
  }
}

export default FormInscricao;