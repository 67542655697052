import React, { Component } from "react";

class SelectStatus extends Component {
  constructor(props) {
    super(props);
    this.state = {
      value: props.status ? props.status : ''
    };
    this.onChange = props.onChange;
  }

  handleChange = (event) => {
    event.preventDefault();
    const value = event.target.value;
    if (this.state.value !== value) {
      this.setState({ value: value }, this.onChange(this.props.name, value));
    }
  };

  render() {
    const { value } = this.state;

    return (
      <select
        value={value}
        onChange={this.handleChange}
        className="custom-select"
        required={this.props.required}
      >
        <option value="">TODOS</option>
        <option value="M">Matriculados</option>
        <option value="F">Formados</option>
        <option value="D">Desistencias</option>
      </select>
    )
  }
}

export default SelectStatus;
