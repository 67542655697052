import React, { Component, Fragment } from "react";
import { Modal, Form, Col, Button } from "react-bootstrap";
import { CSSTransition } from 'react-transition-group';
import { toast } from 'react-toastify';
import SweetAlert from "react-bootstrap-sweetalert";
import DatePicker from "react-datepicker";
import {
  Loading,
  ButtonDelete,
  SelectCursos,
  SelectUnidades,
  MultiSelectAlunos
} from "../../../../components";
import { dateToServer } from "../../../../utils/dates";

import api from "../../../../services/api";

const resetErrors = {
  idUnidade: '',
  idCurso: '',
  nivel: '',
  data: '',
  inicio: '',
  fim: ''
};

class FormAula extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showModal: false,
      isLoading: false,
      readOnly: false,
      alert: null,
      record: props.emptyRecord,
      errors: resetErrors
    };
    this.loadFromAPI = props.loadFromAPI;
    this.closeModalForm = props.closeModalForm;
    this.doDelete = props.doDelete;
  }

  static getDerivedStateFromProps(props, state) {
    if (props.showModal !== state.showModal) {
      const record = Object.assign({}, props.record);

      if (record.inicio && typeof record.inicio === 'string') {
        record.inicio = new Date(record.inicio.replace(/Z$/, ''));
      }
      if (record.fim && typeof record.fim === 'string') {
        record.fim = new Date(record.fim.replace(/Z$/, ''));
      }

      let readOnly = false;
      if (record.alunos && record.alunos.length) {
        record.alunos = record.alunos.map(a => {
          if (a.presenca === 'S') readOnly = true;
          return { value: a.idAluno, label: a.nome }
        });
      }

      record.data = record.inicio;
      record.idProfessor = props.idProfessor;
      return {
        showModal: props.showModal,
        readOnly: readOnly,
        record: record
      };
    }
    return null;
  }

  closeModal = () => {
    this.setState({
      showModal: false,
      isLoading: false,
      record: this.props.emptyRecord,
      errors: resetErrors
    }, () => this.closeModalForm());
  };

  validateInput = (name, value, errors) => {
    switch (name) {
      case 'idUnidade':
        errors.idUnidade = !value ? 'Selecione uma UNIDADE' : '';
        break;
      case 'idCurso':
        errors.idCurso = !value ? 'Selecione um CURSO' : '';
        break;
      case 'data':
        errors.data = !value instanceof Date ? 'DATA inválida' : '';
        break;
      case 'inicio':
        errors.inicio = !value instanceof Date ? 'HORA INICIAL inválida' : '';
        break;
      case 'fim':
        errors.fim = !value instanceof Date ? 'HORA FINAL inválida' : '';
        break;
      default:
        break;
    }

    return errors;
  };

  validateForm = () => {
    let record = Object.assign({}, this.state.record),
      errors = Object.assign({}, this.state.errors);

    for (const key in record) {
      errors = this.validateInput(key, record[key], errors);
    }

    return errors;
  };

  handleInputChange = (event) => {
    event.preventDefault();
    const { name, value } = event.target;
    this.handleComponentChange(name, value);
  };

  handleComponentChange = (name, value) => {
    let record = Object.assign({}, this.state.record),
      errors = Object.assign({}, this.state.errors);

    if (record[name] !== value) {
      record[name] = value;
      this.setState({
        record: record,
        errors: this.validateInput(name, value, errors)
      });
    }
  };

  hideAlert = () => {
    this.setState({ alert: null });
  };

  handleSubmit = (event) => {
    event.preventDefault();
    const errors = this.validateForm();
    if (JSON.stringify(errors) !== JSON.stringify(resetErrors)) {
      this.setState({
        alert: (<SweetAlert warning title="Oops!" onConfirm={() => this.hideAlert()} showConfirm={false} timeout={2000}>Preenchimento incorreto!</SweetAlert>),
        errors: errors
      });
    } else {
      let record = Object.assign({}, this.state.record);
      record.data = dateToServer(record.data);
      record.inicio = record.inicio.toLocaleTimeString();
      record.fim = record.fim.toLocaleTimeString();
      record.alunos = record.alunos ? record.alunos.map(aluno => { return aluno.value }) : [];
      this.setState({ isLoading: true }, () => {
        api.post('/aulas', record)
          .then((response) => {
            if (response.data.message === 'ok') {
              toast.success('Aula salva com sucesso!');
              this.loadFromAPI();
              this.closeModal();
            } else {
              this.setState({
                isLoading: false,
                alert: (<SweetAlert warning title="Oops!" onConfirm={() => this.hideAlert()} showConfirm={false} timeout={2000}>{response.data.message}</SweetAlert>),
              });
            }
          })
          .catch((error) => {
            this.setState({ isLoading: false });
            toast.error('Falha ao enviar os dados!');
          });
      });
    }
  };

  render() {
    const { showModal, isLoading, readOnly, alert, record, errors } = this.state;

    return (
      <Fragment>
        {alert}
        <Modal show={showModal} onHide={this.closeModal}>
          <Modal.Header closeButton>
            <Modal.Title>{readOnly ? ('Detalhes') : (record.id ? 'Editar' : 'Adicionar')} aula</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="loading">
              <CSSTransition
                in={isLoading}
                timeout={500}
                classNames="fade"
                unmountOnExit
              >
                <div><Loading /></div>
              </CSSTransition>
              <Form id="form-aula" className="form-horizontal form-sm form-custom" onSubmit={this.handleSubmit} noValidate>
                <Form.Row>
                  <Form.Group as={Col} xs={4}>
                    <Form.Label>Data</Form.Label>
                    <div className={errors.data ? ' is-invalid' : ''}>
                      <DatePicker
                        name="data"
                        selected={record.data}
                        dateFormat="dd/MM/yyyy"
                        onChange={date => this.handleComponentChange('data', date)}
                        className={'form-control'}
                        showMonthDropdown
                        showYearDropdown
                        dropdownMode="select"
                        required
                      />
                    </div>
                    <Form.Control.Feedback type="invalid">{errors.data}</Form.Control.Feedback>
                  </Form.Group>
                  <Form.Group as={Col} xs={4} >
                    <Form.Label>Hora inicial</Form.Label>
                    <DatePicker
                      name="inicio"
                      selected={record.inicio}
                      onChange={date => this.handleComponentChange('inicio', date)}
                      className={'form-control' + (errors.inicio.length ? ' is-invalid' : '')}
                      required
                      showTimeSelect
                      showTimeSelectOnly
                      timeIntervals={5}
                      timeCaption="Hora"
                      timeFormat="HH:mm"
                      dateFormat="HH:mm"
                    />
                    <Form.Control.Feedback type="invalid">{errors.inicio}</Form.Control.Feedback>
                  </Form.Group>
                  <Form.Group as={Col} xs={4}>
                    <Form.Label>Hora final</Form.Label>
                    <DatePicker
                      name="fim"
                      selected={record.fim}
                      onChange={date => this.handleComponentChange('fim', date)}
                      className={'form-control' + (errors.fim.length ? ' is-invalid' : '')}
                      required
                      showTimeSelect
                      showTimeSelectOnly
                      timeIntervals={5}
                      timeCaption="Hora"
                      timeFormat="HH:mm"
                      dateFormat="HH:mm"
                    />
                    <Form.Control.Feedback type="invalid">{errors.fim}</Form.Control.Feedback>
                  </Form.Group>
                  <Form.Group as={Col} sm={6}>
                    <Form.Label>Unidade</Form.Label>
                    <SelectUnidades
                      name="idUnidade"
                      checkProfessor
                      idProfessor={record.idProfessor}
                      idUnidade={record.idUnidade}
                      onChange={this.handleComponentChange}
                      error={errors.idUnidade}
                      required
                    />
                    <Form.Control.Feedback type="invalid">{errors.idUnidade}</Form.Control.Feedback>
                  </Form.Group>
                  <Form.Group as={Col} sm={6}>
                    <Form.Label>Curso</Form.Label>
                    <SelectCursos
                      name="idCurso"
                      checkProfessor
                      idProfessor={record.idProfessor}
                      idCurso={record.idCurso}
                      onChange={this.handleComponentChange}
                      error={errors.idCurso}
                      required
                    />
                    <Form.Control.Feedback type="invalid">{errors.idCurso}</Form.Control.Feedback>
                  </Form.Group>
                  <Form.Group as={Col} sm={12}>
                    <Form.Label>Alunos</Form.Label>
                    <MultiSelectAlunos
                      name="alunos"
                      checkUnidade
                      idUnidade={record.idUnidade}
                      status="M"
                      alunos={record.alunos}
                      resetAlunosOnChangeUnidade
                      onChange={this.handleComponentChange}
                    />
                  </Form.Group>
                </Form.Row>
              </Form>
            </div>
          </Modal.Body>
          <Modal.Footer>
            {(!readOnly && record.id) && (
              <ButtonDelete
                id={record.id}
                label={"Aula dia " + record.inicio.toLocaleDateString() + ' às ' + record.inicio.toLocaleTimeString().slice(0, -3)}
                buttonLabel="Excluir aula"
                doDelete={this.doDelete}
              />
            )}
            <Button variant="outline-secondary" onClick={this.closeModal}>Fechar</Button>
            {!readOnly && <Button variant="success" form="form-aula" type="submit">Salvar</Button>}
          </Modal.Footer>
        </Modal>
      </Fragment >
    );
  }
}

export default FormAula;