import React, { Component } from "react";
import { Link, withRouter } from "react-router-dom";
import { Card, Image, Form, Button } from "react-bootstrap";
import { CSSTransition } from 'react-transition-group';
import SweetAlert from "react-bootstrap-sweetalert";
import { toast } from 'react-toastify';
import { Loading } from "../components";

import api from "../services/api";
import { setSession } from "../services/auth";
import { validEmailRegex } from "../utils/validation";
import logo from '../assets/img/logo-gaiteiros.png';

class LoginPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      alert: null,
      record: {
        email: '',
        senha: ''
      },
      formValid: false,
      errors: { email: '', senha: '' }
    }
  }

  validateInput = (name, value, errors) => {
    if (name === 'email') {
      if (!value.length) {
        errors.email = 'Preencha o campo EMAIL!';
      } else if (!validEmailRegex.test(value)) {
        errors.email = 'EMAIL inválido!'
      } else {
        errors.email = '';
      }
    } else if (name === 'senha') {
      if (!value.length || value.length < 3) {
        errors.senha = 'A SENHA deve ter no mínimo 5 caracteres!'
      } else {
        errors.senha = '';
      }
    }
    return errors;
  };

  validateForm = (record, errors) => {
    for (const key in record) {
      errors = this.validateInput(key, record[key], errors);
      if (errors[key]) return false;
    }
    return true;
  };

  handleInputChange = (event) => {
    event.preventDefault();
    const { name, value } = event.target;
    let record = Object.assign({}, this.state.record),
      errors = Object.assign({}, this.state.errors);
    record[name] = value;
    errors = this.validateInput(name, value, errors);
    this.setState({
      record: record,
      errors,
      formValid: this.validateForm(record, errors)
    });
  };

  showAlert = (type, message) => {
    this.setState({ alert: (<SweetAlert type={type} title="Oops!" onConfirm={() => this.hideAlert()}>{message}</SweetAlert>) });
  };

  hideAlert = () => {
    this.setState({ alert: null });
  };

  handleSubmit = (event) => {
    event.preventDefault();
    const { record, errors } = this.state;
    if (!this.validateForm(record, errors)) {
      this.showAlert('warning', 'Preenchimento incorreto!');
    } else {
      this.setState({ isLoading: true }, () => {
        api.post('/login', record)
          .then((response) => {
            const { token, expires, usuario } = response.data;
            if (token && expires && usuario) {
              setSession({
                token: token,
                expires: expires,
                usuario: usuario
              });
              toast.success('Autenticado com sucesso!');
              this.props.history.push("/app");
            } else {
              toast.error(response.data.message);
              this.setState({ isLoading: false });
            }
          })
          .catch((error) => {
            this.showAlert('danger', 'Ocorreu uma falha!');
            this.setState({ isLoading: false });
          })
      });
    }
  };

  verifyToken = () => {
    const now = new Date(),
      token = localStorage.getItem('token'),
      usuario = JSON.parse(localStorage.getItem('usuario')),
      expires = parseInt(localStorage.getItem('expires'));

    if (token) {
      if (expires > now.getTime()) {
        this.setState({ isLoading: true }, () => {
          api.post('/verifytoken', { token: token })
            .then((response) => {
              if (response.data.usuario) {
                setSession({
                  token: token,
                  expires: expires,
                  usuario: response.data.usuario
                });
                toast.success('Redirecionando...');
                this.props.history.push("/app");
              } else {
                this.setState({ isLoading: false });
              }
            })
            .catch((error) => {
              this.setState({ isLoading: false });
            })
            return;
        });
      }
    }

    if (usuario && usuario.email) {
      const record = Object.assign({}, this.state.record);
      record.email = usuario.email;
      this.setState({ record: record });
    }
  };

  componentDidMount = () => {
    this.verifyToken();
  };

  render() {
    const { isLoading, alert, record, errors } = this.state;

    return (
      <div className="login-wrapper text-center">
        <Card className="shadow">
          <Card.Body>
            <Image src={logo} fluid />
            <br />
            <br />
            <h3 className="mb-3">Login</h3>
            <div className="loading">
              <Form id="form-login" onSubmit={this.handleSubmit} noValidate>
                <Form.Control
                  type="text"
                  name="email"
                  placeholder="Email"
                  value={record.email}
                  onChange={this.handleInputChange}
                  onBlur={this.handleInputChange}
                  className={errors.email.length && ' is-invalid'}
                  bsPrefix="form-control top"
                  required
                />
                <Form.Control
                  type="password"
                  name="senha"
                  placeholder="Senha"
                  value={record.senha}
                  onChange={this.handleInputChange}
                  onBlur={this.handleInputChange}
                  className={errors.senha.length && ' is-invalid'}
                  bsPrefix="form-control middle"
                  required
                />
                {this.state.formValid ?
                  (
                    <Button
                      variant="primary"
                      size="lg"
                      block
                      type="submit"
                      className="bottom"
                    >
                      Entrar
                    </Button>
                  ) : (
                    <Button
                      variant="primary"
                      size="lg"
                      block
                      className="bottom"
                      disabled
                    >
                      Entrar
                    </Button>
                  )}
                <div className="text-center my-2">
                  <Link to="/recovery">Esqueci minha senha</Link>
                </div>
              </Form>
              <CSSTransition
                in={isLoading}
                timeout={500}
                classNames="fade"
                unmountOnExit
              >
                <div><Loading /></div>
              </CSSTransition>
            </div>
            {alert}
          </Card.Body>
        </Card>
      </div>
    );
  }
}

export default withRouter(LoginPage);
