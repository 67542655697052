import React, { Component } from 'react'
import Chart from 'chart.js';

let myChart;

class AlunosGeneroPieChart extends Component {

  componentDidMount = () => {
    this.buildChart();
  };

  componentDidUpdate = () => {
    this.buildChart();
  };

  buildChart = () => {
    let element = document.getElementById("piechart-alunos-genero"),
      myChartRef = element.getContext("2d");

    element.height = this.props.heigth;

    if (typeof myChart !== "undefined") myChart.destroy();

    const unidades = this.props.unidades.slice();
    let generos = [0, 0, 0, 0];

    unidades.forEach(unidade => {
      generos[0] += unidade.alunos.genero.masculino;
      generos[1] += unidade.alunos.genero.feminino;
      generos[2] += unidade.alunos.genero.outro;
      generos[3] += unidade.alunos.genero.prefiro_nao_dizer;
    });

    console.log('generos', generos)

    myChart = new Chart(myChartRef, {
      type: 'pie',
      data: {
        labels: ['Masculino', 'Feminino', 'Outro', 'Prefiro não dizer'],
        datasets: [
          {
            data: generos,
            backgroundColor: ['rgba(2, 117, 216, 0.5)', 'rgba(217, 83, 79, 0.5)', 'rgba(128, 0, 128, 0.5)', 'rgba(255, 99, 132, 0.5)'],
            datalabels: {
              color: 'rgba(0, 0, 0, 1)',
              anchor: 'end',
              align: 'start',
              formatter: function (value, context) {
                const total = context.dataset.data.reduce((a, b) => a + b, 0);
                return Math.round((value / total) * 100) + '%';
              }
            }
          }
        ]
      },
      options: {
        title: {
          display: true,
          text: 'Alunos / Gênero',
          fontSize: 14
        },
        legend: {
          position: 'right',
          labels: { padding: 5, boxWidth: 12 }
        },
        layout: {
          padding: {
            left: 0,
            right: 0,
            top: -10,
            bottom: 0
          }
        },
        maintainAspectRatio: false
      }
    });
  };

  render() {
    return (
      <canvas id="piechart-alunos-genero" />
    )
  }
}

export default AlunosGeneroPieChart;