import React, { Component } from "react";

class DiaQuadro extends Component {
  constructor(props) {
    super(props);
    this.openModalForm = props.openModalForm;
  }

  render() {
    const records = this.props.records,
      events = [];

    let pos = 420, // 7 horas da manhã
      key = 0;     // key para o elemento que será criado

    records.forEach((record) => {
      while (pos < record.inicio) { // preenche os horarios no intervalo anterior ao evento atual
        const event = Object.assign({}, this.props.emptyRecord);
        event.dia = this.props.weekDay;
        event.inicio = pos;

        let posAux = pos + 1;
        while (posAux % 60 && posAux < record.inicio) { posAux++; }
        const posDiff = posAux - pos;

        event.fim = event.inicio + posDiff;
        events.push(<div key={key} className="alert-event event-button" style={{ height: posDiff + 'px' }} onClick={() => this.openModalForm(event)}></div>);
        pos = posAux;
        key++;
      }

      const divHeight = Math.abs(Math.floor(record.fim - record.inicio));

      let eventClass = 'event-secondary',
        nivel = 'Nenhum aluno';

      if (record.nivel === 1) {
        eventClass = 'event-success';
        nivel = 'Iniciante';
      } else if (record.nivel === 2) {
        eventClass = 'event-warning';
        nivel = 'Intermediário';
      } else if (record.nivel === 3) {
        eventClass = 'event-primary';
        nivel = 'Avançado';
      } else if (record.nivel === 4) {
        eventClass = 'event-danger';
        nivel = 'Misto';
      }

      events.push(
        <div key={key} className={'alert-event ' + eventClass} style={{ height: divHeight + 'px' }} onClick={() => this.openModalForm(record)}>
          <span>{nivel}</span>
          <span className="float-right">{record.alunos ? record.alunos.length : '0'}</span>
        </div>
      )
        ;
      pos += divHeight;
      key++;
    });

    while (pos < 1320) {
      const event = Object.assign({}, this.props.emptyRecord);
      event.dia = this.props.weekDay;
      event.inicio = pos;
      let posAux = pos + 1;
      while (posAux % 60 && posAux < 1320) { posAux++; }
      const posDiff = posAux - pos;
      event.fim = event.inicio + posDiff;
      events.push(<div key={key} className="alert-event event-button" style={{ height: posDiff + 'px' }} onClick={() => this.openModalForm(event)}></div>);
      pos = posAux;
      key++;
    }

    return (
      <div>{events}</div>
    );
  }
}

export default DiaQuadro;