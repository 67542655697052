import React, { Component, Fragment } from "react";
import { Container, Row, Col, Table, ButtonGroup, Button, Alert } from "react-bootstrap";
import { CSSTransition } from 'react-transition-group';
import { toast } from 'react-toastify';
import DatePicker from "react-datepicker";

import { Loading, ButtonChamada } from "../../../../components";

import { monthNames, weekNames, daysInMonth, dateToServer } from "../../../../utils/dates";

import HeaderProfessor from "./HeaderProfessor";
import HeaderAdministrador from "./HeaderAdmin";

import api from "../../../../services/api";

class Chamada extends Component {
  usuario = JSON.parse(localStorage.getItem('usuario'));

  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      targetMonth: new Date(),
      idUnidade: (this.usuario && this.usuario.unidades && this.usuario.unidades.length === 1) ? this.usuario.unidades[0].id : '',
      idProfessor: (this.usuario && this.usuario.idProfessor) ? this.usuario.idProfessor : '',
      idCurso: (this.usuario && this.usuario.cursos && this.usuario.cursos.length === 1) ? this.usuario.cursos[0].id : '',
      records: [],
      totalRecords: 0
    };
  }

  loadFromAPI = () => {
    const { targetMonth, idUnidade, idProfessor, idCurso } = this.state;
    if (targetMonth && idUnidade && idProfessor && idCurso) {
      this.setState({ isLoading: true }, () => {
        api.get('/aulas/chamadas', {
          params: {
            mes: dateToServer(targetMonth),
            idProfessor: idProfessor,
            idUnidade: idUnidade,
            idCurso: idCurso,
          }
        }).then(response => {
          this.setState({
            records: response.data.chamada ? response.data.chamada : [],
            totalRecords: response.data.chamada ? response.data.chamada.length : 0,
            isLoading: false
          });
        }).catch(error => {
          this.setState({ isLoading: false });
          toast.error('Falha ao obter dados!');
        });
      });
    }
  };

  setCurrentMonth = () => {
    this.setMonth(new Date());
  };

  setNextMonth = () => {
    const targetMonth = this.state.targetMonth;
    targetMonth.setMonth(targetMonth.getMonth() + 1);
    this.setMonth(targetMonth);
  };

  setPrevMonth = () => {
    const targetMonth = this.state.targetMonth;
    targetMonth.setMonth(targetMonth.getMonth() - 1);
    this.setMonth(targetMonth);
  };

  setMonth = (value) => {
    this.setState({ targetMonth: value }, this.loadFromAPI());
  };

  handleComponentChange = (name, value) => {
    this.setState({ [name]: value }, () => this.loadFromAPI());
  };

  handlePresenca = (idAula, idAluno, presenca) => {
    const records = this.state.records;

    records.map(aluno => {
      if (aluno.id === idAluno) {
        aluno.aulas.map(aula => {
          if (aula.idAula === idAula) aula.presenca = presenca;
          return aula;
        });
      }
      return aluno;
    });
    this.setState({ redords: records });
  };

  componentDidMount = () => {
    this.loadFromAPI();
  };

  render() {
    const { isLoading, targetMonth, idUnidade, idProfessor, idCurso, records } = this.state;

    const year = targetMonth.getFullYear(),
      month = targetMonth.getMonth(),
      days = daysInMonth(year, month + 1),
      hoje = new Date();

    hoje.setHours(0, 0, 0, 0);

    const TableHead = () => {
      let items = [<th key={0}>ALUNOS</th>];
      for (let day = 1; day <= days; day++) {
        const date = new Date(year, month, day, 0, 0, 0, 0),
          weekDay = date.getDay();
        let bgClass = '';
        if (weekDay === 0 || weekDay === 6) bgClass = 'table-secondary';
        if (date.getTime() === hoje.getTime()) bgClass = 'table-danger table-today';
        items.push(<th key={day} className={bgClass}><small className="text-muted">{weekNames[weekDay]}</small><br />{day}</th>)
      }
      return items;
    };

    const TableBody = () => {
      let items = [];
      records.forEach((aluno) => {
        items.push(<tr key={aluno.id}>{TableRow(aluno)}</tr>);
        return true;
      });
      return items;
    };

    const TableRow = (aluno) => {
      let items = [<td key={0}>{aluno.nome}</td>];
      // Loop nos dias do mes
      for (let day = 1; day <= days; day++) {
        const date = new Date(year, month, day, 0, 0, 0, 0),
          weekDay = date.getDay();

        let content = '',
          bgClass = '';

        if (weekDay === 0 || weekDay === 6) bgClass = 'table-secondary';
        if (date.getTime() === hoje.getTime()) bgClass = 'table-danger table-today';

        // Loop nas aulas procurando a do dia
        for (let i = 0; i < aluno.aulas.length; i++) {
          const dataAula = new Date(aluno.aulas[i].inicio.replace(/Z$/, ''));
          if (dataAula.getDate() === date.getDate()) {
            if (aluno.aulas[i].presenca === 'S') bgClass += ' text-success'
            // if (this.usuario.idAdministrador || date.getTime() === hoje.getTime()) {
            //   content = <ButtonChamada
            //     idAula={aluno.aulas[i].idAula}
            //     idAluno={aluno.id}
            //     presenca={aluno.aulas[i].presenca}
            //     onClick={this.handlePresenca}
            //   />;
            // } else if (aluno.aulas[i].presenca === 'S') {
            //   content = <i className="fa fa-check"></i>;
            // } else {
            //   content = <i className="fa fa-times" style={{color: "red"}}></i>;
            // }
            content = <ButtonChamada
                idAula={aluno.aulas[i].idAula}
                idAluno={aluno.id}
                presenca={aluno.aulas[i].presenca}
                onClick={this.handlePresenca}
              />;
          }
        }

        items.push(<td key={day} className={bgClass}>{content}</td>)
      }
      return items;
    };

    return (
      <Container fluid>
        {this.usuario.idProfessor ? (
          <HeaderProfessor usuario={this.usuario} onChange={this.handleComponentChange} />
        ) : (
            <HeaderAdministrador usuario={this.usuario} onChange={this.handleComponentChange} />
          )}
        {!targetMonth && (<Alert variant="warning"><h5 className="text-center my-3">PERÍODO inválido!</h5></Alert>)}
        {!idUnidade && (<Alert variant="danger"><h5 className="text-center my-3">Selecione uma UNIDADE!</h5></Alert>)}
        {!idProfessor && (<Alert variant="danger"><h5 className="text-center my-3">Selecione um PROFESSOR!</h5></Alert>)}
        {!idCurso && (<Alert variant="danger"><h5 className="text-center my-3">Selecione um CURSO!</h5></Alert>)}
        {(targetMonth && idUnidade && idProfessor && idCurso) && (
          <Fragment>
            <Row>
              <Col sm={5} className="mt-3 mb-2 text-sm-left">
                <DatePicker
                  onChange={date => this.setMonth(date)}
                  showMonthYearPicker
                  customInput={<Button variant="primary"><i className="fa fa-calendar"></i></Button>}
                />
                &nbsp;
                &nbsp;
                <Button variant="outline-secondary" onClick={this.setCurrentMonth} title="Mês atual">Mês atual</Button>
                &nbsp;
                &nbsp;
                <ButtonGroup aria-label="Basic example">
                  <Button variant="outline-secondary" onClick={this.setPrevMonth} title="Semana anterior">
                    <i className="fa fa-chevron-left"></i>
                  </Button>
                  <Button variant="outline-secondary" onClick={this.setNextMonth} title="Próxima semana">
                    <i className="fa fa-chevron-right"></i>
                  </Button>
                </ButtonGroup>
              </Col>
              <Col sm={7} className="text-sm-right">
                <h1 className="mt-2 text-muted text-sm-right">{monthNames[targetMonth.getMonth()] + ' ' + targetMonth.getFullYear()}</h1>
              </Col>
            </Row>
            <div className="loading">
              <Table responsive bordered striped className="table-chamada">
                <thead>
                  <tr className="text-center">{TableHead()}</tr>
                </thead>
                <tbody>
                  {!records.length ? (<tr><td colSpan={days + 1} className="empty">Nenhum registro</td></tr>) : TableBody()}
                </tbody>
              </Table>
              <CSSTransition
                in={isLoading}
                timeout={500}
                classNames="fade"
                unmountOnExit
              >
                <div><Loading /></div>
              </CSSTransition>
            </div>
          </Fragment>
        )}
      </Container >
    );
  }
}

export default Chamada;