import React, { Component } from 'react'
import Chart from "chart.js";

let myChart;

//--Chart Style Options--//
// Chart.defaults.global.defaultFontFamily = "'PT Sans', sans-serif"
// Chart.defaults.global.legend.display = false;
//--Chart Style Options--//

class ChartJS extends Component {
  // chartRef = React.createRef();

  componentDidMount = () => {
    this.buildChart();
  }

  componentDidUpdate = () => {
    this.buildChart();
  }

  buildChart = () => {
    const myChartRef = document.getElementById(this.props.id).getContext("2d");

    if (typeof myChart !== "undefined") myChart.destroy();

    myChart = new Chart(myChartRef, {
      type: this.props.type,
      data: {
        labels: this.props.labels,
        datasets: this.props.datasets
      },
      options: this.props.options ? this.props.options : {}
    });
  }

  render() {
    return (
      <canvas id={this.props.id} />
    )
  }
}

export default ChartJS;