import React, { Component } from "react";
import { Link, withRouter } from "react-router-dom";
import { Card, Image, Form, Button, Alert } from "react-bootstrap";
import { CSSTransition } from 'react-transition-group';
import { toast } from 'react-toastify';
import SweetAlert from "react-bootstrap-sweetalert";

import { Loading } from "../components";

import api from "../services/api";
import { stringToObject } from '../utils/misc';
import logo from '../assets/img/logo-gaiteiros.png';

class ResetPage extends Component {
  constructor(props) {
    super(props);
    const params = this.props.location.search ? stringToObject(this.props.location.search) : {};
    this.state = {
      isLoading: false,
      alert: null,
      record: {
        token: params.token ? params.token : '',
        pass1: '',
        pass2: '',
        path: this.props.location.pathname,
      },
      formValid: false,
      errors: { pass1: '', pass2: '' }
    };
  }

  validateInput = (name, value, errors) => {
    if (name === 'pass1') {
      errors.pass1 = !value.length ? 'Preencha o campo SENHA!' : '';
    } else if (name === 'pass2') {
      errors.pass2 = !value.length ? 'Preencha o campo REPITA SENHA!' : '';
    }
    return errors;
  };

  validateForm = (record, errors) => {
    for (const key in record) {
      errors = this.validateInput(key, record[key], errors);
      if (errors[key]) return false;
    }
    return true;
  };

  handleInputChange = (event) => {
    event.preventDefault();
    const { name, value } = event.target;
    let record = Object.assign({}, this.state.record),
      errors = Object.assign({}, this.state.errors);

    record[name] = value;
    errors = this.validateInput(name, value, errors);
    this.setState({
      record: record,
      errors,
      formValid: this.validateForm(record, errors)
    });
  };

  showAlert = (type, message) => {
    this.setState({
      alert: (<SweetAlert type={type} title="Oops!" onConfirm={() => this.hideAlert()} showConfirm={false} timeout={2000}>{message}</SweetAlert>)
    });
  };

  hideAlert = () => {
    this.setState({ alert: null });
  };

  handleSubmit = (event) => {
    event.preventDefault();
    const { record, errors } = this.state;
    if (!this.validateForm(record, errors)) {
      this.showAlert('warning', 'Preenchimento incorreto!');
    } else {
      this.setState({ isLoading: true }, () => {
        api.post('/reset', record)
          .then((response) => {
            if (response.data.message === 'ok') {
              toast.success('Senha salva com sucesso!');
              this.props.history.push("/login");
            } else {
              toast.error(response.data.message);
              this.setState({ isLoading: false });
            }
          })
          .catch((error) => {
            toast.error('Ocorreu uma falha!');
            this.setState({ isLoading: false });
          })
      });
    }
  };

  render() {
    const { isLoading, alert, record, formValid, errors } = this.state;

    return (
      <div className="login-wrapper text-center">
        {alert}
        <Card className="shadow">
          <Card.Body>
            <Image src={logo} fluid />
            <br />
            <br />
            <h4 className="mb-3">{record.path === '/ativacao' ? 'Ativação de conta' : 'Recuperação de senha'}</h4>
            {record.token ? (
              <div className="loading">
                <Form onSubmit={this.handleSubmit} noValidate>
                  <Form.Control
                    type="password"
                    name="pass1"
                    placeholder="Nova senha"
                    value={record.pass1}
                    onChange={this.handleInputChange}
                    onBlur={this.handleInputChange}
                    className={errors.pass1.length && 'is-invalid'}
                    bsPrefix="form-control top"
                    required
                  />
                  <Form.Control
                    type="password"
                    name="pass2"
                    placeholder="Repita a nova senha"
                    value={record.pass2}
                    onChange={this.handleInputChange}
                    onBlur={this.handleInputChange}
                    className={errors.pass2.length && 'is-invalid'}
                    bsPrefix="form-control middle"
                    required
                  />
                  {formValid ?
                    (
                      <Button
                        variant="primary"
                        size="lg"
                        block
                        type="submit"
                        className="bottom"
                      >
                        Salvar nova senha
                      </Button>
                    ) : (
                      <Button
                        variant="primary"
                        size="lg"
                        block
                        className="bottom"
                        disabled
                      >
                        Salvar nova senha
                      </Button>
                    )}
                </Form>
                <CSSTransition
                  in={isLoading}
                  timeout={500}
                  classNames="fade"
                  unmountOnExit
                >
                  <div><Loading /></div>
                </CSSTransition>
              </div>
            ) : (
                <Alert variant="danger">
                  <Alert.Heading>TOKEN INVÁLIDO!</Alert.Heading>
                  {record.path === '/ativacao' ? (
                    'Entre em contato com o administrador e solicite um novo TOKEN DE ATIVAÇÃO.'
                  ) : (
                      <div>Repita o processo de<br />
                        <Alert.Link as={Link} to="/recovery">RECUPERAÇÃO DE SENHA</Alert.Link>
                      </div>
                    )}
                </Alert>
              )}
          </Card.Body>
        </Card>
      </div>
    );
  }
}

export default withRouter(ResetPage);
