import React, { Component, memo } from "react";

import api from "../services/api";

class TypeaheadColegios extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showDropdown: false,
      isLoading: false,
      options: [],
      value: '',
      error: ''
    }
    this.onChange = props.onChange
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.colegio !== prevState.value
      || nextProps.error !== prevState.error) {
      return {
        value: nextProps.colegio,
        error: nextProps.error
      };
    }
    return null;
  }

  handleChange = (event) => {
    event.preventDefault();
    const value = event.target.value;
    if (this.state.value !== value) {
      this.setState({ value: value }, this.onChange(this.props.name, value));
      if (value.length >= 3) {
        this.loadColegios(value);
      } else {
        this.setState({ showDropdown: false });
      }
    }
  };

  setValue = (value) => {
    this.setState({
      value: value,
      showDropdown: false
    }, this.onChange(this.props.name, value));
  };

  loadColegios = (value) => {
    this.setState({ isLoading: true });
    api.get('/colegios', { params: { colegio: value, limit: 8 } })
      .then(response => {
        this.setState({
          showDropdown: true,
          isLoading: false,
          options: response.data.colegios
        });
      })
      .catch(error => {
        this.setState({ isLoading: false });
      });
  };

  render() {
    const { showDropdown, options, value, error } = this.state;

    const highlightText = (text, string, key) => {
      const textCopy = text;
      text = text.toLowerCase();
      string = string.toLowerCase();
      const index = text.indexOf(string);
      if (index < 0) return string;
      const array = [];
      if (index > 0) array.push(textCopy.substring(0, index));
      array.push(<b key={key}>{textCopy.substr(index, string.length)}</b>);
      array.push(textCopy.substr(index + string.length));
      return array;
    };

    return (
      <div className="typeahead">
        <input
          type="text"
          value={value}
          onChange={this.handleChange}
          onBlur={this.handleChange}
          className={'form-control' + (error ? ' is-invalid' : '')}
          required={this.props.required}
        />
        {
          (showDropdown && options.length) ? (
            <ul>
              {
                options.map((item, key) => (
                  <li key={key} onClick={() => this.setValue(item.colegio)}>
                    {highlightText(item.colegio, value, key)}
                  </li>
                ))
              }
            </ul>
          ) : null
        }
      </div>
    )
  }
}

export default memo(TypeaheadColegios);
