import React, { Component, Fragment } from "react";
import { Modal, Form, Row, Col, Button } from "react-bootstrap";
import { CSSTransition } from 'react-transition-group';
import { toast } from 'react-toastify';

import { Loading } from "../components";

import api from "../services/api";

const resetErrors = { pass: '', pass1: '', pass2: '' };

class ModalSenha extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showModal: false,
      isLoading: false,
      pass: '',
      pass1: '',
      pass2: '',
      errors: resetErrors,
      formValid: false
    };
  }

  openModal = () => {
    this.setState({
      showModal: true
    });
  };

  closeModal = () => {
    this.setState({
      showModal: false,
      isLoading: false,
      pass: '',
      pass1: '',
      pass2: '',
      errors: resetErrors,
      formValid: false
    });
  };

  validateInput = (name, value, errors) => {
    if (name === 'pass') {
      errors.pass = !value.length ? 'Digite sua senha atual' : '';
    } else if (name === 'pass1') {
      errors.pass1 = !value.length ? 'Digite a nova senha' : '';
    } else if (name === 'pass2') {
      errors.pass2 = !value.length ? 'Repita a nova senha' : '';
    }
    return errors;
  };

  validateForm = (record, errors) => {
    for (const key in record) {
      errors = this.validateInput(key, record[key], errors);
      if (errors[key]) return false;
    }
    return true;
  };

  handleInputChange = (event) => {
    event.preventDefault();
    const { name, value } = event.target;
    const { pass, pass1, pass2 } = this.state;
    const record = { pass: pass, pass1: pass1, pass2: pass2 };
    let errors = Object.assign({}, this.state.errors);
    errors = this.validateInput(name, value, errors);
    this.setState({
      [name]: value,
      errors,
      formValid: this.validateForm(record, errors)
    });
  };

  handleSubmit = (event) => {
    event.preventDefault();
    if (!this.state.formValid) {
      toast.error('Preenchimento incorreto!');
    } else {
      this.setState({ isLoading: true }, () => {
        const { pass, pass1, pass2 } = this.state;
        api.post('/usuarios/senha', { pass: pass, pass1: pass1, pass2: pass2 })
          .then((response) => {
            if (response.data.message === 'ok') {
              toast.success('Senha atualizada com sucesso!');
              this.closeModal();
            } else {
              toast.error(response.data.message);
              this.setState({ isLoading: false });
            }
          })
          .catch((error) => {
            toast.error('Falha ao atualizar senha!');
            this.setState({ isLoading: false });
          });
      });
    }
  };

  render() {
    const { showModal, isLoading, pass, pass1, pass2, errors } = this.state;

    return (
      <Fragment>
        <div onClick={this.openModal}>Alterar senha</div>
        <Modal show={showModal} onHide={this.closeModal}>
          <Modal.Header closeButton>
            <Modal.Title>Alterar senha</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="loading">
              <Form className="form-sm form-custom">
                <CSSTransition
                  in={isLoading}
                  timeout={500}
                  classNames="fade"
                  unmountOnExit
                >
                  <div><Loading /></div>
                </CSSTransition>
                <Form.Group as={Row}>
                  <Form.Label column sm="4">Senha atual</Form.Label>
                  <Col sm="8">
                    <Form.Control
                      type="password"
                      name="pass"
                      value={pass}
                      onChange={this.handleInputChange}
                      onBlur={this.handleInputChange}
                      className={errors.pass.length && 'is-invalid'}
                      required
                      noValidate
                    />
                  </Col>
                </Form.Group>
                <Form.Group as={Row}>
                  <Form.Label column sm="4">Nova senha</Form.Label>
                  <Col sm="8">
                    <Form.Control
                      type="password"
                      name="pass1"
                      value={pass1}
                      onChange={this.handleInputChange}
                      onBlur={this.handleInputChange}
                      className={errors.pass1.length && 'is-invalid'}
                      required
                      noValidate
                    />
                  </Col>
                </Form.Group>
                <Form.Group as={Row} className="mb-0">
                  <Form.Label column sm="4">Repita a nova senha</Form.Label>
                  <Col sm="8">
                    <Form.Control
                      type="password"
                      name="pass2"
                      value={pass2}
                      onChange={this.handleInputChange}
                      onBlur={this.handleInputChange}
                      className={errors.pass2.length && 'is-invalid'}
                      required
                      noValidate
                    />
                  </Col>
                </Form.Group>
              </Form>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="outline-secondary" onClick={this.closeModal}>Cancelar</Button>
            {this.state.formValid ?
              (<Button variant="success" onClick={this.handleSubmit}>Salvar</Button>) :
              (<Button variant="success" disabled >Salvar</Button>)}
          </Modal.Footer>
        </Modal>
      </Fragment>
    );
  }
}

export default ModalSenha;