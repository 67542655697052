import React, { Component, memo } from "react";
import { toast } from 'react-toastify';
import Select from 'react-select';
import Qs from 'qs';

import { isEqualArrays } from "../../utils/misc";

import api from "../../services/api";

class MultiSelectAlunos extends Component {
  _isMounted = false;

  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      options: [],
      selected: props.alunos,
      disabled: props.disabled
    };
    this.onChange = props.onChange;
  }

  loadAlunos = (params = {}, upState = false) => {
    api.get('/alunos', {
      params: params,
      paramsSerializer: (params) => { return Qs.stringify(params, { arrayFormat: 'repeat' }) }
    })
      .then(response => {
        if (this._isMounted) {
          console.log(response.data);
          if (response.data.message) toast.error(response.data.message);
          const options = response.data.alunos.map(a => { return { value: a.id, label: a.nome } });
          if (upState && this.props.resetAlunosOnChangeUnidade) {
            this.setState({
              options: options,
              selected: null,
              disabled: false,
            }, this.onChange(this.props.name, []));
          } else {
            this.setState({ options: options, disabled: false, });
          }
        }
      })
      .catch(error => {
        toast.error(error.message);
      });
  };

  handleChange = (selected) => {
    this.setState({ selected }, this.onChange(this.props.name, selected));
  };

  componentDidMount = () => {
    const { checkUnidade, idUnidade, unidades, status } = this.props;
    this._isMounted = true;

    if (idUnidade && unidades === undefined) {
      let params = { idUnidade: idUnidade };
      if (status) params.status = status;
      this.loadAlunos(params);
    } else if (unidades && unidades.length && idUnidade === undefined) {
      let params = { unidades: unidades };
      if (status) params.status = status;
      this.loadAlunos(params);
    } else if (checkUnidade) {
      this.setState({ disabled: true });
    } else {
      this.loadAlunos(status ? { status } : {});
    }
  };

  componentDidUpdate = (prevProps) => {
    const { checkUnidade, idUnidade, unidades, status } = this.props;

    if (unidades === undefined && idUnidade !== prevProps.idUnidade) {
      if (idUnidade) {
        let params = { idUnidade: idUnidade };
        if (status) params.status = status;
        this.loadAlunos(params, true);
      } else if (checkUnidade) {
        this.setState({
          options: [],
          selected: null,
          disabled: true
        });
      }
    } else if (idUnidade === undefined && !isEqualArrays(unidades, prevProps.unidades)) {
      if (unidades.length) {
        let params = { unidades: unidades };
        if (status) params.status = status;
        this.loadAlunos(params, true);
      } else if (checkUnidade) {
        this.setState({
          options: [],
          selected: null,
          disabled: true
        });
      } else {
        let params = status ? { status: status } : {};
        this.loadAlunos(params, true);
      }
    }
  };

  componentWillUnmount = () => {
    this._isMounted = false;
  };

  render() {
    const { isLoading, options, selected, disabled } = this.state;

    return (
      <Select
        name={this.props.name}
        isLoading={isLoading}
        value={selected}
        onChange={this.handleChange}
        options={options}
        isMulti
        closeMenuOnSelect={false}
        isDisabled={disabled}
        placeholder="Selecione os alunos"
        noOptionsMessage={() => null}
      />
    )
  }
}

export default memo(MultiSelectAlunos);
