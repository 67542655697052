import React, { Component } from 'react'
import Chart from 'chart.js';

let myChart;

class InscricoesTurnoPieChart extends Component {

  componentDidMount = () => {
    this.buildChart();
  };

  componentDidUpdate = () => {
    this.buildChart();
  };

  buildChart = () => {
    let element = document.getElementById("piechart-inscr-turnos"),
      myChartRef = element.getContext("2d");

    element.height = this.props.heigth;

    if (typeof myChart !== "undefined") myChart.destroy();

    const unidades = this.props.unidades.slice();
    let labels = [],
      turnos = [],
      colors = [],
      manha = 0,
      tarde = 0,
      noite = 0;

    unidades.forEach(unidade => {
      manha += unidade.inscricoes.turno.manha;
      tarde += unidade.inscricoes.turno.tarde;
      noite += unidade.inscricoes.turno.noite;
    });

    if (manha) {
      labels.push('Manhã');
      turnos.push(manha);
      colors.push('#5ac8fa');
    }
    if (tarde) {
      labels.push('Tarde');
      turnos.push(tarde);
      colors.push('#007aff');
    }
    if (noite) {
      labels.push('Noite');
      turnos.push(noite);
      colors.push('#5856d6');
    }

    myChart = new Chart(myChartRef, {
      type: 'pie',
      data: {
        labels: labels,
        datasets: [
          {
            data: turnos,
            backgroundColor: colors,
            datalabels: {
              color: 'rgba(0, 0, 0, 1)',
              anchor: 'end',
              align: 'start',
              formatter: function (value, context) {
                const total = context.dataset.data.reduce((a, b) => a + b, 0);
                return Math.round((value / total) * 100) + '%';
              }
            }
          }
        ]
      },
      options: {
        title: {
          display: true,
          text: 'Inscrições / Turno Escolar',
          fontSize: 14
        },
        legend: {
          position: 'right',
          labels: { padding: 5, boxWidth: 12 }
        },
        layout: {
          padding: {
            left: 0,
            right: 0,
            top: -10,
            bottom: 0
          }
        },
        maintainAspectRatio: false
      }
    });
  };

  render() {
    return (
      <canvas id="piechart-inscr-turnos" />
    )
  }
}

export default InscricoesTurnoPieChart;