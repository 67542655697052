import React, { Component, Fragment } from "react";
import { Modal, Form, Col, Button, Image } from "react-bootstrap";
import { CSSTransition } from 'react-transition-group';
import { toast } from 'react-toastify';
import SweetAlert from "react-bootstrap-sweetalert";
import Camera from 'react-html5-camera-photo';
import 'react-html5-camera-photo/build/css/index.css';
import { Loading } from "../../../components";
import { dataURItoBlob } from "../../../utils/camera";

import api from "../../../services/api";

class FormFoto extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showModal: false,
      isLoading: false,
      showCamera: false,
      alert: null,
      id: props.record.id,
      imagem: props.record.imagem,
      legenda: props.record.legenda,
      file: null
    };
    this.loadFromAPI = props.loadFromAPI;
    this.updateRecords = props.updateRecords;
    this.closeModal = props.closeModal;
  }

  static getDerivedStateFromProps(props, state) {
    if (props.showModal !== state.showModal) {
      return {
        showModal: props.showModal,
        id: props.record.id ? props.record.id : '',
        imagem: props.record.imagem ? props.record.imagem : '',
        legenda: props.record.legenda ? props.record.legenda : ''
      };
    }
    return null;
  }

  resetAndCloseModal = (id, imagem, legenda) => {
    this.setState({
      showModal: false,
      isLoading: false,
      showCamera: false,
      id: '',
      imagem: '',
      legenda: '',
      file: null
    }, () => this.closeModal(id, imagem, legenda));
  };

  handleLegendaChange = (event) => {
    event.preventDefault();
    this.setState({ legenda: event.target.value });
  };

  handleFileChange = (event) => {
    this.setState({ file: event.target.files[0] });
  };

  hideAlert = () => {
    this.setState({ alert: null });
  };

  handleSubmit = (event) => {
    event.preventDefault();
    const { id, legenda, file } = this.state;
    let form = new FormData();
    form.append('id', id);
    form.append('legenda', legenda);
    form.append('file', file);
    this.setState({ isLoading: true }, () => {
      api.post('/atividades/imagem', form)
        .then((response) => {
          if (response.data.message === 'ok') {
            toast.success('Foto salva com sucesso!');
            this.resetAndCloseModal(id, response.data.imagem, legenda);
          } else {
            this.setState({
              isLoading: false,
              alert: (<SweetAlert warning title="Oops!" onConfirm={() => this.hideAlert()} showConfirm={false} timeout={2000}>{response.data.message}</SweetAlert>),
            });
          }
        })
        .catch((error) => {
          this.setState({ isLoading: false });
          toast.error('Falha ao enviar os dados!');
        });
    });
  };

  startCamera = () => {
    this.setState({ showCamera: true });
  };

  stopCamera = () => {
    this.setState({ showCamera: false });
  };

  takePhoto = (dataUri) => {
    this.setState({
      showCamera: false,
      file: dataURItoBlob(dataUri)
    });
  };

  deletePhoto = () => {
    if (this.state.imagem) {
      this.setState({
        alert: (
          <SweetAlert
            warning
            title="Tem certeza?"
            onConfirm={() => this.deleteFromS3()}
            onCancel={this.hideAlert}
            confirmBtnBsStyle="danger"
            cancelBtnBsStyle="secondary"
            confirmBtnText="Sim, excluir!"
            cancelBtnText="Cancelar"
            showCancel
          >
            Confirma a exclusao da foto ?!
          </SweetAlert>
        )
      });
    } else {
      this.setState({
        showCamera: false,
        imagem: '',
        file: null
      });
    }

  };

  deleteFromS3 = () => {
    this.setState({
      isLoading: true,
      alert: null
    }, () => {
      const id = this.state.id;
      api.delete('/atividades/imagem', { data: { id: id } })
        .then((response) => {
          if (response.data.message === 'ok') {
            toast.success('Foto excluida com sucesso!');
            this.setState({
              isLoading: false,
              imagem: '',
              legenda: ''
            }, () => { this.updateRecords(id, '', ''); });
          } else {
            toast.error(response.data.message);
            this.setState({ isLoading: false });
          }
        })
        .catch((error) => {
          toast.error('Ocorreu uma falha ao excluir a foto!');
          this.setState({ isLoading: false });
        });
    });
  };

  render() {
    const { showModal, showCamera, isLoading, alert, imagem, legenda, file } = this.state;

    return (
      <Fragment>
        {alert}
        <Modal
          show={showModal}
          onHide={() => this.resetAndCloseModal()}
          dialogClassName="modal-90w"
        >
          <Modal.Header closeButton>
            <Modal.Title>Adicionar imagem</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="loading">
              <CSSTransition
                in={isLoading}
                timeout={500}
                classNames="fade"
                unmountOnExit
              >
                <div><Loading /></div>
              </CSSTransition>
              <Form id="form-imagem" className="form-horizontal form-sm form-custom" onSubmit={this.handleSubmit} noValidate>
                {imagem || file ? (
                  <Fragment>
                    <div className="wrapper-foto">
                      <div className="foto-container foto-box">
                        <Image src={imagem ? `${this.props.s3Location}/${imagem}` : URL.createObjectURL(file)} rounded fluid />
                        <Button
                          size="sm"
                          block
                          variant="danger"
                          onClick={() => this.deletePhoto()}
                        >
                          {imagem ? 'Excluir imagem' : 'Descartar imagem'}
                        </Button>
                      </div>
                    </div>
                    <Form.Row>
                      <Form.Group as={Col} xs={12}>
                        <Form.Label>Legenda</Form.Label>
                        <Form.Control
                          type="text"
                          name="legenda"
                          value={legenda}
                          onChange={this.handleLegendaChange}
                        />
                      </Form.Group>
                    </Form.Row>
                  </Fragment>
                ) : (
                    showCamera ? (
                      <Camera
                        imageType="jpg"
                        isMaxResolution={true}
                        isImageMirror={false}
                        onTakePhotoAnimationDone={this.takePhoto}
                      />
                    ) : (
                        <div className="frame-container foto-box foto-large">
                          <div className="foto-content">
                            <Form.Row>
                              <Form.Group as={Col}
                                xs={{ span: 10, offset: 1 }}
                                md={{ span: 6, offset: 3 }}
                              >
                                <Form.File
                                  id="custom-file"
                                  label="Selecione uma foto do seu dispositivo"
                                  custom
                                  onChange={(event) => this.handleFileChange(event)}
                                />
                                <Button
                                  variant="secondary"
                                  block
                                  onClick={() => this.startCamera()}
                                  className="mt-3"
                                >
                                  Fotografar com a câmera
                                </Button>
                              </Form.Group>
                            </Form.Row>
                          </div>
                        </div>
                      )
                  )}
              </Form>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="outline-secondary" disabled={isLoading || !file} onClick={() => this.resetAndCloseModal()}>Cancelar</Button>
            <Button variant="success" disabled={isLoading || !file} form="form-imagem" type="submit">Salvar</Button>
          </Modal.Footer>
        </Modal>
      </Fragment >
    );
  }
}

export default FormFoto;