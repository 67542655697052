import React, { Component, Fragment } from "react";
import { Container, Row, Col, Table, InputGroup, ButtonGroup, Button, Alert } from "react-bootstrap";
import { CSSTransition } from 'react-transition-group';
import { toast } from 'react-toastify';
import DatePicker from "react-datepicker";
import SweetAlert from "react-bootstrap-sweetalert";

import { Loading, SelectProfessores } from "../../../../components";
import FormAula from "./FormAgenda";
import Dia from "./DiaMes";

import { monthNames, weekNames, daysInMonth, dateToServer } from "../../../../utils/dates";

import api from "../../../../services/api";

const emptyRecord = {
  id: '',
  idUnidade: '',
  idCurso: '',
  idProfessor: '',
  nivel: '',
  data: '',
  inicio: '',
  fim: '',
  presenca: '',
  alunos: []
};

class Agenda extends Component {
  usuario = JSON.parse(localStorage.getItem('usuario'));

  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      showModal: false,
      alert: null,
      targetMonth: new Date(),
      idProfessor: (this.usuario && this.usuario.idProfessor) ? this.usuario.idProfessor : '',
      records: [],
      totalRecords: 0,
      record: emptyRecord
    };
  }

  loadFromAPI = () => {
    if (!this.state.targetMonth) {
      toast.error('Período inválido!');
    } else if (this.state.idProfessor) {
      this.setState({ isLoading: true }, () => {
        api.get('/aulas', {
          params: {
            mes: dateToServer(this.state.targetMonth),
            idProfessor: this.state.idProfessor
          }
        })
          .then(response => {
            this.setState({
              records: response.data.aulas ? response.data.aulas : [],
              totalRecords: response.data.aulas ? response.data.aulas.length : 0,
              isLoading: false
            });
          })
          .catch(error => {
            this.setState({ isLoading: false });
            toast.error('Falha ao obter dados!');
          });
      });
    }
  };

  doDelete = (id) => {
    api.delete('/aulas', { data: { id: id } })
      .then((response) => {
        if (response.data.message === 'ok') {
          toast.success('Aula excluida com sucesso!');
          const records = this.state.records.filter(r => { return parseInt(r.id) !== parseInt(id) });
          this.setState({
            showModal: false,
            records: records,
            record: emptyRecord,
          });
        } else {
          toast.error(response.data.message);
        }
      })
      .catch((error) => {
        toast.error('Ocorreu uma falha ao excluir!');
      })
  };

  copiaQuadro = (targetWeek) => {
    const data = { idProfessor: this.state.idProfessor, targetWeek: dateToServer(targetWeek) };
    api.post('/aulas/preencher', data)
      .then((response) => {
        if (response.data.message === 'ok') {
          toast.success('Semana preenchida com sucesso!');
          this.loadFromAPI();
          this.hideAlert();
        } else {
          toast.error(response.data.message);
        }
      })
      .catch((error) => {
        toast.error('Ocorreu uma falha ao preencher a semana!');
      })
  };

  handleQuadro = (targetWeek) => {
    this.setState({
      alert: (
        <SweetAlert
          warning
          title="Copiar do quadro?"
          onConfirm={() => this.copiaQuadro(targetWeek)}
          onCancel={this.hideAlert}
          confirmBtnBsStyle="danger"
          cancelBtnBsStyle="secondary"
          confirmBtnText="Sim, preencher!"
          cancelBtnText="Cancelar"
          showCancel
        >
          Confirma o preenchimento da semana selecionada com as aulas do <b>Quadro Semanal</b> ?!<br /><br />
          <b>ATENÇÃO!!!</b><br />
          Todas as aulas já registradas<br />na semana serão perdidas!
        </SweetAlert>
      )
    });
  };

  hideAlert = () => {
    this.setState({ alert: null });
  };

  openModalForm = (record) => {
    this.setState({
      record: record,
      showModal: true
    })
  };

  closeModalForm = () => {
    this.setState({
      showModal: false,
      record: emptyRecord,
    });
  };

  setCurrentMonth = () => {
    this.setMonth(new Date());
  };

  setNextMonth = () => {
    const targetMonth = this.state.targetMonth;
    targetMonth.setMonth(targetMonth.getMonth() + 1);
    this.setMonth(targetMonth);
  };

  setPrevMonth = () => {
    const targetMonth = this.state.targetMonth;
    targetMonth.setMonth(targetMonth.getMonth() - 1);
    this.setMonth(targetMonth);
  };

  setMonth = (value) => {
    this.setState({ targetMonth: value }, this.loadFromAPI());
  };

  handleProfessorChange = (name, value) => {
    this.setState({ idProfessor: value }, () => this.loadFromAPI());
  };

  filterRecords = (records, monthDay) => {
    return records.filter(record => {
      if (!record.inicio) return false;
      const inicio = new Date(record.inicio.replace(/Z$/, ''));
      if (!inicio instanceof Date) return false;
      return inicio.getDate() === monthDay;
    });
  };

  componentDidMount = () => {
    this.loadFromAPI();
  };

  render() {
    const { isLoading, showModal, targetMonth, idProfessor, records, record } = this.state,
      hours = [8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21];

    const year = targetMonth.getFullYear(),
      month = targetMonth.getMonth(),
      days = daysInMonth(year, month + 1),
      hoje = new Date();

    hoje.setHours(0, 0, 0, 0);

    const botaoModelo = (targetWeek) => <Button
      variant="outline-primary"
      size="sm"
      block
      onClick={() => this.handleQuadro(targetWeek)}
    >Copiar do quadro</Button>;

    const TableHead = () => {
      let items = [<th key={0}></th>];
      for (let day = 1; day <= days; day++) {
        const date = new Date(year, month, day, 0, 0, 0, 0),
          weekDay = date.getDay();
        let bgClass = '';
        if (weekDay === 0 || weekDay === 6) bgClass = 'bg-th-weekend';
        if (date.getTime() === hoje.getTime()) bgClass = 'table-danger table-today';
        items.push(<th key={day} className={bgClass}><small className="text-muted">{weekNames[weekDay].substr(0, 3)}</small><br />{day}</th>)
      }
      return items;
    };

    const TableBody = () => {
      let mes = [];

      for (let index = 1; index <= days; index++) {

        mes.push(<td key={index}>
          <Dia monthDay={index}
            targetMonth={targetMonth}
            records={this.filterRecords(records, index)}
            emptyRecord={emptyRecord}
            openModalForm={(value) => this.openModalForm(value)}
          />
        </td>);

      }
      return mes;
    };

    const TableFoot = () => {
      let items = [<th key={0}></th>],
        date,
        cols = 1;

      for (let day = 1; day <= days; day++) {
        date = new Date(year, month, day, 0, 0, 0, 0);

        if (date.getDay() !== 6) {
          cols++;
          continue;
        }

        items.push(<th key={day} colSpan={cols}>{botaoModelo(date)}</th>);

        // if (cols > 3) items.push(<th key={day} colSpan={cols}>{botaoModelo(date)}</th>);
        // else if (cols > 1) items.push(<th key={day} colSpan={cols}></th>);
        // else items.push(<th key={day}></th>);
        cols = 1;
      }

      date = new Date(year, month, days, 0, 0, 0, 0);
      if (cols > 1) items.push(<th key={days + 1} colSpan={cols - 1}>{botaoModelo(date)}</th>);
      // if (cols > 4) items.push(<th key={days + 1} colSpan={cols - 1}>{botaoModelo(date)}</th>);
      // else if (cols > 1) items.push(<th key={days + 1} colSpan={cols - 1}></th>);

      return items;
    };

    return (
      <Container fluid>
        <Row noGutters className="mb-3">
          {(this.usuario && this.usuario.idProfessor) ? (
            <Col sm={12}><h1 className="page-title">Agenda</h1></Col>
          ) : (
              <Fragment>
                <Col sm={6} md={7} lg={8}><h1 className="page-title">Agenda</h1></Col>
                <Col sm={6} md={5} lg={4} className="pt-3 pl-sm-3">
                  <InputGroup size="sm">
                    <InputGroup.Prepend>
                      <InputGroup.Text>Professor</InputGroup.Text>
                    </InputGroup.Prepend>
                    <SelectProfessores
                      name="idProfessor"
                      idProfessor={idProfessor}
                      onChange={this.handleProfessorChange}
                      appendUnidade={true}
                    />
                  </InputGroup>
                </Col>
              </Fragment>
            )}
        </Row >
        {!targetMonth && (<Alert variant="warning"><h5 className="text-center my-3">Período inválido!</h5></Alert>)}
        {!idProfessor && (<Alert variant="danger"><h5 className="text-center my-3">Selecione um professor!</h5></Alert>)}
        {(idProfessor && targetMonth) && (
          <Fragment>
            <Row>
              <Col sm={5} className="mt-3 mb-2 text-sm-left">
                <DatePicker
                  onChange={date => this.setMonth(date)}
                  showMonthYearPicker
                  customInput={<Button variant="primary"><i className="fa fa-calendar"></i></Button>}
                />
              &nbsp;
              &nbsp;
              <Button variant="outline-secondary" onClick={this.setCurrentMonth} title="Mês atual">Mês atual</Button>
              &nbsp;
              &nbsp;
              <ButtonGroup aria-label="Basic example">
                  <Button variant="outline-secondary" onClick={this.setPrevMonth} title="Semana anterior">
                    <i className="fa fa-chevron-left"></i>
                  </Button>
                  <Button variant="outline-secondary" onClick={this.setNextMonth} title="Próxima semana">
                    <i className="fa fa-chevron-right"></i>
                  </Button>
                </ButtonGroup>
              </Col>
              <Col sm={7} className="text-sm-right">
                <h1 className="mt-2 text-muted text-sm-right">{monthNames[targetMonth.getMonth()] + ' ' + targetMonth.getFullYear()}</h1>
              </Col>
            </Row>
            <div className="loading">
              <Table responsive bordered striped className="table-month">
                <thead>
                  <tr className="text-center">{TableHead()}</tr>
                </thead>
                <tbody>
                  <tr>
                    <td>{hours.map((value, key) => { return (<p key={key}>{value}:00</p>) })}</td>
                    {TableBody()}
                  </tr>
                </tbody>
                <tfoot>
                  <tr>{TableFoot()}</tr>
                </tfoot>
              </Table>
              <CSSTransition
                in={isLoading}
                timeout={500}
                classNames="fade"
                unmountOnExit
              >
                <div><Loading /></div>
              </CSSTransition>
            </div>
          </Fragment>
        )}
        <br />
        <FormAula
          usuario={this.usuario}
          showModal={showModal}
          closeModalForm={this.closeModalForm}
          idProfessor={idProfessor}
          emptyRecord={emptyRecord}
          record={record}
          loadFromAPI={this.loadFromAPI}
          doDelete={this.doDelete}
        />
        {this.state.alert}
      </Container >
    );
  }
}

export default Agenda;