import React, { Component, Fragment } from "react";
import { Modal, Alert, Button } from "react-bootstrap";
import { CSSTransition } from 'react-transition-group';
import { toast } from 'react-toastify';

import { Loading } from "../../../components";

import api from "../../../services/api";

class ModalNotifica extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showModal: false,
      isLoading: false,
      record: {}
    };
    this.loadFromAPI = props.loadFromAPI;
    this.closeModalNotifica = props.closeModal;
  }

  static getDerivedStateFromProps(props, state) {
    if (props.showModal !== state.showModal) {
      return {
        showModal: props.showModal,
        record: props.record
      };
    }
    return null;
  }

  closeModal = () => {
    this.setState({
      showModal: false,
      isLoading: false,
      record: this.props.emptyRecord
    }, () => this.closeModalNotifica());
  };

  handleSubmit = (event) => {
    event.preventDefault();
    this.setState({ isLoading: true }, () => {
      api.post('/inscricoes/notifica', { id: this.state.record.id })
        .then((response) => {
          if (response.data.message === 'ok') {
            toast.success('Notificação enviada com sucesso!');
            this.loadFromAPI();
            this.closeModal();
          } else {
            this.setState({ isLoading: false });
            toast.error(response.data.message);
          }
        })
        .catch((error) => {
          this.setState({ isLoading: false });
          toast.error('Ocorreu uma falha ao tentar enviar a notificação!');
        });
    });
  };

  render() {
    const { showModal, isLoading, record } = this.state;

    return (
      <Fragment>
        <Modal show={showModal} onHide={this.closeModal}>
          <Modal.Header closeButton>
            <Modal.Title>Notificação para matrícula</Modal.Title>
          </Modal.Header>
          <div className="loading">
          <Modal.Body className="text-center">
              <h5>Confirma o envio da mensagem de notificação?</h5>
              <Alert variant="primary" className="mb-0">
              <Alert.Heading>{record.nome}</Alert.Heading>
                {record.email}
                {(record.email && record.fone) && (<br />)}
                {record.fone}
              </Alert>
            </Modal.Body>
            <Modal.Footer>
              <Button variant="outline-secondary" onClick={this.closeModal}>Cancelar</Button>
              <Button variant="success" onClick={this.handleSubmit}>Enviar</Button>
            </Modal.Footer>
            <CSSTransition
              in={isLoading}
              timeout={500}
              classNames="fade"
              unmountOnExit
            >
              <div><Loading /></div>
            </CSSTransition>
          </div>
        </Modal>
      </Fragment>
    );
  }
}

export default ModalNotifica;