export const formatFone = (str) => {
  if (!str.length) return '';

  let fone = '',
    numbers = str.match(/\d+/g);

  numbers = (numbers && numbers.length) ? numbers.join('') : '';

  if (str.length < 5 && numbers.length < 3) {
    return numbers.length === 1 ? '(' + numbers : str;
  }

  fone = '(' + numbers.substring(0, 2) + ') ';
  numbers = numbers.substring(2);
  fone += numbers.length > 4 ? numbers.substring(0, numbers.length - 4) + '.' : '';
  fone += numbers.substring(numbers.length - 4);

  return fone;
};