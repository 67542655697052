export const isAuthenticated = () => {
  const now = new Date(),
    token = localStorage.getItem('token');

  let expires = localStorage.getItem('expires'),
    usuario = localStorage.getItem('usuario');

  if (!token || !expires || !usuario) return false;

  usuario = JSON.parse(usuario);
  expires = new Date(parseInt(expires));

  if (expires < now) return false;
  if (!usuario.id || !usuario.nome) return false;
  if (!usuario.idProfessor && !usuario.idAdministrador) return false;
  
  return true;
};

export const setSession = (session) => {
  if (typeof (session.usuario) !== 'string') {
    session.usuario = JSON.stringify(session.usuario);
  }
  localStorage.setItem('usuario', session.usuario);
  localStorage.setItem('token', session.token);
  localStorage.setItem('expires', session.expires);
};