import React, { Component } from "react";
import { Button } from "react-bootstrap";
import Dropzone from "react-dropzone";

export default class Dropbox extends Component {
  renderDragMessage = (isDragActive, isDragReject) => {
    if (!isDragActive) return <div>
      <i className="fa fa-youtube-play fa-3x mb-2"></i>
      <h5>Arraste e solte o arquivo aqui para carregar...</h5>
      <Button variant="primary" size="sm"><i className="fa fa-cloud-upload mr-2"></i> ou escolha o arquivo</Button>
    </div>;
    if (isDragReject) return <div type="error">Arquivo não suportado</div>;
    return <div type="success">Solte o arquivo aqui para carregar...</div>;
  };

  render() {
    const { onUpload } = this.props;

    return (
      <Dropzone
        accept="video/*"
        onDropAccepted={onUpload}
        multiple={false}
      >
        {({ getRootProps, getInputProps, isDragActive, isDragReject }) => (
          <div {...getRootProps()} className="dropzone">
            <input {...getInputProps()} />
            {this.renderDragMessage(isDragActive, isDragReject)}
          </div>
        )}
      </Dropzone>
    );
  }
}