import React, { Component } from "react";
import { Switch, Route } from "react-router-dom";

import '../assets/css/pages.css';
import routes from "../routes/pages.js";

class Pages extends Component {
  getRoutes = routes => {
    return routes.map((prop, key) => {
      return (
        <Route
          path={prop.path}
          key={key}
          render={routeProps => (<prop.component {...routeProps} />)}
        />
      );
    });
  };

  render() {
    return (
      <div className="public-page">
        <Switch>{this.getRoutes(routes)}</Switch>
      </div>
    );
  }
}

export default Pages;
