import React, { Component } from 'react'
import Chart from 'chart.js';

let myChart;

class InscricoesieChart extends Component {

  componentDidMount = () => {
    this.buildChart();
  };

  componentDidUpdate = () => {
    this.buildChart();
  };

  buildChart = () => {
    let element = document.getElementById("piechart-inscricoes"),
      myChartRef = element.getContext("2d");

    element.height = this.props.heigth;

    if (typeof myChart !== "undefined") myChart.destroy();

    let unidades = this.props.unidades.slice();
    unidades = unidades.filter(a => a.inscricoes.total > 0);

    myChart = new Chart(myChartRef, {
      type: 'doughnut',
      data: {
        labels: unidades.map(u => u.unidade),
        datasets: [
          {
            data: unidades.map(u => u.inscricoes.total),
            backgroundColor: unidades.map(u => u.color),
            datalabels: {
              color: 'rgba(0, 0, 0, 1)',
              anchor: 'end',
              align: 'start',
              formatter: function (value, context) {
                const total = context.dataset.data.reduce((a, b) => a + b, 0);
                return Math.round((value / total) * 100) + '%';
              }
            }
          }
        ]
      },
      options: {
        title: {
          display: true,
          text: 'Inscrições / Unidade',
          fontSize: 14
        },
        legend: {
          display: false,
          position: 'bottom',
          labels: { padding: 5 }
        },
        layout: {
          padding: {
            left: 0,
            right: 0,
            top: -10,
            bottom: 0
          }
        },
        maintainAspectRatio: false
      }
    });
  };

  render() {
    return (
      <canvas id="piechart-inscricoes" />
    )
  }
}

export default InscricoesieChart;