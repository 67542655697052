import React, { Component, Fragment } from "react";
import { Modal, Table, Form, Col, Button, InputGroup, Alert } from "react-bootstrap";
import { CSSTransition } from 'react-transition-group';
import { toast } from 'react-toastify';
import SweetAlert from "react-bootstrap-sweetalert";
import DatePicker from "react-datepicker";
import {
  Loading,
  MultiSelectUnidades,
  MultiSelectProfessores,
  MultiSelectAlunos
} from "../../../components";
import { dateToServer } from "../../../utils/dates";

import api from "../../../services/api";

const errorsReset = {
  atividade: '',
  local: '',
  data: '',
  unidades: '',
  alunos: ''
};

class FormAtividade extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showModal: false,
      isLoading: false,
      alert: null,
      record: props.record,
      outro: '',
      errors: errorsReset
    };
    this.loadFromAPI = props.loadFromAPI;
    this.closeForm = props.closeForm;
  }

  static getDerivedStateFromProps(props, state) {
    if (props.showModal !== state.showModal) {
      const record = Object.assign({}, props.record);

      if (record.unidades.length) {
        record.unidades = record.unidades.map(a => { return { value: a.idUnidade, label: a.unidade } });
      }
      if (record.professores.length) {
        record.professores = record.professores.map(a => { return { value: a.idProfessor, label: a.professor } });
      }
      if (record.alunos.length) {
        record.alunos = record.alunos.map(a => { return { value: a.idAluno, label: a.aluno } });
      }
      if (record.outros.length) {
        record.outros = record.outros.map(a => { return { value: a.id, label: a.nome } });
      }

      if (record.data) {
        if (Object.prototype.toString.call(record.data) !== "[object Date]") record.data = new Date(record.data);
        if (isNaN(record.data.getTime())) record.data = '';
      }

      return {
        showModal: props.showModal,
        record: record
      };
    }
    return null;
  }

  closeModal = () => {
    this.setState({
      showModal: false,
      isLoading: false,
      record: this.props.emptyRecord,
      errors: errorsReset
    }, () => this.closeForm());
  };

  validateInput = (name, value, errors) => {
    switch (name) {
      case 'atividade':
        errors.atividade = !value ? 'Preencha o campo ATIVIDADE' : '';
        break;
      case 'local':
        errors.local = !value ? 'Preencha o campo LOCAL' : '';
        break;
      case 'data':
        errors.data = (!value || !value instanceof Date) ? 'DATA inválida' : '';
        break;
      case 'unidades':
        errors.unidades = !value ? 'Selecione no mínimo uma UNIDADE' : '';
        break;
      case 'alunos':
        errors.alunos = !value ? 'Selecione no mínimo um ALUNO' : '';
        break;
      default:
        break;
    }
    return errors;
  };

  validateForm = () => {
    let record = Object.assign({}, this.state.record),
      errors = Object.assign({}, this.state.errors);

    for (const key in record) {
      errors = this.validateInput(key, record[key], errors);
    }

    return errors;
  };

  handleInputChange = (event) => {
    event.preventDefault();
    const { name, value } = event.target;
    this.handleComponentChange(name, value);
  };

  handleComponentChange = (name, value) => {
    let record = Object.assign({}, this.state.record),
      errors = Object.assign({}, this.state.errors);

    record[name] = value;
    this.setState({
      record: record,
      errors: this.validateInput(name, value, errors)
    });
  };

  hideAlert = () => {
    this.setState({ alert: null });
  };

  handleSubmit = (event) => {
    event.preventDefault();
    const errors = this.validateForm();
    if (JSON.stringify(errors) !== JSON.stringify(errorsReset)) {
      this.setState({
        alert: (<SweetAlert warning title="Oops!" onConfirm={() => this.hideAlert()} showConfirm={false} timeout={2000}>Preenchimento incorreto!</SweetAlert>),
        errors: errors
      });
    } else {
      let record = Object.assign({}, this.state.record);
      record.data = dateToServer(record.data);
      record.unidades = record.unidades.map(unidade => { return unidade.value });
      record.professores = record.professores.map(professor => { return professor.value });
      record.alunos = record.alunos.map(aluno => { return aluno.value });
      record.outros = record.outros.map(outro => { return outro.label });
      this.setState({ isLoading: true }, () => {
        api.post('/atividades', record)
          .then((response) => {
            if (response.data.message === 'ok') {
              toast.success('Atividade salva com sucesso!');
              this.loadFromAPI();
              this.closeModal();
            } else {
              this.setState({
                isLoading: false,
                alert: (<SweetAlert warning title="Oops!" onConfirm={() => this.hideAlert()} showConfirm={false} timeout={2000}>{response.data.message}</SweetAlert>),
              });
            }
          })
          .catch((error) => {
            this.setState({ isLoading: false });
            toast.error('Falha ao enviar os dados!');
          });
      });
    }
  };

  handleOutroChange = (event) => {
    event.preventDefault();
    this.setState({ outro: event.target.value });
  };

  addOutro = () => {
    let record = Object.assign({}, this.state.record)
    record.outros.push({ value: '', label: this.state.outro });
    this.setState({
      outro: '',
      record: record
    });
  };

  deleteOutro = (index) => {
    let record = Object.assign({}, this.state.record)
    record.outros.splice(index, 1);
    this.setState({ record: record });
  };

  render() {
    const { showModal, isLoading, alert, record, outro, errors } = this.state;
    const unidades = record.unidades ? record.unidades.map(u => { return u.value }) : [];

    const RowsOutros = (outros) => {
      if (!outros.length) return <tr><td colSpan={3} className="py-3">Nenhum participante</td></tr>;
      let rows = [];
      for (let i = 0; i < outros.length; i++) {
        rows.push(
          <tr key={i}>
            <td className="text-center" style={{ width: '40px' }}><span className="text-muted">{i + 1}</span></td>
            <td><b>{outros[i].label}</b></td>
            <td style={{ width: '40px' }}>
              <Button
                variant="danger"
                title="Excluir"
                className="btn-icon-sm"
                onClick={() => this.deleteOutro(i)}
              >
                <i className="fa fa-trash"></i>
              </Button>
            </td>
          </tr>
        )
      }
      return rows;
    };

    return (
      <Fragment>
        {alert}
        <Modal size="lg" show={showModal} onHide={this.closeModal}>
          <Modal.Header closeButton>
            <Modal.Title>{record.id ? 'Editar' : 'Adicionar'} atividade</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="loading">
              <CSSTransition
                in={isLoading}
                timeout={500}
                classNames="fade"
                unmountOnExit
              >
                <div><Loading /></div>
              </CSSTransition>
              <Form id="form-atividade" className="form-horizontal form-sm form-custom" onSubmit={this.handleSubmit} noValidate>
                <Form.Row>
                  <Form.Group as={Col} xs={12}>
                    <Form.Label>Atividade</Form.Label>
                    <Form.Control type="text"
                      name="atividade"
                      value={record.atividade}
                      onChange={this.handleInputChange}
                      onBlur={this.handleInputChange}
                      className={errors.atividade.length ? 'is-invalid' : ''}
                      required
                    />
                    <Form.Control.Feedback type="invalid">{errors.atividade}</Form.Control.Feedback>
                  </Form.Group>
                  <Form.Group as={Col} sm={8}>
                    <Form.Label>Local</Form.Label>
                    <Form.Control type="text"
                      name="local"
                      value={record.local}
                      onChange={this.handleInputChange}
                      onBlur={this.handleInputChange}
                      className={errors.local.length ? 'is-invalid' : ''}
                      required
                    />
                    <Form.Control.Feedback type="invalid">{errors.local}</Form.Control.Feedback>
                  </Form.Group>
                  <Form.Group as={Col} sm={4}>
                    <Form.Label>Data</Form.Label>
                    <div className={errors.data ? ' is-invalid' : ''}>
                      <DatePicker
                        name="data"
                        selected={record.data}
                        dateFormat="dd/MM/yyyy"
                        onChange={date => this.handleComponentChange('data', date)}
                        popperPlacement="bottom-end"
                        popperClassName="right-triangle"
                        className={'form-control' + (errors.data ? ' is-invalid' : '')}
                        showMonthDropdown
                        showYearDropdown
                        dropdownMode="select"
                        required
                      />
                    </div>
                    <Form.Control.Feedback type="invalid">{errors.data}</Form.Control.Feedback>
                  </Form.Group>
                  <Form.Group as={Col} xs={12}>
                    <Form.Label>Instituição</Form.Label>
                    <Form.Control type="text"
                      name="instituicao"
                      value={record.instituicao}
                      onChange={this.handleInputChange}
                      onBlur={this.handleInputChange}
                    />
                  </Form.Group>
                  <Form.Group as={Col} xs={12}>
                    <Form.Label>Descrição</Form.Label>
                    <Form.Control as="textarea"
                      rows="3"
                      name="descricao"
                      value={record.descricao}
                      onChange={this.handleInputChange}
                      onBlur={this.handleInputChange}
                    />
                  </Form.Group>
                  <Form.Group as={Col} xs={12}>
                    <Form.Label>Professores</Form.Label>
                    <MultiSelectProfessores
                      name="professores"
                      professores={record.professores}
                      onChange={this.handleComponentChange}
                      error={errors.professores}
                      required
                    />
                    <Form.Control.Feedback type="invalid">{errors.professores}</Form.Control.Feedback>
                  </Form.Group>
                  <Form.Group as={Col} xs={12}>
                    <Form.Label>Unidades</Form.Label>
                    <MultiSelectUnidades
                      name="unidades"
                      unidades={record.unidades}
                      onChange={this.handleComponentChange}
                      required
                    />
                    <Form.Control.Feedback type="invalid">{errors.unidades}</Form.Control.Feedback>
                  </Form.Group>
                  <Form.Group as={Col} xs={12}>
                    <Form.Label>Alunos</Form.Label>
                    {unidades.length ? (
                      <MultiSelectAlunos
                        name="alunos"
                        checkUnidade
                        unidades={unidades}
                        status="M"
                        alunos={record.alunos}
                        onChange={this.handleComponentChange}
                        error={errors.alunos}
                      />
                    ) : (
                        <Alert variant="danger" className="mb-0 py-3 text-center">
                          Selecione uma ou mais unidades
                        </Alert>
                      )}
                  </Form.Group>
                  <Form.Group as={Col} xs={12}>
                    <Form.Label>Outros participantes</Form.Label>
                    <InputGroup className="mb-1">
                      <Form.Control type="text"
                        name="outro"
                        value={outro}
                        onChange={this.handleOutroChange}
                        placeholder="Digite o nome..."
                      />
                      <InputGroup.Append>
                        <Button variant="secondary" onClick={() => this.addOutro()} >Adicionar</Button>
                      </InputGroup.Append>
                    </InputGroup>
                    <Table bordered striped className="table-data" responsive>
                      <tbody>{RowsOutros(record.outros)}</tbody>
                    </Table>
                  </Form.Group>
                </Form.Row>
              </Form>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="outline-secondary" onClick={this.closeModal}>Cancelar</Button>
            <Button variant="success" form="form-atividade" type="submit">Salvar</Button>
          </Modal.Footer>
        </Modal>
      </Fragment>
    );
  }
}

export default FormAtividade;