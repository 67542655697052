import React, { Component } from "react";

class SelectIdades extends Component {
  constructor(props) {
    super(props);
    this.state = {
      value: props.idade ? props.idade : ''
    };
    this.onChange = props.onChange;
  }

  handleChange = (event) => {
    event.preventDefault();
    const value = event.target.value;
    if (this.state.value !== value) {
      this.setState({ value: value }, this.onChange(this.props.name, value));
    }
  };

  render() {
    const { value } = this.state;

    return (
      <select
        value={value}
        onChange={this.handleChange}
        className="custom-select"
        required={this.props.required}
      >
        <option value=""></option>
        <option value="7">7</option>
        <option value="8">8</option>
        <option value="9">9</option>
        <option value="10">10</option>
        <option value="11">11</option>
        <option value="12">12</option>
        <option value="13">13</option>
        <option value="14">14</option>
        <option value="15">15</option>
        <option value="16">16...</option>
      </select>
    )
  }
}

export default SelectIdades;
