import React, { Component } from "react";

import { weekNames } from "../../../../utils/dates";
import Dia from "./Dia";

class SemanaQuadro extends Component {
  constructor(props) {
    super(props);
    this.openModalForm = props.openModalForm;
  }

  filterRecords = (records, weekDay) => {
    return records.filter(record => {
      return parseInt(record.dia) === parseInt(weekDay);
    });
  };

  render() {
    let semana = [];

    weekNames.forEach((value, index) => {
      semana.push(<td key={index}>
        <Dia weekDay={index}
          records={this.filterRecords(this.props.records, index)}
          emptyRecord={this.props.emptyRecord}
          openModalForm={(value) => this.openModalForm(value)}
        />
      </td>);
    });

    return semana;
  }
}

export default SemanaQuadro;