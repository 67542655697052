import React, { memo } from 'react';
import { Button } from "react-bootstrap";

function ButtonSend({ label, onClick }) {
  return <Button
  variant="primary"
  title={label}
  onClick={onClick}
  className="btn-icon-sm mr-1"
>
  <i className="fa fa-send"></i>
</Button>
};

export default memo(ButtonSend);