import React, { Component } from "react";

class DiaMes extends Component {
  constructor(props) {
    super(props);
    this.openModalForm = props.openModalForm;
  }

  render() {
    const records = this.props.records,
      targetMonth = new Date(this.props.targetMonth),
      current = parseInt(this.props.monthDay),
      date = new Date(targetMonth.setDate(current)),
      year = date.getFullYear(),
      month = date.getMonth(),
      day = date.getDate(),
      events = [];

    let pos = 0,
      key = 0;

    records.forEach((record) => {
      const inicio = new Date(record.inicio.replace(/Z$/, '')),
        fim = new Date(record.fim.replace(/Z$/, '')),
        posIni = ((inicio.getHours() * 60) + inicio.getMinutes()) - 420;

      while (pos < posIni) {
        const event = Object.assign({}, this.props.emptyRecord);
        let hours = parseInt(pos / 60),
          minutes = pos - (hours * 60);
        event.inicio = new Date(year, month, day, hours + 7, minutes);
        let posAux = pos + 1;
        while (posAux % 60 && posAux < posIni) { posAux++; }
        const posDiff = posAux - pos;
        event.fim = new Date(event.inicio.getTime() + posDiff * 60000);
        events.push(<div key={key} className="alert-event event-button" style={{ height: (posDiff / 2) + 'px' }} onClick={() => this.openModalForm(event)}></div>);
        pos = posAux;
        key++;
      }

      const divHeight = Math.abs(Math.floor(((fim - inicio) / 1000) / 60));
      
      let eventClass = 'event-secondary',
        nivel = 'Nenhum aluno';

      if (record.nivel === 1) {
        eventClass = 'event-success';
        nivel = 'Iniciante';
      } else if (record.nivel === 2) {
        eventClass = 'event-warning';
        nivel = 'Intermediário';
      } else if (record.nivel === 3) {
        eventClass = 'event-primary';
        nivel = 'Avançado';
      } else if (record.nivel === 4) {
        eventClass = 'event-danger';
        nivel = 'Misto';
      }

      events.push(
        <div key={key} className={'alert-event ' + eventClass} style={{ height: (divHeight / 2) + 'px' }} onClick={() => this.openModalForm(record)}>
          <span>{nivel}</span>
          <span className="float-right">{record.alunos ? record.alunos.length : '0'}</span>
        </div>
      )
        ;
      pos += divHeight;
      key++;
    });

    while (pos < 900) {
      const event = Object.assign({}, this.props.emptyRecord);
      let hours = parseInt(pos / 60),
        minutes = pos - (hours * 60);
      event.inicio = new Date(year, month, day, hours + 7, minutes);
      let posAux = pos + 1;
      while (posAux % 60 && posAux < 900) { posAux++; }
      const posDiff = posAux - pos;
      event.fim = new Date(event.inicio.getTime() + posDiff * 60000);
      events.push(<div key={key} className="alert-event event-button" style={{ height: (posDiff / 2) + 'px' }} onClick={() => this.openModalForm(event)}></div>);
      pos = posAux;
      key++;
    }

    return (
      <div className={(date.getDay() === 0 || date.getDay() === 6) ? 'bg-weekend' : ''} >{events}</div>
    );
  }
}

export default DiaMes;