import React, { Component, memo } from "react";
import { Button } from "react-bootstrap";
import SweetAlert from "react-bootstrap-sweetalert";

class ButtonDelete extends Component {
  constructor(props) {
    super(props);
    this.state = { alert: null };
    this.doDelete = props.doDelete;
  }

  handleDalete = () => {
    this.setState({
      alert: (
        <SweetAlert
          warning
          title="Tem certeza?"
          onConfirm={() => this.triggerDelete()}
          onCancel={this.hideAlert}
          confirmBtnBsStyle="danger"
          cancelBtnBsStyle="secondary"
          confirmBtnText="Sim, excluir!"
          cancelBtnText="Cancelar"
          showCancel
        >
          Confirma a exclusao de <br /><b>{this.props.label}</b> ?!
        </SweetAlert>
      )
    });
  };

  triggerDelete = () => {
    this.hideAlert();
    this.doDelete(this.props.id);
  };

  hideAlert = () => {
    this.setState({ alert: null });
  };

  render() {
    return (
      <span>
        <Button
          variant="danger"
          title={this.props.title ? this.props.title : "Excluir"}
          className={!this.props.buttonLabel ? "btn-icon-sm ml-1" : ""}
          onClick={() => this.handleDalete()}
        >
          {this.props.buttonLabel ? (this.props.buttonLabel) : (<i className="fa fa-trash"></i>)}
        </Button>
        {this.state.alert}
      </span>
    );
  }
}

export default memo(ButtonDelete);