import React, { Component } from 'react'
import Chart from 'chart.js';

let myChart;

class AtividadesPieChart extends Component {

  componentDidMount = () => {
    this.buildChart();
  };

  componentDidUpdate = () => {
    this.buildChart();
  };

  buildChart = () => {
    let element = document.getElementById("piechart-atividades"),
      myChartRef = element.getContext("2d");

    element.height = this.props.heigth;

    if (typeof myChart !== "undefined") myChart.destroy();

    let unidades = this.props.unidades.slice();
    unidades = unidades.filter(a => a.atividades > 0);

    myChart = new Chart(myChartRef, {
      type: 'pie',
      data: {
        labels: unidades.map(u => u.unidade),
        datasets: [
          {
            data: unidades.map(u => u.atividades),
            backgroundColor: unidades.map(u => u.color),
            datalabels: {
              color: 'rgba(0, 0, 0, 1)',
              anchor: 'end',
              align: 'start',
              formatter: function (value, context) {
                const total = context.dataset.data.reduce((a, b) => a + b, 0);
                return Math.round((value/total) * 100) + '%';
              }
            }
          }
        ]
      },
      options: {
        legend: { display: false },
        maintainAspectRatio: false
      }
    });
  };

  render() {
    return (
      <canvas id="piechart-atividades" />
    )
  }
}

export default AtividadesPieChart;