import React, { Component } from "react";
import { Container, Row, Col, Card, Table, Button } from "react-bootstrap";
import { CSSTransition } from 'react-transition-group';
import { toast } from 'react-toastify';
import Pagination from "react-js-pagination";
import {
  Loading,
  ButtonAdd,
  ButtonEdit,
  ButtonDelete,
  ButtonSend,
  PaginationPerPage
} from '../../../components';
import Filter from './Filter';
import FormInscricao from './FormInscricao';
import FormAluno from '../Alunos/FormAluno';
import ModalNotifica from './ModalNotifica';
import { dateToServer, datetimeToLocal, calculaIdade } from '../../../utils/dates';
import { turnoLabel, serieLabel, filterQuery } from '../../../utils/misc';
import api from '../../../services/api';

const emptyRecord = {
  id: '',
  idUnidade: '',
  idCurso: '',
  nome: '',
  nascimento: '',
  cpf: '',
  rg: '',
  email: '',
  fone: '',
  colegio: '',
  serie: '',
  turno: '',
  inscricao: '',
  ativo: 'S'
};

const emptyAluno = {
  id: '',
  idUnidade: '',
  idCurso: '',
  idEstado: '',
  nome: '',
  email: '',
  municipio: '',
  colegio: '',
  serie: '',
  turno: '',
  nascimento: '',
  cpf: '',
  rg: '',
  endereco: '',
  bairro: '',
  fone: '',
  responsavel: '',
  respTipo: '',
  respRG: '',
  respFone: '',
  foneEmergencia: '',
  inscricao: '',
  matricula: '',
  formatura: '',
  desistencia: '',
  nivel: ''
};

class Inscricoes extends Component {
  usuario = JSON.parse(localStorage.getItem('usuario'));

  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      showFormInscricao: false,
      showFormAluno: false,
      showModalNotifica: false,
      records: [],
      totalRecords: 0,
      record: emptyRecord,
      query: {
        idUnidade: (this.usuario.unidades && this.usuario.unidades.length === 1) ? this.usuario.unidades[0].id : '',
        idCurso: (this.usuario.cursos && this.usuario.cursos.length === 1) ? this.usuario.cursos[0].id : '',
        nome: '',
        idade: '',
        serie: '',
        turno: '',
        dataIni: '',
        dataFim: '',
        order: 'inscricoes.inscricao',
        direction: 'ASC',
        limit: 50,
        offset: 0
      },
      activePage: 1
    };
  }

  loadFromAPI = () => {
    let query = Object.assign({}, this.state.query);
    this.setState({ isLoading: true }, () => {
      query.dataIni = query.dataIni ? dateToServer(query.dataIni) : '';
      query.dataFim = query.dataFim ? dateToServer(query.dataFim) : '';
      api.get('/inscricoes', { params: filterQuery(query) })
        .then(response => {
          this.setState({
            records: response.data.inscricoes ? response.data.inscricoes : [],
            totalRecords: response.data.total ? response.data.total : 0,
            isLoading: false
          });
        })
        .catch(error => {
          this.setState({ isLoading: false });
          toast.error('Falha ao obter dados!');
        });
    });
  };

  doDelete = (id) => {
    api.delete('/inscricoes', { data: { id: id } })
      .then((response) => {
        if (response.data.message === 'ok') {
          toast.success('Inscrição excluida com sucesso!');
          this.loadFromAPI();
        } else {
          toast.error(response.data.message);
        }
      })
      .catch((error) => {
        toast.error('Ocorreu uma falha ao excluir!');
      })
  };

  handlePageChange = (pageNumber) => {
    let query = Object.assign({}, this.state.query);
    query.offset = (pageNumber * query.limit) - query.limit;
    this.setState({
      query: query,
      activePage: pageNumber
    }, () => this.loadFromAPI());
  };

  setQuery = (filter) => {
    let query = Object.assign({}, this.state.query);
    query.idUnidade = filter.idUnidade;
    query.idCurso = filter.idCurso;
    query.nome = filter.nome;
    query.status = filter.status;
    query.idade = filter.idade;
    query.serie = filter.serie;
    query.turno = filter.turno;
    query.dataIni = filter.dataIni;
    query.dataFim = filter.dataFim;
    this.setState({ query: query }, () => this.loadFromAPI());
  };

  setLimit = (limit) => {
    let query = Object.assign({}, this.state.query);
    query.limit = limit;
    query.offset = 0;
    this.setState({
      query: query,
      activePage: 1
    }, () => this.loadFromAPI());
  };

  setOrder = (order) => {
    let query = Object.assign({}, this.state.query);
    query.order = order;
    query.direction = (query.order === order && query.direction === 'ASC') ? 'DESC' : 'ASC';

    if (this.state.totalRecords <= query.limit) {
      // reordena em memoria
      let records = this.state.records;
      if (order.indexOf('.') > 0) {
        order = order.split('.');
        order = order[1];
      }

      if (records.length && query.direction === 'ASC') {
        records.sort((a, b) => { return (a[order] > b[order]) ? 1 : -1 });
      } else {
        records.sort((a, b) => { return (a[order] < b[order]) ? 1 : -1 });
      }
      this.setState({
        records: records,
        query: query
      })
    } else {
      this.setState({ query: query });
      this.loadFromAPI();
    }
  };

  openFormInscricao = (record) => {
    this.setState({
      showFormInscricao: true,
      record: record
    })
  };

  closeFormInscricao = () => {
    this.setState({
      showFormInscricao: false,
      record: emptyRecord
    });
  };

  openFormAluno = (record) => {
    let newRecord = Object.assign({}, record);
    newRecord.idInscricao = record.id;
    newRecord.id = null;
    this.setState({
      showFormAluno: true,
      record: newRecord
    })
  };

  closeFormAluno = () => {
    this.setState({
      showFormAluno: false,
      record: emptyRecord
    });
  };

  openModalNotifica = (record) => {
    this.setState({
      showModalNotifica: true,
      record: record
    })
  };

  closeModalNotifica = () => {
    this.setState({
      showModalNotifica: false,
      record: emptyRecord
    });
  };

  componentDidMount = () => {
    this.loadFromAPI();
  };

  render() {
    const { isLoading, showFormInscricao, showFormAluno, showModalNotifica, records, record, query, activePage, totalRecords } = this.state;

    return (
      <Container fluid>
        <Row className="mb-3">
          <Col><h1 className="page-title">Inscrições</h1></Col>
          <Col sm="auto" className="pt-3 pt-sm-2">
            {this.usuario.idProfessor ? (
              <Button
                variant="secondary"
                title="Nova inscrição"
                disabled
              >
                <i className="fa fa-plus"></i> Nova inscrição
              </Button>

            ) : (
                <ButtonAdd label="Nova inscrição" onClick={() => this.openFormInscricao(emptyRecord)} />
              )}
          </Col>
        </Row>
        <Filter usuario={this.usuario} setQuery={this.setQuery} />
        <br />
        <Card className="card-list">
          <Card.Body className="p-0">
            <div className="loading">
              <CSSTransition
                in={isLoading}
                timeout={500}
                classNames="fade"
                unmountOnExit
              >
                <div><Loading /></div>
              </CSSTransition>
              <Row className="pt-2 pb-1 pl-3 pr-2">
                <Col className="text-center text-sm-left">
                  <span className="nowrap">Total: <b>{totalRecords}</b> registro(s).</span>&nbsp;
                  {totalRecords > query.limit && (<PaginationPerPage limit={query.limit} setLimit={this.setLimit} />)}
                </Col>
                <Col>
                  {totalRecords > query.limit && (
                    <Pagination
                      activePage={activePage}
                      itemsCountPerPage={query.limit}
                      totalItemsCount={totalRecords}
                      pageRangeDisplayed={5}
                      onChange={this.handlePageChange}
                      innerClass={'pagination pagination-sm float-sm-right my-0'}
                      itemClass={'page-item'}
                      linkClass={'page-link'}
                      activeLinkClass={'page-link'}
                    />
                  )}
                </Col>
              </Row>
              <Table striped hover responsive className="table-data">
                <thead className="thead-light">
                  <tr>
                    <th className="text-center" style={{ minWidth: "30px" }}>#</th>
                    <th><Button variant="link" size="sm" onClick={() => this.setOrder('inscricoes.nome')}>Nome</Button></th>
                    <th className="text-center"><Button variant="link" size="sm" onClick={() => this.setOrder('inscricoes.cpf')}>CPF</Button></th>
                    <th className="text-center"><Button variant="link" size="sm" onClick={() => this.setOrder('inscricoes.nascimento')}>Idade</Button></th>
                    <th className="text-center"><Button variant="link" size="sm" onClick={() => this.setOrder('inscricoes.serie')}>Série</Button></th>
                    <th className="text-center"><Button variant="link" size="sm" onClick={() => this.setOrder('inscricoes.turno')}>Turno</Button></th>
                    <th><Button variant="link" size="sm" onClick={() => this.setOrder('unidades.unidade')}>Unidade</Button></th>
                    <th className="text-center"><Button variant="link" size="sm" onClick={() => this.setOrder('inscricoes.inscricao')}>Inscrição</Button></th>
                    <th className="text-center"><Button variant="link" size="sm" onClick={() => this.setOrder('inscricoes.notificacao')}>Notificação</Button></th>
                    <th style={{ width: "142px" }}></th>
                  </tr>
                </thead>
                <tbody>
                  {!records.length ? (
                    <tr><td colSpan={11} className="empty">Nenhum registro </td></tr>
                  ) : (
                      records.map((item, key) => {
                        return (
                          <tr key={key}>
                            <td className="text-center"><span className="text-muted">{query.offset + key + 1}</span></td>
                            <td><b>{item.nome}</b></td>
                            <td className="text-center">{item.cpf}</td>
                            <td className="text-center">{item.nascimento ? calculaIdade(item.nascimento) + ' anos' : ''}</td>
                            <td className="text-center">{serieLabel(item.serie)}</td>
                            <td className="text-center">{turnoLabel(item.turno)}</td>
                            <td>{item.unidade}</td>
                            <td className="text-center">{datetimeToLocal(item.inscricao)}</td>
                            <td className="text-center">{datetimeToLocal(item.notificacao)}</td>
                            <td className="text-right">
                              {item.notificacao ? (
                                <Button
                                  variant="secondary"
                                  className="btn-icon-sm mr-1"
                                  title="Enviar notificação para matrícula"
                                  disabled
                                >
                                  <i className="fa fa-send"></i>
                                </Button>
                              ) : (
                                  <ButtonSend onClick={() => this.openModalNotifica(item)} label="Enviar notificação para matrícula" />
                                )}
                              <Button
                                variant="info"
                                className="btn-icon-sm mr-1"
                                onClick={() => this.openFormAluno(item)}
                                title="Matricular"
                              >
                                <i className="fa fa-user-plus"></i>
                              </Button>
                              {this.usuario.idProfessor ? (
                                <Button
                                  variant="secondary"
                                  className="btn-icon-sm"
                                  title="Editar"
                                  disabled
                                >
                                  <i className="fa fa-pencil"></i>
                                </Button>
                              ) : (
                                  <ButtonEdit onClick={() => this.openFormInscricao(item)} />
                                )}
                              {this.usuario.idProfessor ? (
                                <Button
                                  variant="secondary"
                                  className="btn-icon-sm ml-1"
                                  title="Excluir"
                                  disabled
                                >
                                  <i className="fa fa-trash"></i>
                                </Button>
                              ) : (
                                  <ButtonDelete id={item.id} label={item.nome} doDelete={this.doDelete} />
                                )}
                            </td>
                          </tr>
                        )
                      })
                    )}
                </tbody>
              </Table>
            </div>
          </Card.Body>
        </Card>
        <br />
        <FormInscricao
          emptyRecord={emptyRecord}
          record={record}
          showModal={showFormInscricao}
          closeModal={this.closeFormInscricao}
          loadFromAPI={this.loadFromAPI}
          usuario={this.usuario}
        />
        <FormAluno
          emptyRecord={emptyAluno}
          record={record}
          showModal={showFormAluno}
          closeFormAluno={this.closeFormAluno}
          loadFromAPI={this.loadFromAPI}
          usuario={this.usuario}
        />
        <ModalNotifica
          emptyRecord={emptyAluno}
          record={record}
          showModal={showModalNotifica}
          closeModal={this.closeModalNotifica}
          loadFromAPI={this.loadFromAPI}
        />
      </Container>
    );
  }
}

export default Inscricoes;