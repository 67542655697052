import React, { memo } from 'react';
import { Button } from "react-bootstrap";

function ButtonPrint({ label, onClick }) {
  return <Button
    variant="secondary"
    title={label}
    className="btn-icon-sm ml-1"
    onClick={onClick}
  >
    <i className="fa fa-print"></i> {label}
</Button>
};

export default memo(ButtonPrint);