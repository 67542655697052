import React, { Component } from "react";
import { Modal, Row, Col, Card} from "react-bootstrap";
import { CSSTransition } from 'react-transition-group';
import SweetAlert from "react-bootstrap-sweetalert";

import { Loading } from "../../../components"

class ModalForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showModal: false,
      isLoading: false,
      alert: null,
      record: {}
    };
    this.loadFromAPI = props.loadFromAPI;
    this.closeModalProfile = props.closeModal;
  }

  static getDerivedStateFromProps(props, state) {
    if (props.showModal !== state.showModal) {
      let record = Object.assign({}, props.record);

      for (const key in record) {
        if (key === 'nascimento' || key === 'inscricao' || key === 'matricula' || key === 'formatura' || key === 'desistencia') {
          if (record[key] && record[key].length) record[key] = new Date(record[key].replace('Z', ''));
          else record[key] = '';
        } else if (record[key] === null) {
          record[key] = '';
        }
      }

      return {
        showModal: props.showModal,
        record: record
      };
    }
    return null;
  }

  closeModal = () => {
    this.setState({
      showModal: false,
      isLoading: false,
      record: this.props.emptyRecord
    }, () => this.closeModalProfile());
  };

  showAlert = (type, message) => {
    this.setState({
      alert: (<SweetAlert type={type} title="Oops!" onConfirm={() => this.hideAlert()} showConfirm={false} timeout={2000}>{message}</SweetAlert>)
    });
  };

  hideAlert = () => {
    this.setState({ alert: null });
  };

  render() {
    const { showModal, isLoading, alert, record } = this.state;

    return (
      <React.Fragment>
        {alert}
        <Modal
          size="lg"
          show={showModal}
          onHide={this.closeModal}
          dialogClassName="modal-90w"
        >
          <Modal.Header closeButton>
            <Modal.Title>Ficha do professor</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="loading">
              <CSSTransition
                in={isLoading}
                timeout={500}
                classNames="fade"
                unmountOnExit
              >
                <div><Loading /></div>
              </CSSTransition>

              <Row>
                <Col sm={12}>
                  <h2 className="mb-3"><b>{record.nome}</b></h2>
                </Col>
                <Col md={6}>
                  <Card className="mb-3">
                    <Card.Body className="p-2">
                    <Card.Title>Dados pessoais</Card.Title>
                    </Card.Body>
                  </Card>
                  <Card className="mb-3">
                    <Card.Body className="p-2">
                    <Card.Title>Aulas</Card.Title>
                    </Card.Body>
                  </Card>
                </Col>
                <Col md={6}>
                <Card className="mb-3">
                    <Card.Body className="p-2">
                    <Card.Title>Atividades</Card.Title>
                    </Card.Body>
                  </Card>
                </Col>
              </Row>

            </div>
          </Modal.Body>
        </Modal>
      </React.Fragment>
    );
  }
}

export default ModalForm;