import React, { Component } from 'react'
import Chart from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels';

let myChart;

class AtividadesBarChart extends Component {

  componentDidMount = () => {
    this.buildChart();
  };

  componentDidUpdate = () => {
    this.buildChart();
  };

  buildChart = () => {
    let element = document.getElementById("barchart-atividades"),
      myChartRef = element.getContext("2d");

    element.height = this.props.heigth;

    if (typeof myChart !== "undefined") myChart.destroy();

    const compare = (a, b) => a.atividades > b.atividades ? -1 : (a.atividades < b.atividades ? 1 : 0);
  
    let unidades = this.props.unidades.slice();
    unidades.sort(compare);

    myChart = new Chart(myChartRef, {
      type: 'horizontalBar',
      data: {
        labels: unidades.map(u => u.unidade),
        datasets: [
          {
            data: unidades.map(u => u.atividades),
            categoryPercentage: 0.8,
            barPercentage: 1.0,
            backgroundColor: unidades.map(u => u.color),
            borderWidth: 1,
            datalabels: {
              color: 'rgba(0, 0, 0, 0.8)',
              anchor: 'end',
              align: 'end'
            }
          }
        ]
      },
      options: {
        layout: {
          padding: {
            left: 0,
            right: 22,
            top: 0,
            bottom: 0
          }
        },
        legend: { display: false },
        tooltips: { enabled: false },
        scales: {
          xAxes: [{
            gridLines: {
              zeroLineColor: 'rgba(0, 0, 0, 0.5)'
            },
            ticks: {
              fontColor: '#212528'
            },
          }],
          yAxes: [{
            gridLines: {
              tickMarkLength: 0
            },
            ticks: {
              fontColor: '#212528',
              padding: 10
            },
          }]
        },
        maintainAspectRatio: false
      },
      plugins: [ChartDataLabels]
    });
  };

  render() {
    return (
      <canvas id="barchart-atividades" />
    )
  }
}

export default AtividadesBarChart;