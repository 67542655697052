import React, { Component } from "react";
import { Card, Image, Alert } from "react-bootstrap";
import { CSSTransition } from 'react-transition-group';
import { Loading } from "../components";

import api from "../services/api";
import { stringToObject } from '../utils/misc';
import logo from '../assets/img/logo-gaiteiros.png';

class AtivaInscricao extends Component {
  constructor(props) {
    super(props);
    const params = props.location.search ? stringToObject(props.location.search) : {};
    this.state = {
      isLoading: false,
      token: params.token ? params.token : '',
      response: {
        variant: '',
        message: '',
        action: ''
      }
    };
  }

  handleSubmit = () => {
    if (this.state.token) {
      this.setState({ isLoading: true }, () => {
        api.get('/inscricao', { params: { token: this.state.token } })
          .then((response) => {
            this.setState({
              isLoading: false,
              response: response.data,
            });
          })
          .catch((error) => {
            this.setState({
              isLoading: false,
              response: {
                variant: 'danger',
                message: 'Falha na comunicação de dados!',
                action: 'Tente mais tarde.'
              }
            });
          })
      });
    } else {
      this.setState({
        response: {
          variant: 'danger',
          message: 'Chave de ativação não recebida!',
          action: 'Verifique o email de ativação e tente novamente.',
        }
      });
    }
  };

  componentDidMount = () => {
    this.handleSubmit();
  };

  render() {
    const { isLoading, response } = this.state;

    return (
      <div className="page-wrapper">
        <Card className="shadow">
          <Card.Body>
            <div className="text-center">
              <Image src={logo} fluid />
            </div>
            <br />
            <h4 className="mb-3 text-center" style={{ color: "#fd7922" }}><b>ATIVAÇÃO DE INSCRIÇÃO</b></h4>
            <div className="loading">
              <Alert variant={response.variant} className="mt-4 mb-0 p-4 text-center">
                <Alert.Heading >ATENÇÃO!</Alert.Heading>
                <p className="mb-0 mt-3">{response.message}</p>
                <hr className="my-2" />
                <p>{response.action}</p>
                {response.link === 'inscricao' ? (
                  <Alert.Link
                    href="/#/inscricao"
                    className="btn btn-fabrica"
                  >
                    FORMULÁRIO DE INSCRIÇÃO
                  </Alert.Link>
                ) : (
                  <Alert.Link
                    href="http://fabricadegaiteiros.com.br"
                    className="btn btn-fabrica"
                  >
                    IR PARA O SITE
                  </Alert.Link>
                  )}
              </Alert>
              <CSSTransition
                in={isLoading}
                timeout={500}
                classNames="fade"
                unmountOnExit
              >
                <div><Loading /></div>
              </CSSTransition>
            </div>
          </Card.Body>
        </Card>
      </div>
    );
  }
}

export default AtivaInscricao;
