import React, { Component } from "react";
import { Row, Col, InputGroup } from "react-bootstrap";

import { SelectUnidades, SelectProfessores, SelectCursos } from '../../../../components';

class HeaderAdministrador extends Component {
  constructor(props) {
    super(props);
    this.state = {
      idUnidade: '',
      idProfessor: '',
      idCurso: ''
    };
    this.upState = props.onChange;
  }

  handleComponentChange = (name, value) => {
    this.setState({ [name]: value }, this.upState(name, value));
  };

  render() {
    const { idUnidade, idProfessor, idCurso } = this.state;

    return (
      <Row noGutters className="mb-3">
        <Col md={3} lg={6}><h1 className="page-title">Chamada</h1></Col>
        <Col md={9} lg={6} className="pt-3 pl-md-3">
          <Row noGutters>
            <Col sm={4}>
              <InputGroup size="sm" className="mb-2 mb-sm-0">
                <InputGroup.Prepend>
                  <InputGroup.Text>Unidade</InputGroup.Text>
                </InputGroup.Prepend>
                <SelectUnidades
                  name="idUnidade"
                  unidades={this.props.usuario.unidades}
                  idUnidade={idUnidade}
                  onChange={this.handleComponentChange}
                />
              </InputGroup>
            </Col>
            <Col sm={5} className="px-sm-1">
              <InputGroup size="sm" className="mb-2 mb-sm-0">
                <InputGroup.Prepend>
                  <InputGroup.Text>Professor</InputGroup.Text>
                </InputGroup.Prepend>
                <SelectProfessores
                  name="idProfessor"
                  idProfessor={idProfessor}
                  checkUnidade
                  idUnidade={idUnidade}
                  onChange={this.handleComponentChange}
                />
              </InputGroup>
            </Col>
            <Col sm={3}>
              <InputGroup size="sm" className="mb-2 mb-sm-0">
                <InputGroup.Prepend>
                  <InputGroup.Text>Curso</InputGroup.Text>
                </InputGroup.Prepend>
                <SelectCursos
                  name="idCurso"
                  cursos={this.props.usuario.cursos}
                  idCurso={idCurso}
                  checkProfessor
                  idProfessor={idProfessor}
                  onChange={this.handleComponentChange}
                />
              </InputGroup>
            </Col>
          </Row>
        </Col>
      </Row >
    );
  }
}

export default HeaderAdministrador;