import React, { Component } from "react";
import { Container, Row, Col, Card, Table, Button } from "react-bootstrap";
import { CSSTransition } from 'react-transition-group';
import { toast } from 'react-toastify';
import Pagination from "react-js-pagination";
import {
  Loading,
  NivelBadge,
  ButtonAdd,
  ButtonEdit,
  ButtonDelete,
  ButtonPrint,
  PaginationPerPage
} from '../../../components';
import Filter from './Filter';
import FormAluno from './FormAluno';
import ProfileAluno from './ProfileAluno';
import PrintAluno from './PrintAluno';
import { dateToServer, dateToLocal, calculaIdade } from '../../../utils/dates';
import { serieLabel, filterQuery } from '../../../utils/misc';
import api from '../../../services/api';

const emptyRecord = {
  id: '',
  idUnidade: '',
  idCurso: '',
  idEstado: '',
  nome: '',
  email: '',
  municipio: '',
  colegio: '',
  serie: '',
  turno: '',
  nascimento: '',
  cpf: '',
  rg: '',
  endereco: '',
  bairro: '',
  fone: '',
  responsavel: '',
  respTipo: '',
  respRG: '',
  respFone: '',
  foneEmergencia: '',
  inscricao: '',
  matricula: '',
  formatura: '',
  desistencia: '',
  nivel: ''
};

class Alunos extends Component {
  usuario = JSON.parse(localStorage.getItem('usuario'));

  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      showFormAluno: false,
      showProfileAluno: false,
      showPrintAluno: false,
      records: [],
      totalRecords: 0,
      record: emptyRecord,
      query: {
        idUnidade: (this.usuario.unidades && this.usuario.unidades.length === 1) ? this.usuario.unidades[0].id : '',
        idCurso: (this.usuario.cursos && this.usuario.cursos.length === 1) ? this.usuario.cursos[0].id : '',
        nome: '',
        status: 'M',
        idade: '',
        idColegio: '',
        serie: '',
        turno: '',
        tipoRange: 2,
        rangeStart: '',
        rangeEnd: '',
        order: 'alunos.nome',
        direction: 'ASC',
        limit: 50,
        offset: 0
      },
      activePage: 1
    };
  }

  loadFromAPI = () => {
    this.setState({ isLoading: true }, () => {
      let query = Object.assign({}, this.state.query);
      query.rangeStart = query.rangeStart ? dateToServer(query.rangeStart) : '';
      query.rangeEnd = query.rangeEnd ? dateToServer(query.rangeEnd) : '';
      query.tipoRange = (query.rangeStart && query.rangeEnd) ? query.tipoRange : '';
      api.get('/alunos', { params: filterQuery(query) })
        .then(response => {
          this.setState({
            records: response.data.alunos ? response.data.alunos : [],
            totalRecords: response.data.total ? response.data.total : 0,
            isLoading: false
          });
        })
        .catch(error => {
          this.setState({ isLoading: false });
          toast.error('Falha ao obter dados!');
        });
    });
  };

  doDelete = (id) => {
    api.delete('/alunos', { data: { id: id } })
      .then((response) => {
        if (response.data.message === 'ok') {
          toast.success('Aluno excluido com sucesso!');
          this.loadFromAPI();
        } else {
          toast.error(response.data.message);
        }
      })
      .catch((error) => {
        toast.error('Ocorreu uma falha ao excluir!');
      });
  };

  handlePageChange = (pageNumber) => {
    let query = Object.assign({}, this.state.query);
    query.offset = (pageNumber * query.limit) - query.limit;
    this.setState({
      query: query,
      activePage: pageNumber
    }, () => this.loadFromAPI());
  };

  setQuery = (filter) => {
    let query = Object.assign({}, this.state.query);
    query.idUnidade = filter.idUnidade;
    query.idCurso = filter.idCurso;
    query.nome = filter.nome;
    query.status = filter.status;
    query.idade = filter.idade;
    query.idColegio = filter.idColegio;
    query.serie = filter.serie;
    query.turno = filter.turno;
    query.tipoRange = filter.tipoRange;
    query.rangeStart = filter.rangeStart;
    query.rangeEnd = filter.rangeEnd;
    this.setState({ query: query }, () => this.loadFromAPI());
  };

  setLimit = (limit) => {
    let query = Object.assign({}, this.state.query);
    query.limit = limit;
    query.offset = 0;
    this.setState({
      query: query,
      activePage: 1
    }, () => this.loadFromAPI());
  };

  setOrder = (order) => {
    let query = Object.assign({}, this.state.query);
    query.order = order;
    query.direction = (query.order === order && query.direction === 'ASC') ? 'DESC' : 'ASC';

    if (this.state.totalRecords <= query.limit) {
      // reordena em memoria
      let records = this.state.records;
      if (order.indexOf('.') > 0) {
        order = order.split('.');
        order = order[1];
      }

      if (records.length && query.direction === 'ASC') {
        records.sort((a, b) => (a[order] > b[order]) ? 1 : -1);
      } else {
        records.sort((a, b) => (a[order] < b[order]) ? 1 : -1);
      }
      this.setState({
        records: records,
        query: query
      });
    } else {
      // busca na api
      this.setState({ query: query });
      this.loadFromAPI();
    }
  };

  openFormAluno = (record) => {
    this.setState({
      showFormAluno: true,
      record: record
    });
  };

  closeFormALuno = () => {
    this.setState({
      showFormAluno: false,
      record: emptyRecord
    });
  };

  openProfileAluno = (record) => {
    this.setState({
      showProfileAluno: true,
      record: record
    });
  };

  closeProfileAluno = () => {
    this.setState({
      showProfileAluno: false,
      record: emptyRecord
    });
  };

  openPrintAluno = (record) => {
    this.setState({
      showPrintAluno: true,
      record: record
    });
  };

  closePrintAluno = () => {
    this.setState({
      showPrintAluno: false,
      record: emptyRecord
    });
  };

  componentDidMount = () => {
    this.loadFromAPI();
  };

  render() {
    const { isLoading, showFormAluno, showProfileAluno, showPrintAluno, records, record, query, activePage, totalRecords } = this.state;

    return (
      <Container fluid>
        <Row className="mb-3">
          <Col><h1 className="page-title">Alunos</h1></Col>
          <Col sm="auto" className="pt-3 pt-sm-2">
            <ButtonAdd label="Matricular aluno" onClick={() => this.openFormAluno(emptyRecord)} />
          </Col>
        </Row>
        <Filter query={query} usuario={this.usuario} setQuery={this.setQuery} />
        <br />
        <Card className="card-list">
          <Card.Body className="p-0">
            <div className="loading">
              <CSSTransition
                in={isLoading}
                timeout={500}
                classNames="fade"
                unmountOnExit
              >
                <div><Loading /></div>
              </CSSTransition>
              <Row className="pt-2 pb-1 pl-3 pr-2">
                <Col className="text-center text-sm-left">
                  <span className="nowrap">Total: <b>{totalRecords}</b> registro(s).</span>&nbsp;
                  {totalRecords > query.limit && (<PaginationPerPage limit={query.limit} setLimit={this.setLimit} />)}
                </Col>
                <Col>
                  {totalRecords > query.limit && (
                    <Pagination
                      activePage={activePage}
                      itemsCountPerPage={query.limit}
                      totalItemsCount={totalRecords}
                      pageRangeDisplayed={5}
                      onChange={this.handlePageChange}
                      innerClass={'pagination pagination-sm float-sm-right my-0'}
                      itemClass={'page-item'}
                      linkClass={'page-link'}
                      activeLinkClass={'page-link'}
                    />
                  )}
                </Col>
              </Row>
              <Table striped hover responsive className="table-data">
                <thead className="thead-light">
                  <tr>
                    <th className="text-center" style={{ minWidth: "30px" }}>#</th>
                    <th><Button variant="link" size="sm" onClick={() => this.setOrder('alunos.nome')}>Nome</Button></th>
                    <th className="text-center"><Button variant="link" size="sm" onClick={() => this.setOrder('alunos.nascimento')}>Idade</Button></th>
                    <th className="text-center"><Button variant="link" size="sm" onClick={() => this.setOrder('alunos.serie')}>Série</Button></th>
                    <th><Button variant="link" size="sm" onClick={() => this.setOrder('unidades.unidade')}>Unidade</Button></th>
                    <th className="text-center"><Button variant="link" size="sm" onClick={() => this.setOrder('matriculas.inscricao')}>Inscrição</Button></th>
                    <th className="text-center"><Button variant="link" size="sm" onClick={() => this.setOrder('matriculas.matricula')}>Matricula</Button></th>
                    <th className="text-center"><Button variant="link" size="sm" onClick={() => this.setOrder('matriculas.formatura')}>Formatura</Button></th>
                    <th className="text-center"><Button variant="link" size="sm" onClick={() => this.setOrder('matriculas.desistencia')}>Desistencia</Button></th>
                    <th className="text-center"><Button variant="link" size="sm" onClick={() => this.setOrder('matriculas.nivel')}>Nível</Button></th>
                    <th style={{ width: "76px" }}></th>
                  </tr>
                </thead>
                <tbody>
                  {!records.length ? (
                    <tr><td colSpan={11} className="empty">Nenhum registro </td></tr>
                  ) : (
                      records.map((aluno, key) => {
                        return (
                          <tr key={key}>
                            <td className="text-center"><span className="text-muted">{query.offset + key + 1}</span></td>
                            <td><Button size="sm" variant="link" onClick={() => this.openProfileAluno(aluno)}><b>{aluno.nome}</b></Button></td>
                            <td className="text-center">{aluno.nascimento ? calculaIdade(aluno.nascimento) + ' anos' : ''}</td>
                            <td className="text-center">{serieLabel(aluno.serie)}</td>
                            <td>{aluno.unidade}</td>
                            <td className="text-center">{dateToLocal(aluno.inscricao)}</td>
                            <td className="text-center">{dateToLocal(aluno.matricula)}</td>
                            <td className="text-center">{dateToLocal(aluno.formatura)}</td>
                            <td className="text-center">{dateToLocal(aluno.desistencia)}</td>
                            <td className="text-center"><NivelBadge nivel={aluno.nivel} /></td>
                            <td className="text-right">
                              <ButtonEdit onClick={() => this.openFormAluno(aluno)} />
                              <ButtonPrint onClick={() => this.openPrintAluno(aluno)} />
                              <ButtonDelete id={aluno.id} label={aluno.nome} doDelete={this.doDelete} />
                            </td>
                          </tr>
                        )
                      })
                    )}
                </tbody>
              </Table>
            </div>
          </Card.Body>
        </Card>
        <br />
        <FormAluno
          emptyRecord={emptyRecord}
          record={record}
          showModal={showFormAluno}
          loadFromAPI={this.loadFromAPI}
          closeFormAluno={this.closeFormALuno}
          usuario={this.usuario}
        />
        <ProfileAluno
          emptyRecord={emptyRecord}
          record={record}
          showModal={showProfileAluno}
          closeProfileAluno={this.closeProfileAluno}
          loadFromAPI={this.loadFromAPI}
        />
        <PrintAluno
          emptyRecord={emptyRecord}
          record={record}
          showModal={showPrintAluno}
          closePrintAluno={this.closePrintAluno}
          loadFromAPI={this.loadFromAPI}
        />
      </Container>
    );
  }
}

export default Alunos;