import React, { Component } from "react";
import { Modal, Form, Col, Button } from "react-bootstrap";
import { CSSTransition } from 'react-transition-group';
import { toast } from 'react-toastify';
import SweetAlert from "react-bootstrap-sweetalert";
import {
  Loading,
  SelectEstados,
  SelectAtivo,
  TypeaheadMunicipios,
  MultiSelectProfessores
} from "../../../components";
import api from "../../../services/api";

const resetErrors = { unidade: '', idEstado: '', municipio: '', ativo: '' };

class FormUnidade extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showModal: false,
      isLoading: false,
      alert: null,
      record: {},
      errors: resetErrors
    }
    this.loadFromAPI = props.loadFromAPI;
    this.closeModalForm = props.closeModalForm;
  }

  static getDerivedStateFromProps(props, state) {
    if (props.showModal !== state.showModal) {
      const record = Object.assign({}, props.record);
      if (record.professores.length) {
        record.professores = record.professores.map(c => { return { value: c.id, label: c.nome } });
      }

      return {
        showModal: props.showModal,
        record: record
      };
    }
    return null;
  }

  closeModal = () => {
    this.setState({
      showModal: false,
      isLoading: false,
      record: this.props.emptyRecord,
      errors: resetErrors
    }, () => this.closeModalForm());
  };

  validateInput = (name, value, errors) => {
    switch (name) {
      case 'unidade':
        errors.unidade = !value ? 'Preencha o campo UNIDADE!' : '';
        break;
      case 'idEstado':
        errors.idEstado = !value ? 'Selecione um ESTADO!' : '';
        break;
      case 'municipio':
        errors.municipio = !value ? 'Preencha o campo MUNICIPIO!' : '';
        break;
      case 'ativo':
        errors.ativo = !value ? 'Selecione um STATUS!' : '';
        break;
      default:
        break;
    }

    return errors;
  };

  validateForm = () => {
    let record = Object.assign({}, this.state.record),
      errors = Object.assign({}, this.state.errors);

    for (const key in record) {
      errors = this.validateInput(key, record[key], errors);
    }

    return errors;
  };

  handleInputChange = (event) => {
    event.preventDefault();
    const { name, value } = event.target;
    this.handleComponentChange(name, value);
  };

  handleComponentChange = (name, value) => {
    let record = Object.assign({}, this.state.record),
      errors = Object.assign({}, this.state.errors);

    record[name] = value;
    this.setState({
      record: record,
      errors: this.validateInput(name, value, errors)
    });
  };

  hideAlert = () => {
    this.setState({ alert: null });
  };

  handleSubmit = (event) => {
    event.preventDefault();
    const errors = this.validateForm();
    if (JSON.stringify(errors) !== JSON.stringify(resetErrors)) {
      this.setState({
        alert: (<SweetAlert warning title="Oops!" onConfirm={() => this.hideAlert()} showConfirm={false} timeout={2000}>Preenchimento incorreto!</SweetAlert>),
        errors: errors
      });
    } else {
      this.setState({ isLoading: true }, () => {
        let record = Object.assign({}, this.state.record);
        record.professores = record.professores.map(p => { return p.value });
        api.post('/unidades', record)
          .then((response) => {
            if (response.data.message === 'ok') {
              toast.success('Unidade salva com sucesso!');
              this.loadFromAPI();
              this.closeModal();
            } else {
              this.setState({
                isLoading: false,
                alert: (<SweetAlert warning title="Oops!" onConfirm={() => this.hideAlert()} showConfirm={false} timeout={2000}>{response.data.message}</SweetAlert>),
              });
            }
          })
          .catch((error) => {
            this.setState({ isLoading: false });
            toast.error('Falha ao enviar os dados!');
          });
      });
    }
  };

  render() {
    const { showModal, isLoading, alert, record, errors } = this.state;

    return (
      <React.Fragment>
        {alert}
        <Modal show={showModal} onHide={this.closeModal}>
          <Modal.Header closeButton>
            <Modal.Title>{record.id ? 'Editar' : 'Adicionar'} unidade</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="loading">
              <Form id="form-unidade" className="form-horizontal form-sm form-custom" onSubmit={this.handleSubmit} noValidate>
                <Form.Row>
                  <Form.Group as={Col} sm={9}>
                    <Form.Label>Nome da Unidade</Form.Label>
                    <Form.Control
                      type="text"
                      name="unidade"
                      value={record.unidade}
                      onChange={this.handleInputChange}
                      onBlur={this.handleInputChange}
                      className={errors.unidade.length && 'is-invalid'}
                      required
                    />
                    <Form.Control.Feedback type="invalid">{errors.unidade}</Form.Control.Feedback>
                  </Form.Group>
                  <Form.Group as={Col} sm={3}>
                    <Form.Label>Status</Form.Label>
                    <SelectAtivo
                      name="ativo"
                      ativo={record.ativo}
                      onChange={this.handleComponentChange}
                      required
                    />
                    <Form.Control.Feedback type="invalid">{errors.ativo}</Form.Control.Feedback>
                  </Form.Group>
                  <Form.Group as={Col} sm={3}>
                    <Form.Label>Estado</Form.Label>
                    <SelectEstados
                      name="idEstado"
                      idEstado={record.idEstado}
                      onChange={this.handleComponentChange}
                      required={true}
                      error={errors.idEstado}
                      showUF
                    />
                    <Form.Control.Feedback type="invalid">{errors.idEstado}</Form.Control.Feedback>
                  </Form.Group>
                  <Form.Group as={Col} sm={9}>
                    <Form.Label>Municipio</Form.Label>
                    <TypeaheadMunicipios
                      name="municipio"
                      idEstado={record.idEstado}
                      municipio={record.municipio}
                      onChange={this.handleComponentChange}
                      required
                      error={errors.municipio}
                    />
                    <Form.Control.Feedback type="invalid">{errors.municipio}</Form.Control.Feedback>
                  </Form.Group>
                  <Form.Group as={Col} sm={12}>
                    <Form.Label>Professores</Form.Label>
                    <MultiSelectProfessores
                      name="professores"
                      professores={record.professores}
                      onChange={this.handleComponentChange}
                    />
                  </Form.Group>
                </Form.Row>
              </Form>
              <CSSTransition
                in={isLoading}
                timeout={500}
                classNames="fade"
                unmountOnExit
              >
                <div><Loading /></div>
              </CSSTransition>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="outline-secondary" onClick={this.closeModal}>Cancelar</Button>
            <Button variant="success" form="form-unidade" type="submit">Salvar</Button>
          </Modal.Footer>
        </Modal>
      </React.Fragment>
    );
  }
}

export default FormUnidade;