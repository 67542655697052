import React, { Component, Fragment } from "react";
import { Modal, Form, Col, Button } from "react-bootstrap";
import { CSSTransition } from 'react-transition-group';
import { toast } from 'react-toastify';
import SweetAlert from "react-bootstrap-sweetalert";
import {
  Loading,
  ButtonDelete,
  SelectCursos,
  SelectDiaSemana,
  SelectHorario,
  SelectUnidades,
  MultiSelectAlunos
} from "../../../../components";

import api from "../../../../services/api";

const resetErrors = {
  idUnidade: '',
  idCurso: '',
  idProfessor: '',
  dia: '',
  inicio: '',
  fim: ''
};

class FormQuadro extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showModal: false,
      isLoading: false,
      alert: null,
      record: {},
      errors: resetErrors
    };
    this.loadFromAPI = props.loadFromAPI;
    this.closeModalForm = props.closeModalForm;
    this.doDelete = props.doDelete;
  }

  static getDerivedStateFromProps(props, state) {
    if (props.showModal !== state.showModal) {
      const record = Object.assign({}, props.record);

      if (record.alunos && record.alunos.length) {
        record.alunos = record.alunos.map(a => {
          return { value: a.idAluno, label: a.nome }
        });
      }

      record.idProfessor = parseInt(props.idProfessor);
      return {
        showModal: props.showModal,
        record: record
      };
    }
    return null;
  }

  closeModal = () => {
    this.setState({
      showModal: false,
      isLoading: false,
      record: this.props.emptyRecord,
      errors: resetErrors
    }, () => this.closeModalForm());
  };

  validateInput = (name, value, errors) => {
    switch (name) {
      case 'idUnidade':
        errors.idUnidade = !value ? 'Selecione uma UNIDADE' : '';
        break;
      case 'idCurso':
        errors.idCurso = !value ? 'Selecione um CURSO' : '';
        break;
      case 'dia':
        errors.dia = !(!isNaN(value) && value >= 0 && value <= 6) ? 'Selecione o DIA DA SEMANA' : '';
        break;
      case 'inicio':
        errors.inicio = !value ? 'Selecione o HORÁRIO INICIAL' : '';
        break;
      case 'fim':
        errors.fim = !value ? 'Selecione o HORÁRIO FINAL' : '';
        break;
      default:
        break;
    }

    return errors;
  };

  validateForm = () => {
    let record = Object.assign({}, this.state.record),
      errors = Object.assign({}, this.state.errors);

    for (const key in record) {
      errors = this.validateInput(key, record[key], errors);
    }

    return errors;
  };

  handleInputChange = (event) => {
    event.preventDefault();
    const { name, value } = event.target;
    this.handleComponentChange(name, value);
  };

  handleComponentChange = (name, value) => {
    let record = Object.assign({}, this.state.record),
      errors = Object.assign({}, this.state.errors);

    if (record[name] !== value) {
      record[name] = value;
      this.setState({
        record: record,
        errors: this.validateInput(name, value, errors)
      });
    }
  };

  hideAlert = () => {
    this.setState({ alert: null });
  };

  handleSubmit = (event) => {
    event.preventDefault();
    const errors = this.validateForm();
    if (JSON.stringify(errors) !== JSON.stringify(resetErrors)) {
      this.setState({
        alert: (<SweetAlert warning title="Oops!" onConfirm={() => this.hideAlert()} showConfirm={false} timeout={2000}>Preenchimento incorreto!</SweetAlert>),
        errors: errors
      });
    } else {
      let record = Object.assign({}, this.state.record);
      record.alunos = record.alunos ? record.alunos.map(aluno => { return aluno.value }) : [];
      this.setState({ isLoading: true }, () => {
        api.post('/aulas/quadro', record)
          .then((response) => {
            if (response.data.message === 'ok') {
              toast.success('Aula salva com sucesso!');
              this.loadFromAPI();
              this.closeModal();
            } else {
              this.setState({
                isLoading: false,
                alert: (<SweetAlert warning title="Oops!" onConfirm={() => this.hideAlert()} showConfirm={false} timeout={2000}>{response.data.message}</SweetAlert>),
              });
            }
          })
          .catch((error) => {
            this.setState({ isLoading: false });
            toast.error('Falha ao enviar os dados!');
          });
      });
    }
  };

  render() {
    const { showModal, isLoading, alert, record, errors } = this.state;

    return (
      <Fragment>
        {alert}
        <Modal show={showModal} onHide={this.closeModal}>
          <Modal.Header closeButton>
            <Modal.Title>{record.id ? 'Editar' : 'Adicionar'} aula</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="loading">
              <CSSTransition
                in={isLoading}
                timeout={500}
                classNames="fade"
                unmountOnExit
              >
                <div><Loading /></div>
              </CSSTransition>
              <Form id="form-quadro" className="form-horizontal form-sm form-custom" onSubmit={this.handleSubmit} noValidate>
                <Form.Row>
                  <Form.Group as={Col} sm={6}>
                    <Form.Label>Dia da semana</Form.Label>
                    <SelectDiaSemana
                      name="dia"
                      dia={record.dia}
                      onChange={this.handleComponentChange}
                      error={errors.dia}
                      required
                    />
                    <Form.Control.Feedback type="invalid">{errors.dia}</Form.Control.Feedback>
                  </Form.Group>
                  <Form.Group as={Col} xs={6} sm={3} >
                    <Form.Label>Hora inicial</Form.Label>
                    <SelectHorario
                      name="inicio"
                      value={record.inicio}
                      onChange={this.handleComponentChange}
                      error={errors.inicio}
                      required
                    />
                    <Form.Control.Feedback type="invalid">{errors.inicio}</Form.Control.Feedback>
                  </Form.Group>
                  <Form.Group as={Col} xs={6} sm={3}>
                    <Form.Label>Hora final</Form.Label>
                    <SelectHorario
                      name="fim"
                      value={record.fim}
                      onChange={this.handleComponentChange}
                      error={errors.fim}
                      required
                    />
                    <Form.Control.Feedback type="invalid">{errors.fim}</Form.Control.Feedback>
                  </Form.Group>
                  <Form.Group as={Col} sm={6}>
                    <Form.Label>Unidade</Form.Label>
                    <SelectUnidades
                      name="idUnidade"
                      idUnidade={record.idUnidade}
                      checkProfessor
                      idProfessor={record.idProfessor}
                      onChange={this.handleComponentChange}
                      error={errors.idUnidade}
                      required
                    />
                    <Form.Control.Feedback type="invalid">{errors.idUnidade}</Form.Control.Feedback>
                  </Form.Group>
                  <Form.Group as={Col} sm={6}>
                    <Form.Label>Curso</Form.Label>
                    <SelectCursos
                      name="idCurso"
                      idCurso={record.idCurso}
                      checkProfessor
                      idProfessor={record.idProfessor}
                      onChange={this.handleComponentChange}
                      error={errors.idCurso}
                      required

                    />
                    <Form.Control.Feedback type="invalid">{errors.idCurso}</Form.Control.Feedback>
                  </Form.Group>
                  <Form.Group as={Col} sm={12}>
                    <Form.Label>Alunos</Form.Label>
                    <MultiSelectAlunos
                      name="alunos"
                      checkUnidade
                      idUnidade={record.idUnidade}
                      status="M"
                      alunos={record.alunos}
                      resetAlunosOnChangeUnidade
                      onChange={this.handleComponentChange}
                    />
                  </Form.Group>
                </Form.Row>
              </Form>
            </div>
          </Modal.Body>
          <Modal.Footer>
            {record.id && (
              <ButtonDelete
                id={record.id}
                label={"Aula de " + record.dia}
                buttonLabel="Excluir aula"
                doDelete={this.doDelete}
              />
            )}
            <Button variant="outline-secondary" onClick={this.closeModal}>Fechar</Button>
            <Button variant="success" form="form-quadro" type="submit">Salvar</Button>
          </Modal.Footer>
        </Modal>
      </Fragment >
    );
  }
}

export default FormQuadro;