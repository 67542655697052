import React, { Component } from "react";
import { Card, Col, Form, InputGroup, Button } from "react-bootstrap";
import DatePicker from "react-datepicker";

import {
  SelectCursos,
  SelectColegios,
  SelectIdades,
  SelectSeries,
  SelectStatus,
  SelectTurnos,
  SelectUnidades
} from '../../../components';

class Filter extends Component {
  constructor(props) {
    super(props);
    this.state = {
      idUnidade: props.query.idUnidade,
      idCurso: props.query.idCurso,
      nome: props.query.nome,
      idade: props.query.idade,
      idColegio: props.query.idColegio,
      serie: props.query.serie,
      turno: props.query.turno,
      status: props.query.status,
      tipoRange: props.query.tipoRange,
      rangeStart: props.query.rangeStart,
      rangeEnd: props.query.rangeEnd
    };
    this.upState = props.setQuery;
  }

  handleInputChange = (event) => {
    event.preventDefault();
    const { name, value } = event.target;
    this.handleComponentChange(name, value);
  };

  handleComponentChange = (name, value) => {
    this.setState({ [name]: value });
  };

  handleStartChange = (date) => {
    this.setState({ rangeStart: date });
  };

  handleEndChange = (date) => {
    this.setState({ rangeEnd: date });
  };

  handleSubmit = (event) => {
    event.preventDefault();
    this.upState(this.state);
  };

  render() {
    const { idUnidade, idCurso, nome, idade, idColegio, serie, turno, status, tipoRange, rangeStart, rangeEnd } = this.state;

    return (
      <Card className="card-filter">
        <Card.Body>
          <Card.Title>Filtros</Card.Title>
          <Form id="form-filter" onSubmit={this.handleSubmit} noValidate>
            <Form.Row>
              <Col sm={4}>
                <InputGroup size="sm">
                  <InputGroup.Prepend>
                    <InputGroup.Text>Unidade</InputGroup.Text>
                  </InputGroup.Prepend>
                  <SelectUnidades
                    name="idUnidade"
                    unidades={this.props.usuario.unidades}
                    idUnidade={idUnidade}
                    onChange={this.handleComponentChange}
                  />
                </InputGroup>
              </Col>
              <Col sm={4} lg={3}>
                <InputGroup size="sm">
                  <InputGroup.Prepend>
                    <InputGroup.Text>Curso</InputGroup.Text>
                  </InputGroup.Prepend>
                  <SelectCursos
                    name="idCurso"
                    cursos={this.props.usuario.cursos}
                    idCurso={idCurso}
                    onChange={this.handleComponentChange}
                  />
                </InputGroup>
              </Col>
              <Col xs={6} sm={4} lg={3}>
                <InputGroup size="sm">
                  <InputGroup.Prepend>
                    <InputGroup.Text>Status</InputGroup.Text>
                  </InputGroup.Prepend>
                  <SelectStatus
                    name="status"
                    status={status}
                    onChange={this.handleComponentChange}
                  />
                </InputGroup>
              </Col>
              <Col xs={6} sm={3} lg={2}>
                <InputGroup size="sm">
                  <InputGroup.Prepend>
                    <InputGroup.Text>Idade</InputGroup.Text>
                  </InputGroup.Prepend>
                  <SelectIdades
                    name="idade"
                    idade={idade}
                    onChange={this.handleComponentChange}
                  />
                </InputGroup>
              </Col>
              <Col sm={9} lg={4}>
                <InputGroup size="sm">
                  <InputGroup.Prepend>
                    <InputGroup.Text>Nome</InputGroup.Text>
                  </InputGroup.Prepend>
                  <Form.Control
                    type="text"
                    name="nome"
                    value={nome}
                    onChange={this.handleInputChange}
                  />
                </InputGroup>
              </Col>
              <Col sm={6} lg={4}>
                <InputGroup size="sm">
                  <InputGroup.Prepend>
                    <InputGroup.Text>Colégio</InputGroup.Text>
                  </InputGroup.Prepend>
                  <SelectColegios
                    name="idColegio"
                    idColegio={idColegio}
                    onChange={this.handleComponentChange}
                  />
                </InputGroup>
              </Col>
              <Col xs={6} sm={3} lg={2}>
                <InputGroup size="sm">
                  <InputGroup.Prepend>
                    <InputGroup.Text>Série</InputGroup.Text>
                  </InputGroup.Prepend>
                  <SelectSeries
                    name="serie"
                    serie={serie}
                    onChange={this.handleComponentChange}
                  />
                </InputGroup>
              </Col>
              <Col xs={6} sm={3} lg={2}>
                <InputGroup size="sm">
                  <InputGroup.Prepend>
                    <InputGroup.Text>Turno</InputGroup.Text>
                  </InputGroup.Prepend>
                  <SelectTurnos
                    name="turno"
                    turno={turno}
                    onChange={this.handleComponentChange}
                    required={false}
                  />
                </InputGroup>
              </Col>
              <Col sm={9}>
                <InputGroup size="sm">
                  <InputGroup.Prepend>
                    <InputGroup.Text>Periodo</InputGroup.Text>
                  </InputGroup.Prepend>
                  <Form.Control
                    as="select"
                    name="tipoRange"
                    value={tipoRange}
                    onChange={this.handleInputChange}
                    size="sm"
                    className="custom-select"
                  >
                    <option value="1">Inscrição</option>
                    <option value="2">Matrícula</option>
                    <option value="3">Formatura</option>
                    <option value="4">Desistencia</option>
                  </Form.Control>
                  <DatePicker
                    name="rangeStart"
                    selected={rangeStart}
                    dateFormat="dd/MM/yyyy"
                    onChange={date => this.handleStartChange(date)}
                    selectsStart
                    startDate={rangeStart}
                    endDate={rangeEnd}
                    className="form-control form-control-sm rounded-0 border-left-0"
                    popperPlacement="bottom"
                    popperClassName="center-triangle"
                    showMonthDropdown
                    showYearDropdown
                    dropdownMode="select"
                  />
                  <DatePicker
                    name="rangeEnd"
                    selected={rangeEnd}
                    dateFormat="dd/MM/yyyy"
                    onChange={date => this.handleEndChange(date)}
                    selectsEnd
                    endDate={rangeEnd}
                    minDate={rangeStart}
                    className="form-control form-control-sm border-left-0"
                    popperPlacement="bottom-end"
                    popperClassName="right-triangle"
                    showMonthDropdown
                    showYearDropdown
                    dropdownMode="select"
                  />
                </InputGroup>
              </Col>
              <Col sm={3}>
                <Button variant="primary" size="sm" form="form-filter" type="submit" block>Filtrar</Button>
              </Col>
            </Form.Row>
          </Form>
        </Card.Body>
      </Card>
    );
  }
}

export default Filter;