import React, { Component, memo } from "react";
import { toast } from 'react-toastify';
import Select from 'react-select';

import api from "../../services/api";

class MultiSelectUnidades extends Component {
  _isMounted = false;

  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      options: [],
      selected: props.unidades,
      disabled: props.disabled
    };
    this.onChange = props.onChange;
  }

  loadUnidades = (params = {}, upState = false) => {
    api.get('/unidades', { params: params })
      .then(response => {
        if (this._isMounted) {
          if (response.data.message) toast.error(response.data.message);
          const options = response.data.unidades.map(p => { return { value: p.id, label: p.unidade } });
          if (upState) {
            this.setState({
              options: options,
              selected: null,
              disabled: false,
            }, this.onChange('unidades', []));
          } else {
            this.setState({ options: options, disabled: false, });
          }
        }
      })
      .catch(error => {
        toast.error(error.message);
      });
  };

  handleChange = (selected) => {
    this.setState({ selected }, this.onChange('unidades', selected));
  };

  componentDidMount = () => {
    const { checkProfessor, idProfessor } = this.props;
    this._isMounted = true;

    if (!checkProfessor) {
      this.loadUnidades({ ativo: 'S' });
    } else if (checkProfessor && idProfessor) {
      this.loadUnidades({ idProfessor: this.state.idProfessor, ativo: 'S' });
    } else {
      this.setState({ disabled: true });
    }
  };

  componentDidUpdate = (prevProps) => {
    const { checkProfessor, idProfessor } = this.props;
    if (checkProfessor) {
      if (idProfessor !== prevProps.idProfessor) {
        if (idProfessor) {
          this.loadUnidades({ idProfessor, ativo: 'S' }, true);
        } else {
          this.setState({
            options: [],
            selected: null,
            disabled: true
          });
        }
      }
    }
  };

  componentWillUnmount = () => {
    this._isMounted = false;
  };

  render() {
    const { isLoading, options, selected, disabled } = this.state;

    return (
      <Select
        name={this.props.name}
        isLoading={isLoading}
        value={selected}
        onChange={this.handleChange}
        options={options}
        isMulti
        closeMenuOnSelect={false}
        isDisabled={disabled}
        placeholder="Selecione as Unidades"
        noOptionsMessage={() => null}
      />
    )
  }
}

export default memo(MultiSelectUnidades);
