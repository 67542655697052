import React from "react";
import { Badge } from "react-bootstrap";
import { nivelLabel } from '../utils/misc';

function NivelBadge(props) {

  return <Badge
    variant={props.nivel === 1 ? 'success' : props.nivel === 2 ? 'warning' : 'primary'}
    className="badge-md"
    title={nivelLabel(props.nivel)}
  >
    {nivelLabel(props.nivel)}
  </Badge>;
}

export default NivelBadge;