import React, { Component } from "react";
import { Switch, Route } from "react-router-dom";

import "react-datepicker/dist/react-datepicker.css";
import '../assets/css/app.css';

import AdminNavbar from "../components/AdminNavbar";
import routes from "../routes/app.js";

class App extends Component {
  getRoutes = (routes) => {
    return routes.map((route, key) => {
      if (route.views) {
        return this.getRoutes(route.views);
      }
      return (
        <Route
          path={route.path}
          key={key}
          render={routeProps => (<route.component {...routeProps} />)}
        />
      );
    });
  };

  render() {
    return (
      <div>
        <AdminNavbar />
        <main>
          <Switch>{this.getRoutes(routes)}</Switch>
        </main>
      </div>
    );
  }
}

export default App;