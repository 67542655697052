import React, { Component } from "react";

class PaginationPerPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      value: props.limit ? props.limit : 50
    }
    this.setLimit = props.setLimit
  }

  handleChange = (event) => {
    event.preventDefault();
    const value = event.target.value;
    this.setState({ value: value }, this.setLimit(value));
  };

  render() {
    const { value } = this.state;

    return (
      <span className="form-sm nowrap align-middle">
        Exibindo&nbsp;
        <select
          value={value}
          className="form-control autosize inline"
          onChange={this.handleChange}
        >
          <option value="50">50</option>
          <option value="100">100</option>
        </select>
        &nbsp;por página.
      </span>
    )
  }
}

export default PaginationPerPage;
