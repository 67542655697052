import React, { Component } from "react";
import { Container, Row, Col, Card, Table } from "react-bootstrap";
import { CSSTransition } from 'react-transition-group';
import { toast } from 'react-toastify';
import {
  Loading,
  ButtonAdd,
  ButtonEdit,
  ButtonDelete,
  ButtonSend,
  StatusBadge,
  ModalAtivacao
} from "../../../components";
import FormAdmin from "./FormAdmin";
import { datetimeToLocal } from '../../../utils/dates';
import api from "../../../services/api";

const emptyRecord = { id: '', nome: '', email: '', ativo: 'S' };

class Administradores extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      showModalForm: false,
      showModalAtivacao: false,
      records: [],
      totalRecords: 0,
      record: emptyRecord
    };
  }

  loadFromAPI = () => {
    this.setState({ isLoading: true }, () => {
      api.get('/administradores')
        .then(response => {
          this.setState({
            records: response.data.administradores ? response.data.administradores : [],
            totalRecords: response.data.administradores ? response.data.administradores.length : 0,
            isLoading: false
          });
        })
        .catch(error => {
          this.setState({ isLoading: false });
          toast.error('Falha ao obter dados!');
        });
    });
  };

  doDelete = (id) => {
    api.delete('/administradores', { data: { id: id } })
      .then((response) => {
        if (response.data.message === 'ok') {
          toast.success('Administrador excluido com sucesso!');
          this.loadFromAPI();
        } else {
          toast.error(response.data.message);
        }
      })
      .catch((error) => {
        toast.error('Ocorreu uma falha ao excluir!');
      });
  };

  openModalForm = (record) => {
    this.setState({
      showModalForm: true,
      record: record
    });
  };

  closeModalForm = () => {
    this.setState({
      showModalForm: false,
      record: emptyRecord
    });
  };

  openModalActivation = (record) => {
    this.setState({
      showModalAtivacao: true,
      record: record
    });
  };

  closeModalActivation = () => {
    this.setState({
      showModalAtivacao: false,
      record: emptyRecord
    });
  };

  componentDidMount = () => {
    this.loadFromAPI();
  };

  render() {
    const { isLoading, showModalForm, showModalAtivacao, totalRecords, records, record } = this.state;
    return (
      <Container fluid>
        <Row className="mb-3">
          <Col><h1 className="page-title">Administradores</h1></Col>
          <Col sm="auto" className="pt-3 pt-sm-2">
            <ButtonAdd label="Adicionar administrador" onClick={() => this.openModalForm(emptyRecord)} />
          </Col>
        </Row>
        <Card className="card-list">
          <Card.Body className="p-0">
            <div className="loading">
              <div className="nowrap mx-3 py-2">Total: <b>{totalRecords}</b> registro(s).</div>
              <Table striped responsive className="table-data">
                <thead className="thead-light">
                  <tr>
                    <th>#</th>
                    <th>Nome</th>
                    <th>Email</th>
                    <th className="text-center">Último acesso</th>
                    <th className="text-center">Status</th>
                    <th style={{ width: "108px" }}></th>
                  </tr>
                </thead>
                <tbody>
                  {
                    !records.length ? (
                      <tr><td colSpan={6} className="empty">Nenhum registro </td></tr>
                    ) : (
                        records.map((item, key) => {
                          return (
                            <tr key={key}>
                              <td><span className="text-muted">{key + 1}</span></td>
                              <td><b>{item.nome}</b></td>
                              <td>{item.email}</td>
                              <td className="text-center">{item.ultAcesso ? datetimeToLocal(item.ultAcesso) : '-'}</td>
                              <td className="text-center"><StatusBadge ativo={item.ativo} /></td>
                              <td className="text-right">
                                <ButtonSend onClick={() => this.openModalActivation(item)} label="Enviar mensagem de ativação" />
                                <ButtonEdit onClick={() => this.openModalForm(item)} />
                                <ButtonDelete id={item.id} label={item.nome} doDelete={this.doDelete} />
                              </td>
                            </tr>
                          )
                        })
                      )
                  }
                </tbody>
              </Table>
              <CSSTransition
                in={isLoading}
                timeout={500}
                classNames="fade"
                unmountOnExit
              >
                <div><Loading /></div>
              </CSSTransition>
            </div>
          </Card.Body>
        </Card>
        <br />
        <FormAdmin
          emptyRecord={emptyRecord}
          record={record}
          showModal={showModalForm}
          closeModal={this.closeModalForm}
          loadFromAPI={this.loadFromAPI}
        />
        <ModalAtivacao
          emptyRecord={emptyRecord}
          record={record}
          showModal={showModalAtivacao}
          closeModal={this.closeModalActivation}
        />
      </Container>
    );
  }
}

export default Administradores;