export const series = [
  { value: 1, label: 'Nivel 1' },
  { value: 2, label: 'Nível 2' },
  { value: 11, label: '1ª' },
  { value: 12, label: '2ª' },
  { value: 13, label: '3ª' },
  { value: 14, label: '4ª' },
  { value: 15, label: '5ª' },
  { value: 16, label: '6ª' },
  { value: 17, label: '7ª' },
  { value: 18, label: '8ª' },
  { value: 19, label: '9ª' },
  { value: 21, label: '1° do 2°' },
  { value: 22, label: '2° do 2°' },
  { value: 23, label: '3° do 2°' }
];

export const serieLabel = (value) => {
  for (let i = 0; i < series.length; i++) {
    if (series[i].value === value) return series[i].label;
  }
  return '';
};

export const turnoLabel = (value) => {
  value = parseInt(value);
  return value === 1 ? 'Manhã' : (value === 2 ? 'Tarde' : 'Noite');
};

export const nivelLabel = (value) => {
  value = parseInt(value);
    switch (value) {
      case 5:
        return 'Monitor';
      case 4:
        return 'Especial';
      case 3:
        return 'Avançado';
      case 2:
        return 'Intermediário';
      case 1:
        return 'Iniciante';
      default:
        return 'Não informado';
    }
  };

export const filterQuery = (obj) => {
  let query = {};
  for (const key in obj) {
    if (obj[key]) query[key] = obj[key];
  }

  if (query.order && query.direction) {
    query.order += ' ' + query.direction;
    delete query.direction;
  }
  return query;
};

export const stringToObject = (str) => {
  let obj = {};

  str = str.replace('?', '');
  const array = str.split('&');

  array.forEach(item => {
    item = item.split('=');
    obj[item[0]] = item[1];
  })
  return obj;
};

export const isEqualArrays = (a, b) => {
  if (!a instanceof Array || !b instanceof Array || a.length !== b.length) return false;

  for (let i = 0; i < a.length; i++) {
    if(b.indexOf(a[i]) === -1) return false
  }

  return true;
};