import React, { Component } from "react";
import { Link, withRouter } from "react-router-dom";
import { Card, Image, Form, Button } from "react-bootstrap";
import { CSSTransition } from 'react-transition-group';
import { toast } from 'react-toastify';
import { Loading } from "../components";

import api from "../services/api";
import { validEmailRegex } from "../utils/validation";
import logo from '../assets/img/logo-gaiteiros.png';

class RecoveryPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      email: '',
      formValid: false
    }
  }

  handleInputChange = (event) => {
    event.preventDefault();
    const { value } = event.target;

    let formValid = true;
    if (!value.length) {
      formValid = false
    } else if (!validEmailRegex.test(value)) {
      formValid = false
    }
    this.setState({
      email: value,
      formValid: formValid
    });
  };

  handleSubmit = (event) => {
    event.preventDefault();
    if (this.state.formValid) {
      this.setState({ isLoading: true }, () => {
        api.post('/recovery', { email: this.state.email })
          .then((response) => {
            if (response.data.message === 'ok') {
              toast.success('Solicitação enviada com sucesso!');
            } else {
              toast.error(response.data.message);
            }
            this.setState({ isLoading: false });
          })
          .catch((error) => {
            toast.error('Ocorreu uma falha!');
            this.setState({ isLoading: false });
          })
      });
    } else {
      toast.error('Preenchimento incorreto!');
    }
  };

  render() {
    const { isLoading, email, formValid } = this.state;

    return (
      <div className="login-wrapper text-center">
        <Card className="shadow">
          <Card.Body>
            <Image src={logo} fluid />
            <br />
            <br />
            <h4 className="mb-3">Recuperação de senha</h4>
            <div className="loading">
              <Form onSubmit={this.handleSubmit} noValidate>
                <Form.Control
                  type="text"
                  name="email"
                  placeholder="Digite seu email"
                  value={email}
                  onChange={this.handleInputChange}
                  onBlur={this.handleInputChange}
                  className={!formValid && 'is-invalid'}
                  bsPrefix="form-control top"
                  required
                />
                {this.state.formValid ?
                  (
                    <Button
                      variant="primary"
                      size="lg"
                      block
                      type="submit"
                      className="bottom"
                    >
                      Enviar solicitação
                    </Button>
                  ) : (
                    <Button
                      variant="primary"
                      size="lg"
                      block
                      className="bottom"
                      disabled
                    >
                      Enviar solicitação
                    </Button>
                  )}
                <div className="text-center my-2">
                  <Link to="/login">voltar</Link>
                </div>
              </Form>
              <CSSTransition
                in={isLoading}
                timeout={500}
                classNames="fade"
                unmountOnExit
              >
                <div><Loading /></div>
              </CSSTransition>
            </div>
          </Card.Body>
        </Card>
      </div>
    );
  }
}

export default withRouter(RecoveryPage);
