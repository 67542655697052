import React, { useState } from "react";
import { NavLink, useHistory } from "react-router-dom";
import { Navbar, Nav, NavDropdown } from 'react-bootstrap';
import { toast } from 'react-toastify';
import ModalSenha from "./ModalSenha";

import routes from "../routes/app.js";

function AdminNavbar() {
  let history = useHistory();
  const [navExpanded, setNavExpanded] = useState(false);
  const usuario = JSON.parse(localStorage.getItem('usuario'));

  const toggleMenu = (value) => { setNavExpanded(value) };

  const handleSelect = () => { setTimeout(() => document.dispatchEvent(new MouseEvent('click')), 50) };

  const doLogout = () => {
    localStorage.removeItem('token');
    localStorage.removeItem('expires');
    // localStorage.clear();
    history.push("/login");
    toast.success('Sessão encerrada!');
  };

  return (
    <Navbar
      collapseOnSelect
      expand="md"
      bg="dark"
      variant="dark"
      fixed="top"
      className="shadow-sm"
      onToggle={toggleMenu}
      expanded={navExpanded}
    >
      <Navbar.Brand href="#home">Fabrica Tools</Navbar.Brand>
      <Navbar.Toggle aria-controls="responsive-navbar-nav" />
      <Navbar.Collapse id="responsive-navbar-nav">
        <Nav className="mr-auto">
          {
            routes.map((route, key) => {
              if (route.admin && usuario.idProfessor) return true;

              return route.views ? (
                <NavDropdown
                  key={key}
                  id={"nav-dropdown-" + key}
                  title={route.group}
                  onSelect={handleSelect()}
                >
                  {route.views.map((view, key) => {
                    if (view.admin && usuario.idProfessor) return true;
                    return <NavLink
                      key={key}
                      to={view.path}
                      onClick={() => toggleMenu(false)}
                      className="dropdown-item"
                    >
                      {view.name}
                    </NavLink>
                  })}
                </NavDropdown>
              ) : (
                <NavLink
                  key={key}
                  to={route.path}
                  onClick={() => toggleMenu(false)}
                  className="nav-link"
                >
                  {route.name}
                </NavLink>
              )
            })
          }
        </Nav>
        <Nav>
          <NavDropdown
            id="nav-dropdown-user"
            title={usuario.nome}
            alignRight
          >
            <NavDropdown.Item><ModalSenha /></NavDropdown.Item>
            <NavDropdown.Divider />
            <NavDropdown.Item onClick={() => doLogout()}>Sair</NavDropdown.Item>
          </NavDropdown>
        </Nav>
      </Navbar.Collapse>
    </Navbar >
  );
}

export default AdminNavbar;
