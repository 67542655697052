import React, { Component, memo } from "react";
import { toast } from 'react-toastify';

import api from "../../services/api";

class SelectCursos extends Component {
  _isMounted = false;

  constructor(props) {
    super(props);
    this.state = {
      options: props.cursos ? props.cursos : [],
      idProfessor: '',
      value: '',
      error: ''
    };
    this.upState = props.onChange;
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.idProfessor !== prevState.idProfessor
      || nextProps.idCurso !== prevState.value
      || nextProps.error !== prevState.error) {
      return {
        idProfessor: nextProps.idProfessor,
        value: nextProps.idCurso,
        error: nextProps.error
      };
    }
    return null;
  }

  componentDidMount = () => {
    this._isMounted = true;
    if (!this.props.cursos) {
      if (this.props.checkProfessor) {
        if (this.state.idProfessor) this.loadCursos({ idProfessor: this.state.idProfessor });
        if (this.state.idProfessor) {
          this.loadCursos({ idProfessor: this.state.idProfessor, ativo: 'S' });
        } else {
          toast.error('Falha ao carregar Cursos!');
        }
      } else {
        this.loadCursos();
      }
    }
  };

  componentDidUpdate = (prevProps) => {
    if (!this.props.cursos) {
      if (this.props.checkProfessor && this.props.idProfessor !== prevProps.idProfessor) {
        this.loadCursos({ idProfessor: this.state.idProfessor, ativo: 'S' });
      }
    }
  };

  loadCursos = (params = {}) => {
    api.get('/cursos', { params: params })
      .then(response => {
        if (this._isMounted) {
          const value = response.data.cursos.length === 1 ? response.data.cursos[0].id : '';
          this.setState({
            options: response.data.cursos,
            value: value
          }, this.upState(this.props.name, value));
        }
      })
      .catch(error => {
        toast.error(error.message);
      });
  };

  handleChange = (event) => {
    event.preventDefault();
    const value = event.target.value;
    if (this.state.value !== value) {
      this.setState({ value: value }, this.upState(this.props.name, value));
    }
  };

  componentWillUnmount = () => {
    this._isMounted = false;
  };

  render() {
    const { options, idUnidade, idProfessor, value, error } = this.state;

    return (
      <select
        name={this.props.name}
        value={value}
        onChange={this.handleChange}
        className={'custom-select' + (error ? ' is-invalid' : '')}
        required={this.props.required}
        disabled={((this.props.checkProfessor && !idProfessor) || (this.props.checkUnidade && !idUnidade)) ? true : false}
      >
        {options.length > 1 && (<option value="">{this.props.label}</option>)}
        {options.map((v, i) => <option key={i} value={v.id}>{v.curso}</option>)}
      </select>
    )
  }
}

export default memo(SelectCursos);
