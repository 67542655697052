// App Routes
import Administradores from "../views/App/Administradores";
import Alunos from "../views/App/Alunos";
import Inscricoes from "../views/App/Inscricoes";
import Atividades from "../views/App/Atividades";
import Quadro from "../views/App/Aulas/Quadro";
import Agenda from "../views/App/Aulas/Agenda";
import Chamada from "../views/App/Aulas/Chamada";
import Colegios from "../views/App/Colegios";
import Professores from "../views/App/Professores";
import Musicas from "../views/App/Musicas";
import Cursos from "../views/App/Cursos";
import Unidades from "../views/App/Unidades";
// import Festival from "../views/App/Festival";

// Relatórios
import Dashboard from "../views/App/Dashboard";
// import AulasProfessores from "../views/App/Relatorios/AulasProfessores";
// import AulasUnidades from "../views/App/Relatorios/AulasUnidades";

var routes = [
  {
    path: "/app/dashboard",
    name: "Dashboard",
    component: Dashboard
  },
  {
    group: "Cadastros",
    views: [
      {
        path: "/app/alunos",
        name: "Alunos",
        component: Alunos
      },
      {
        path: "/app/inscricoes",
        name: "Inscrições",
        component: Inscricoes
      },
      {
        path: "/app/colegios",
        name: "Colegios",
        component: Colegios,
        admin: true
      },
      {
        path: "/app/musicas",
        name: "Músicas",
        component: Musicas
      },
      {
        path: "/app/professores",
        name: "Professores",
        component: Professores,
        admin: true
      },
      {
        path: "/app/cursos",
        name: "Cursos",
        component: Cursos,
        admin: true
      },
      {
        path: "/app/unidades",
        name: "Unidades",
        component: Unidades,
        admin: true
      },
      {
        path: "/app/administradores",
        name: "Administradores",
        component: Administradores,
        admin: true
      }
    ]
  },
  {
    group: "Sala de Aula",
    views: [
      {
        path: "/app/aulas/chamadas",
        name: "Chamada",
        component: Chamada
      },
      {
        path: "/app/aulas/agenda",
        name: "Agenda",
        component: Agenda
      },
      {
        path: "/app/aulas/quadro",
        name: "Quadro Semanal",
        component: Quadro
      }
    ]
  },
  {
    path: "/app/atividades",
    name: "Atividades",
    component: Atividades
  },
  // {
  //   path: "/app/festival",
  //   name: "Festival",
  //   component: Festival
  // },
];
export default routes;
