import React, { Component, memo } from "react";
import { toast } from 'react-toastify';

import api from "../../services/api";

class SelectProfessores extends Component {
  _isMounted = false;

  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      options: [],
      idUnidade: '',
      idCurso: '',
      value: '',
      error: '',
      disabled: true
    };
    this.upState = props.onChange;
  }

  static getDerivedStateFromProps(props, state) {
    if (props.idUnidade !== state.idUnidade
      || props.idCurso !== state.idCurso
      || props.idProfessor !== state.value
      || props.error !== state.error) {
      return {
        idUnidade: props.idUnidade,
        idCurso: props.idCurso,
        value: props.idProfessor,
        error: props.error
      };
    }
    return null;
  }

  componentDidMount = () => {
    this._isMounted = true;
    if (this.props.checkUnidade && this.props.checkCurso) {
      if (this.state.idUnidade && this.state.idCurso) {
        this.loadProfessores({ idUnidade: this.state.idUnidade, idCurso: this.state.idCurso });
      } else {
        this.setState({ disabled: true });
      }
    } else if (this.props.checkUnidade) {
      if (this.state.idUnidade) {
        this.loadProfessores({ idUnidade: this.state.idUnidade });
      } else {
        this.setState({ disabled: true });
      }
    } else if (this.props.checkCurso) {
      if (this.state.idCurso) {
        this.loadProfessores({ idCurso: this.state.idCurso });
      } else {
        this.setState({ disabled: true });
      }
    } else {
      this.loadProfessores();
    }
  };

  componentDidUpdate = (prevProps) => {
    if (this.props.checkUnidade && this.props.checkCurso) {
      if (this.props.idUnidade !== prevProps.idUnidade || this.props.idCurso !== prevProps.idCurso) {
        if (this.props.idUnidade && this.props.idCurso) {
          this.loadProfessores({ idUnidade: this.state.idUnidade, idCurso: this.state.idCurso });
        } else {
          this.setState({
            options: [],
            value: '',
            disabled: true
          });
        }
      }
    } else if (this.props.checkUnidade) {
      if (this.props.idUnidade !== prevProps.idUnidade) {
        if (this.props.idUnidade) {
          this.loadProfessores({ idUnidade: this.state.idUnidade });
        } else {
          this.setState({
            options: [],
            value: '',
            disabled: true
          });
        }
      }
    } else if (this.props.checkCurso) {
      if (this.props.idCurso !== prevProps.idCurso) {
        if (this.props.idCurso) {
          this.loadProfessores({ idCurso: this.state.idCurso });
        } else {
          this.setState({
            options: [],
            value: '',
            disabled: true
          });
        }
      }
    }
  };

  loadProfessores = (params = {}, upState = false) => {
    api.get('/professores', { params: params })
      .then(response => {
        if (this._isMounted) {
          const value = response.data.professores.length === 1 ? response.data.professores[0].id : '';
          this.setState({
            options: response.data.professores,
            value: value,
            disabled: false,
          }, this.upState('idProfessor', value));
        }
      })
      .catch(error => {
        toast.error(error.message);
      });
  };

  handleChange = (event) => {
    event.preventDefault();
    const value = event.target.value;
    if (this.state.value !== value) {
      this.setState({ value: value }, this.upState('idProfessor', value));
    }
  };

  componentWillUnmount = () => {
    this._isMounted = false;
  };

  render() {
    const { options, value, error, disabled } = this.state;

    return (
      <select
        value={value}
        onChange={this.handleChange}
        onBlur={this.handleChange}
        className={'custom-select' + (error ? ' is-invalid' : '')}
        required={this.props.required}
        disabled={disabled}
      >
        {options.length > 1 && (<option value="">{this.props.label}</option>)}
        {options.map((v, i) => <option key={i} value={v.id}>{v.nome}</option>)}
      </select>
    )
  }
}

export default memo(SelectProfessores);
