// Pages routes
import Login from "../views/Login";
import Recovery from "../views/Recovery";
import Reset from "../views/Reset";
import Inscricao from "../views/Inscricao";
import AtivaInscricao from "../views/AtivaInscricao";

var routes = [
  {
    path: "/login",
    name: "Login",
    component: Login
  },
  {
    path: "/recovery",
    name: "Recovery",
    component: Recovery
  },
  {
    path: ["/reset", "/ativacao"],
    name: "Reset",
    component: Reset
  },
  {
    path: "/inscricao",
    name: "Inscricao",
    component: Inscricao
  },
  {
    path: "/ativa-inscricao",
    name: "Ativação",
    component: AtivaInscricao
  }
];
export default routes;
