export const monthNames = ["Janeiro", "Fevereiro", "Março", "Abril", "Maio", "Junho", "Julho", "Agosto", "Setembro", "Outubro", "Novembro", "Dezembro"];

export const weekNames = ["DOM", "SEG", "TER", "QUA", "QUI", "SEX", "SAB"];

// export const dateFromDB = (str) => {
//   let result = null;
//   if ((str) instanceof Date) {
//     let year = str.getFullYear();
//     let month = str.getMonth() + 1;
//     let day = str.getDate();
//     if (month < 10) {
//       month = '0' + month;
//     }
//     if (day < 10) {
//       day = '0' + day;
//     }
//     result = [day, month, year].join('/');
//   } else if (str) {
//     let d = str.substr(0, 10).split('-');
//     if (d[2].length < 2) {
//       d[2] = '0' + d[2];
//     }
//     if (d[1].length < 2) {
//       d[1] = '0' + d[1];
//     }
//     result = d.reverse().join('/');
//   }
//   return result;
// };

export const dateToLocal = (value) => {
  if (!value) return '';
  if (value instanceof Date && !isNaN(value)) {
    let year = value.getFullYear(),
      month = value.getMonth() + 1,
      day = value.getDate();
    if (month < 10) month = '0' + month;
    if (day < 10) day = '0' + day;
    return [day, month, year].join('/');
  } else if (typeof (value) === 'string') {
    if (value.indexOf('T') !== -1) value = value.split('T')[0];
    if (value.indexOf('/') !== -1) {
      let date = value.split('/');
      date = new Date(value.reverse().join('-'));
      if (isNaN(date)) return 'Data inválida';
      return value;
    }
    if (value.indexOf('-') !== -1) {
      const date = new Date(value);
      if (isNaN(date)) return 'Data inválida';
      value = value.split('-');
      return value.reverse().join('/')
    }
  }
  return 'Data inválida';
}

export const dateToServer = (value) => {
  if (value instanceof Date) {
    if (isNaN(value)) return '';
    let year = value.getFullYear(),
      month = value.getMonth() + 1,
      day = value.getDate();
    if (month < 10) month = '0' + month;
    if (day < 10) day = '0' + day;
    return [year, month, day].join('-');
  } else if (typeof (value) === 'string') {
    const date = new Date(value);
    if (isNaN(date)) return '';
    if (value.indexOf('/') !== -1 && parseInt(value.substr(0, 2)) !== date.getDate()) return '';
    if (value.indexOf('-') !== -1 && parseInt(value.substr(8, 2)) === date.getDate()) return '';
    let year = value.getFullYear(),
      month = value.getMonth() + 1,
      day = value.getDate();
    if (month < 10) month = '0' + month;
    if (day < 10) day = '0' + day;
    return [year, month, day].join('-');
  }
  return '';
};

export const datetimeToLocal = (value) => {
  if (!value) return '';
  let result = '';
  if (value instanceof Date && !isNaN(value)) {
    let date = dateToLocal(value),
      hours = value.getHours(),
      minutes = value.getMinutes();
    if (hours < 10) hours = '0' + hours;
    if (minutes < 10) minutes = '0' + minutes;
    result = [date, [hours, minutes].join(':')].join(' ');
  } else if (typeof (value) === 'string' && value.indexOf('T') >= 0) {
    let d = value.split('T'),
      date = dateToLocal(d[0]),
      time = d[1].split(':');
    result = [date, [time[0], time[1]].join(':')].join(' ');
  }
  return result;
};

export const datetimeToTime = (str) => {
  let result = null;
  if ((str) instanceof Date) {
    let hours = str.getHours(),
      minutes = str.getMinutes();
    if (hours < 10) {
      hours = '0' + hours;
    }
    if (minutes < 10) {
      minutes = '0' + minutes;
    }
    result = [hours, minutes].join(':');
  } else if (str) {
    let d = str.split('T'),
      time = d[1].split(':');

    result = [time[0], time[1]].join(':');
  }
  return result;
};

export const getTodayAsString = (format = null) => {
  let today = new Date(),
    dd = today.getDate(),
    mm = today.getMonth() + 1,
    yyyy = today.getFullYear();

  if (dd < 10) dd = '0' + dd;
  if (mm < 10) mm = '0' + mm;

  if (format === 'toDB') {
    return yyyy + '-' + mm + '-' + dd;
  }
  return dd + '/' + mm + '/' + yyyy;
};

export const calculaIdade = (nasc) => {
  if (!nasc) return 0;
  if (typeof (nasc) === 'string') nasc = new Date(nasc.replace(/Z$/, ''));
  const hoje = new Date(),
    anoAtual = hoje.getFullYear(),
    aniversarioEsteAno = new Date(anoAtual, nasc.getMonth(), nasc.getDate());
  let idade = anoAtual - nasc.getFullYear();
  if (aniversarioEsteAno > hoje) idade--;
  return idade;
};

export const previsaoSaida = (nasc) => {
  if (!nasc) return '';
  if (typeof (nasc) === 'string') nasc = new Date(nasc.replace(/Z$/, ''));
  const year = nasc.getFullYear() + 15;
  const month = nasc.getMonth();
  const day = nasc.getDay();
  return new Date(year, month, day);
};

export const intervaloAteHoje = (data) => {
  if (!data) return '';
  if (typeof (data) === 'string') data = new Date(data.replace(/Z$/, ''));
  // const hoje = new Date(),
  //   difDias = (hoje - data) / (1000 * 60 * 60 * 24),
  //   meses = parseInt(difDias / 30),
  //   dias = Math.round(difDias % 30);
  const hoje = new Date();

  if (hoje < data) return 'Data inválida';

  let anos = hoje.getFullYear() - data.getFullYear(),
    meses = hoje.getMonth() - data.getMonth(),
    dias = hoje.getDate() - data.getDate();
  if (meses < 0) {
    anos--;
    meses += 12;
  }
  if (dias < 0) {
    meses--;
    dias += 30;
  }
  let result = '';
  if (anos) result += `${anos}a`;
  if (meses) result += ` ${meses}m`;
  if (dias) result += ` ${dias}d`;

  return result;
};

export const daysInMonth = (year, month) => {
  return new Date(year, month, 0).getDate();
};

export const weekDay = (year, month, day) => {
  const date = new Date(year, month, day, 0, 0, 0);
  return weekNames[date.getDay()];
};

export const subDays = (date, days) => {
  return date.setDate(date.getDate() - days);
};

export const secondsToLocaleTime = (value) => {
  const minutes = parseInt(value / 60);
  const seconds = parseInt(value % 60);
  return `${minutes}:${seconds < 10 ? `0${seconds}` : seconds}`;
};